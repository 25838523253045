import React, { useEffect } from 'react';
import { Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import * as MyUtil from "../constants/MyUtil";

const SABPayList = ({ location }: any) => {
  const history = useHistory();
  const isMobile = (window.innerWidth < 570);
  const headerData = ['요금제', '결제 금액', '결제일', '이용 기간'];
  const tableData = [
    { type: 'Basic', price: '10,900원', payDay: '2011-01-01', payDate: '2001-05-11 ~ 2011-01-05' },
    { type: 'Basic', price: '10,900원', payDay: '2011-01-01', payDate: '2001-05-11 ~ 2011-01-05' },
    { type: 'Basic', price: '10,900원', payDay: '2011-01-01', payDate: '2001-05-11 ~ 2011-01-05' },
    { type: 'Basic', price: '10,900원', payDay: '2011-01-01', payDate: '2001-05-11 ~ 2011-01-05' },
    { type: 'Basic', price: '10,900원', payDay: '2011-01-01', payDate: '2001-05-11 ~ 2011-01-05' },
    { type: 'Basic', price: '10,900원', payDay: '2011-01-01', payDate: '2001-05-11 ~ 2011-01-05' },
    { type: 'Basic', price: '10,900원', payDay: '2011-01-01', payDate: '2001-05-11 ~ 2011-01-05' },
    { type: 'Basic', price: '10,900원', payDay: '2011-01-01', payDate: '2001-05-11 ~ 2011-01-05' },
    { type: 'Basic', price: '10,900원', payDay: '2011-01-01', payDate: '2001-05-11 ~ 2011-01-05' },
    { type: 'Basic', price: '10,900원', payDay: '2011-01-01', payDate: '2001-05-11 ~ 2011-01-05' },
    { type: 'Basic', price: '10,900원', payDay: '2011-01-01', payDate: '2001-05-11 ~ 2011-01-05' },
    { type: 'Basic', price: '10,900원', payDay: '2011-01-01', payDate: '2001-05-11 ~ 2011-01-05' },
    { type: 'Basic', price: '10,900원', payDay: '2011-01-01', payDate: '2001-05-11 ~ 2011-01-05' },
    { type: 'Basic', price: '10,900원', payDay: '2011-01-01', payDate: '2001-05-11 ~ 2011-01-05' },
    { type: 'Basic', price: '10,900원', payDay: '2011-01-01', payDate: '2001-05-11 ~ 2011-01-05' },
    { type: 'Basic', price: '10,900원', payDay: '2011-01-01', payDate: '2001-05-11 ~ 2011-01-05' },
    { type: 'Basic', price: '10,900원', payDay: '2011-01-01', payDate: '2001-05-11 ~ 2011-01-05' },
    { type: 'Basic', price: '10,900원', payDay: '2011-01-01', payDate: '2001-05-11 ~ 2011-01-05' },
    { type: 'Basic', price: '10,900원', payDay: '2011-01-01', payDate: '2001-05-11 ~ 2011-01-05' },
  ];





  useEffect(() => {
    // ** 아임포트 JS 호출
    const jquery = document.createElement("script");
    jquery.src = "https://code.jquery.com/jquery-1.12.4.min.js";
    const iamport = document.createElement("script");
    iamport.src = "https://cdn.iamport.kr/js/iamport.payment-1.1.8.js";
    document.head.appendChild(jquery); document.head.appendChild(iamport);

    return () => {
      document.head.removeChild(jquery);
      document.head.removeChild(iamport);
    };
  }, []);





  return (
    <div className="container">
      <div className="container-wrap" style={{ marginBottom: '30px', alignItems: 'center', alignContent: 'center' }}>
        <span className="container-title">{!MyUtil._isNull(location?.state?.korName) ? location?.state?.korName : ''}</span>
        <span className="container-sub">{!MyUtil._isNull(location?.state?.subName) ? location?.state?.subName : ''}</span>

        <div className='b-empty-row'></div>

        {
          isMobile && <div style={{ width: '530px', height: '15px' }}></div>
        }

        <div className="titleBtn-Con" onClick={() => { history.push({ pathname: '/SABPay', state: { korName: 'PAYMENT' } }); }}>
          <span className='titleBtn-text'>payment</span>
        </div>
      </div>

      <div className="Pay-table">
        {/* 헤더 */}
        {
          headerData.map((hdItem, hdIdx) => (<div key={hdIdx} className="Sales-table-header">{hdItem}</div>))
        }

        {/* DATA */}
        {
          tableData.map((item, idx) => (
            <React.Fragment key={idx}>
              <div className='Sales-table-cd'>{item.type}</div>
              <div className='Sales-table-cd'>{item.price}</div>
              <div className='Sales-table-cd'>{item.payDay}</div>
              <div className='Sales-table-cd'>{item.payDate}</div>
            </React.Fragment>
          ))
        }
      </div>




    </div>
  )

};

export default SABPayList;
