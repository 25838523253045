import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { mobileHeaderProps } from "../Interface/Interface";

const MobileHeaderContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 75px;
  color: #bebebe;
  display: flex;
  align-items: flex-end;
  z-index: 1;
  background-color: #fff;
  border-bottom: 1px solid #bebebe;

  > .titleWrap {
    width: 100%;
    display: flex;
    text-align: left;
  }

  .titleContents {
    margin-left: 30px;
    margin-bottom: 10px;
    position: relative;
  }

  .titleContents.isOn {
    font-weight: 700;
    color: #09348a;
  }

  .titleContents.isOn::after {
    content: "";
    width: 100%;
    position: absolute;
    top: 22px;
    left: 0;
    border-bottom: 3px solid #09348a;
  }
`;

function MobileHeader({
  textOne,
  textTwo,
  onTextOneClick,
  onTextTwoClick,
  initialActive,
}: mobileHeaderProps) {
  const [isTextOne, setIsTextOne] = useState(initialActive === "textOne");
  const [isTextTwo, setIsTextTwo] = useState(initialActive === "textTwo");

  useEffect(() => {
    setIsTextOne(initialActive === "textOne");
    setIsTextTwo(initialActive === "textTwo");
  }, [initialActive]);

  const handleTextOne = () => {
    setIsTextOne(true);
    setIsTextTwo(false);
    onTextOneClick();
  };

  const handleTextTwo = () => {
    setIsTextOne(false);
    setIsTextTwo(true);
    onTextTwoClick();
  };

  return (
    <MobileHeaderContainer>
      <div className="titleWrap">
        <div
          className={`titleContents ${isTextOne ? "isOn" : ""}`}
          onClick={handleTextOne}
        >
          {textOne}
        </div>
        <div
          className={`titleContents ${isTextTwo ? "isOn" : ""}`}
          onClick={handleTextTwo}
        >
          {textTwo}
        </div>
      </div>
    </MobileHeaderContainer>
  );
}

export default MobileHeader;
