import React, { useCallback, useEffect, useState } from "react";
import AlertModal from "react-modal";
import * as ServerApi from "../../constants/ServerApiM";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "../../component/redux/rootReducer";
import NewSaveButton from "./NewSaveButton";

const StyledModal = styled(AlertModal)`
  background-color: #dddddd;
  color: white;
  width: 80vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 15px;
  padding-bottom: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ModalTitle = styled.div`
  text-align: center;
  background-color: #09348a;
  color: #fff;
  width: 100%;
  font-size: 1.1rem;
  font-weight: 700;
  padding: 20px 0;
  margin-bottom: 10px;
  border-radius: 15px 15px 0 0;
`;

const StyledSelect = styled.select`
  width: calc(100% - 20px);
  height: 40px;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: transparent;
  color: #333;

  &:focus {
    outline: none;
    border-color: #09348a;
  }
`;

const StyledInput = styled.input`
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: transparent;
  color: #333;
`;

const ProgramsList = styled.ul`
  list-style-type: none;
  width: calc(100% - 20px);
  height: 45vh;
  overflow-y: scroll;
  padding: 0;
  margin-bottom: 10px;
  border-radius: 5px;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`;

const ProgramItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: start;
  text-align: start;
  padding: 10px;
  cursor: pointer;
  color: #000;

  &:hover {
    background-color: #e9ecef;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  appearance: none;
  width: 14px;
  height: 14px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-right: 10px;
  cursor: pointer;

  &:checked {
    background-color: #09348a;
    border-color: #09348a;
  }
  &:focus {
    outline: none;
  }
`;

const ConfirmButton = styled.div`
  padding: 15px;
`;

const ImportProgramsModal = ({ isOpen, onRequestClose, fold_yn }: any) => {
  const [dnStatus, setDnStatus] = useState<
    "ready" | "loading" | "error" | "success"
  >("ready");
  const [centers, setCenters] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState("");
  const [programs, setPrograms] = useState([]);
  const [selectedPrograms, setSelectedPrograms] = useState<any[]>([]);
  const [searchText, setSearchText] = useState("");
  const MLoginInfo = useSelector((state: RootState) => state.MLoginInfo);

  const fetchCenters = async (searchText: string = "") => {
    try {
      const response = await ServerApi.m_mng_center({ center_nm: searchText });
      if (response && response.rsp_code === "100") {
        setCenters(response.array);
      } else {
        console.error("Failed to fetch centers.");
      }
    } catch (error) {
      console.error("Error fetching centers:", error);
    }
  };

  const fetchPrograms = useCallback(async () => {
    if (!selectedCenter) return; // 선택된 센터가 있는지 확인

    try {
      const data = {
        center_no: selectedCenter,
        c_day: "",
        pro_m_nm: searchText,
        fold_yn: fold_yn,
      };
      const response = await ServerApi.m_mng_pro_m(data);
      console.log("리스폰스", response, "데이타", data);
      if (response && response.rsp_code === "100") {
        setPrograms(response.array);
        console.log("프로그램 검색 응답:", response);
      } else {
        console.error("프로그램을 가져오는 데 실패했습니다.");
      }
    } catch (error) {
      console.error("API 호출 중 오류 발생:", error);
    }
  }, [selectedCenter, searchText, fold_yn]);

  const importSelectedPrograms = async () => {
    setDnStatus("loading");
    // 선택된 프로그램의 정보를 사용하여 API 호출
    try {
      const data = {
        center_no: MLoginInfo.MLoginInfo.center_no,
        array: selectedPrograms.map((program) => ({ pro_no: program.pro_no })),
      };
      const response = await ServerApi.m_mng_pro_m_have_i(data);
      console.log("가져온 데이타", data, "가져온 리스폰스", response);
      setTimeout(() => {
        setDnStatus("success");
        setTimeout(() => {
          if (response.rsp_code === "100") {
            setTimeout(() => {
              console.log("Programs imported successfully.");
              setTimeout(() => {
                setDnStatus("ready");
              }, 500);
            }, 1000);
          } else {
            console.error("Failed to import programs.");
          }
        }, 1000);
      }, 1000);
    } catch (error) {
      console.error("Error during the API call:", error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchCenters();
    }
  }, [isOpen]);

  useEffect(() => {
    fetchPrograms();
  }, [selectedCenter, searchText]);

  const toggleSelection = (program: any) => {
    const index = selectedPrograms.findIndex(
      (p: any) => p.pro_no === program.pro_no
    );
    if (index > -1) {
      setSelectedPrograms(
        selectedPrograms.filter((p: any) => p.pro_no !== program.pro_no)
      );
    } else {
      setSelectedPrograms([...selectedPrograms, program]);
    }
  };

  const submit = async () => {
    await importSelectedPrograms();
    setTimeout(() => {
      onRequestClose(selectedPrograms);

      // 상태 초기화
      setSelectedCenter("");
      setSearchText("");
      setSelectedPrograms([]);
      setDnStatus("ready");
    }, 1500);
  };

  const handleClose = async () => {
    onRequestClose(selectedPrograms);

    // 상태 초기화
    setSelectedCenter("");
    setSearchText("");
    setSelectedPrograms([]);
    setDnStatus("ready");
  };

  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentLabel="Import Programs"
      style={{
        overlay: { backgroundColor: "rgba(0, 0, 0, 0.4)", zIndex: "999" },
      }}
    >
      <ModalTitle>Import</ModalTitle>
      <StyledSelect
        onChange={(e) => setSelectedCenter(e.target.value)}
        value={selectedCenter}
      >
        <option value="">Select a center</option>
        {centers.map((center: any) => (
          <option key={center.center_no} value={center.center_no}>
            {center.center_nm}
          </option>
        ))}
      </StyledSelect>
      <StyledInput
        type="text"
        placeholder="Search programs"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <ProgramsList>
        {programs.map((program: any) => (
          <ProgramItem
            key={program.pro_no}
            onClick={() => toggleSelection(program)}
          >
            <Checkbox
              type="checkbox"
              checked={selectedPrograms.some(
                (p) => p.pro_no === program.pro_no
              )}
              onChange={() => {}}
              onClick={(e) => {
                e.stopPropagation();
                toggleSelection(program);
              }} // 이벤트 버블링 방지
            />
            {program.pro_m_nm}
          </ProgramItem>
        ))}
      </ProgramsList>
      <ConfirmButton>
        <NewSaveButton
          text={"SAVE"}
          dnStatus={dnStatus}
          setDnStatus={setDnStatus}
          myClick={submit}
        />
      </ConfirmButton>
    </StyledModal>
  );
};

export default ImportProgramsModal;
