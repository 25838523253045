import React from "react";
import { HeaderProps } from "../Interface/Interface";
import mobileLogo from "../../img/logo_mobile.png";
import symbol from "../../img/symbol.png";
import { Link } from "react-router-dom";

function Logo(props: HeaderProps) {
  const { logoType } = props;

  let imageUrl: string;

  if (logoType === 1) {
    imageUrl = mobileLogo;
  } else if (logoType === 2) {
    imageUrl = symbol;
  } else {
    imageUrl = mobileLogo;
  }

  return (
    <div className="mobileLogo">
      <Link to="/">
        <h1>
          <img src={imageUrl} alt={logoType === 1 ? "mobile logo" : "symbol"} />
        </h1>
      </Link>
    </div>
  );
}

export default Logo;
