import React, { useState } from "react";
import styled from "styled-components";
import backGround from "../img/new2.png";
import JoinFormComponent from "./Compornent/NewJoinForm";

const MobileContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const BackGround = styled.div`
  height: 100%;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.9)
    ),
    url(${backGround});
  background-size: cover;
  background-position: calc(50% + 120px) center;
  z-index: 9;
  overflow: hidden;

  @media screen and (max-width: 400px) {
    background-position: center;
  }
`;

const TitleWrap = styled.div`
  width: 100%;
  text-align: center;
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, 50%);
  color: #fff;
  font-family: Poppins-Italic, serif;
  font-size: 2.4rem;
  line-height: 2rem;
  font-weight: 800;

  span {
    font-family: Suit, serif;
    font-size: 0.9rem;
    font-weight: 500;
  }

  @media screen and (max-width: 400px) {
    top: 10%;
  }

  @media (max-height: 500px) {
    display: none;
  }
`;

const JoinContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  bottom: 42%;
  left: 50%;
  transform: translate(-50%, 50%);
`;

function JoinPageT() {
  const fieldsToShow = ["place", "name", "phone", "email", "password"];
  const linkToShow = ["trainer"];

  return (
    <MobileContainer>
      <BackGround />
      <TitleWrap>
        <p>Trainer</p>
        <span>밋 코치 계정 가입을 환영합니다!</span>
      </TitleWrap>
      <JoinContainer>
        <JoinFormComponent
          fieldsToShow={fieldsToShow}
          linksToShow={linkToShow}
        />
      </JoinContainer>
    </MobileContainer>
  );
}

export default JoinPageT;
