import '../css/DragItem.css';
import VideoAddItem from "./VideoAddItem";
import ic_copy_ol from '../img/ic_copy_ol.png';

const ExVideoItem = ({ item, idx, arrProVideos, setArrProVideos }: any) => {

  return (
    <div className="dndItemWrap">
      <div className='videoDndWrapIn'>
        <div className="videoDndWrapRow">
          <span className={"handle"} style={{ padding: '3px 6px', cursor: 'pointer' }}>{`:::`}</span>
          <span className="modalProgramDndIndex">{'NO.' + (parseInt(idx) + 1) + ''}</span>
          <span className="dndItem-key">{item.mv_no}</span>

          <div style={{ display: 'flex', flex: '1' }}></div>

          <span className="videoDndWrap-del" onClick={() => {
            arrProVideos.splice(idx, 1);
            const newPic = [...arrProVideos];
            setArrProVideos(newPic);
          }}>X</span>
        </div>

        <div className="videoDndWrapRow">
          <VideoAddItem item={item} />
        </div>

        <div className="videoDndWrapRow">
          <span className="videoDndWrap-imgTitle">{item.mv_nm}</span>

          <img style={{ width: '17px', height: '17px', marginLeft: '0px', cursor: 'pointer' }} src={ic_copy_ol} alt="logo image" onClick={() => {
            const newPic = [...arrProVideos];
            newPic.splice((idx + 1), 0, item);
            setArrProVideos(newPic);
          }} />
        </div>
      </div>
    </div>
  );
}

export default ExVideoItem;
