import React, {useState} from "react";
import styled, {css} from "styled-components";
import {useHistory} from "react-router-dom";
import * as ServerApi from "../constants/ServerApiM";
import Modal from "react-modal";
import backGround from "../img/new2.png";
import emailImg from '../img/email.png'
import phoneImg from '../img/phone.png'
import keyImg from '../img/key.png'
import passwordImg from '../img/password.png'
import NewButtons from "./Compornent/NewButtons";

interface JoinBoxProps {
    disabled?: boolean; // Make it optional if not always required
}

const MobileContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
`;

const BackGround = styled.div`
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(3, 7, 23, 0.1), rgba(3, 7, 23, 0.9)),
    linear-gradient(to top, rgb(3, 7, 23), transparent 70%),
    url(${backGround});
    background-size: cover;
    background-position: calc(50% + 120px) center;
    z-index: 2;

    @media screen and (max-width: 400px) {
        background-position: center;
    }
`;

const TitleWrap = styled.div`
    width: 100%;
    text-align: center;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, 50%);
    color: #fff;
    font-family: Poppins-Italic, serif;
    font-size: 1.8rem;
    line-height: 2rem;
    font-weight: 800;

    @media screen and (max-width: 400px) {
        top: 10%;
    }
    
    @media (max-height: 500px) {
        display: none;
    }
`;

const sharedStyles = css`
    padding: 0 20px;
    border-radius: 8px;
    height: 40px;
`;

const JoinFormContainer = styled.form`
    width: 80%;
    height: 100%;
`;

const JoinContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    bottom: 45%;
    left: 50%;
    transform: translate(-50%, 50%);

    @media screen and (max-width: 400px) {
        bottom: 50%;
    }
`;

const JoinBox = styled.div<JoinBoxProps>`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    background-color: ${props => props.disabled ? 'rgba(49, 54, 84, 1)' : 'rgba(49, 54, 84, 0.3)'};
    width: 100%;
    padding: 0 20px;
    margin-top: 15px;
    border-radius: 10px;
    border: none;
    backdrop-filter: blur(6px);
    position: relative;
    opacity: ${props => props.disabled ? 0.5 : 1};
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
`;

const JoinBoxStyle = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin: 10px auto 10px auto;
    background: transparent;

    input {
        background: transparent;
        border: none;
        color: #ffffff;
        margin-left: 5px;
    }

    img {
        width: 18px;
    }
`;

const Input = styled.input`
    ${sharedStyles};
    width: 100%;
    border: none;
    margin: 0;
`;

const ButtonInsideInput = styled.button`
    position: absolute;
    top: 22px;
    right: 14px;
    border: none;
    background-color: transparent;
    display: flex;

    color: #ffffff;
    cursor: pointer;

    &:hover {
        color: #0056b3;
    }
`;

const ButtonContainer = styled.div`
    position: fixed;
    bottom: -70px;
    left: 50%;
    transform: translate(-50%, 50%);
`;

const ForgotPassword: React.FC = () => {
    const history = useHistory();
    const [isVerified, setIsVerified] = useState(false);
    const [dnStatus, setDnStatus] = useState<
        "ready" | "loading" | "error" | "success"
    >("ready");

    // 패스워드 찾기
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [no, setNo] = useState("");
    const [emailSent, setEmailSent] = useState(false);

    // 모달
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    const openModal = (message: any) => {
        setModalMessage(message);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    // 모달 끝

    const handleSubmit = (e: any) => {
        e.preventDefault();
        console.log("제출하기");
    };

    // 전화번호 입력 상태 업데이트
    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        let cleaned = value.replace(/\D/g, ""); // 숫자가 아닌 모든 문자를 제거
        let formatted = "";

        // 숫자를 적절한 형태로 포맷팅
        if (cleaned.length > 3) {
            formatted = `${cleaned.slice(0, 3)}-`;
            if (cleaned.length <= 7) {
                formatted += `${cleaned.slice(3)}`;
            } else {
                formatted += `${cleaned.slice(3, 7)}-${cleaned.slice(7, 11)}`;
            }
        } else {
            formatted = cleaned;
        }

        setPhone(formatted); // 포맷된 문자열을 상태로 설정
    };

    // 이메일 입력 상태 업데이트
    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    // 인증번호 입력 상태 업데이트
    const handleVerificationCodeChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setVerificationCode(e.target.value);
    };

    // 새 비밀번호 입력 상태 업데이트
    const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(e.target.value);
    };

    // 새 비밀번호 확인 입력 상태 업데이트
    const handleConfirmPasswordChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setConfirmPassword(e.target.value);
    };

    // 인증메일 전송 함수
    const sendVerificationCode = async () => {
        console.log("인증번호 메일 발송 누름");
        try {
            // UI에서 표시된 전화번호에서 '-'를 제거하여 실제 데이터 형식으로 변환
            const phoneForServer = phone.replace(/-/g, "");

            const result = await ServerApi.m_app_email_pw_find({
                userid: email,
                handphone: phoneForServer,
            });
            if (result && result.rsp_code === "100") {
                // 성공 응답 코드 확인
                openModal("인증번호 발송 성공");
                setNo(result.no);
                setEmailSent(true);
            } else if (result && result.rsp_code === "200") {
                openModal("이메일과 휴대전화번호를 확인해주세요");
            } else if (result && result.rsp_code === "999") {
                openModal("인증번호 발송 실패");
            } else {
                console.error("인증번호 발송 실패");
            }
        } catch (error) {
            console.error("인증번호 발송 중 오류 발생:", error);
        }
    };

    // 인증번호 확인 함수
    const checkVerificationCode = async () => {
        if (!verificationCode) {
            alert("인증번호를 입력해주세요.");
            return;
        }
        try {
            const result = await ServerApi.m_app_email_auth_confirm({
                no: no,
                auth_no: verificationCode,
            });
            console.log("인증번호", verificationCode, "빽에서오는 번호", no);
            if (result && result.rsp_code === "100") {
                openModal("인증 성공");
                setIsVerified(true);
            } else {
                openModal("인증번호를 확인해주세요");
                setIsVerified(false);
            }
        } catch (error) {
            console.error("인증 중 오류 발생:", error);
            setIsVerified(false);
        }
    };

    const validateInputs = () => {
        if (!isVerified) {
            setDnStatus("loading");
            setTimeout(() => {
                setDnStatus("error");
                setTimeout(()=>{
                    setDnStatus("ready");
                },1000)
            }, 1000);
            return false;
        }
        return true;
    };

    const handleSubmitClick = async () => {
        if (!validateInputs()) {
            return;
        }
        try {
            setDnStatus("loading");
            const result = await ServerApi.m_app_pwd_change({
                no: no,
                auth_no: verificationCode,
                password: newPassword,
            });
            console.log("새로운 비밀번호", result);
            console.log(
                "인증번호",
                verificationCode,
                "빽에서오는 번호",
                no,
                "새 패스워드",
                newPassword
            );
            if (result && result.rsp_code === "100") {
                setTimeout(() => {
                    setDnStatus("success");
                    setTimeout(() => {
                        history.push({pathname: "/ForgotSuccess"});
                        setDnStatus("ready");
                    }, 500);
                }, 1000);
            } else if (result && result.rsp_code === "300") {
                setTimeout(() => {
                    setDnStatus("error");
                    setTimeout(() => {
                        openModal("인증시간이 초과되었습니다. 다시 시도해주세요.");
                        setDnStatus("ready");
                    }, 1000);
                }, 1000);
            } else {
                setTimeout(() => {
                    setDnStatus("error");
                    setTimeout(() => {
                        openModal("패스워드를 확인해주세요");
                        setDnStatus("ready");
                    }, 1000);
                }, 1000);
            }
        } catch (error) {
            console.error("패스워드 변경 오류 발생:", error);
        }
    };

    return (
        <MobileContainer>
            <BackGround/>
            <TitleWrap>
                <p>ACCOUNT CHANGE</p>
            </TitleWrap>
            <JoinContainer>
                <JoinFormContainer onSubmit={handleSubmit}>
                    <JoinBox>
                        <JoinBoxStyle>
                            <img src={phoneImg} alt='Phone'/>
                            <Input
                                type="tel"
                                id="phone"
                                value={phone}
                                placeholder="Phone"
                                onChange={handlePhoneChange}
                            />
                        </JoinBoxStyle>
                    </JoinBox>
                    <JoinBox>
                        <JoinBoxStyle>
                            <img src={emailImg} alt='Email'/>
                            <Input
                                type="text"
                                id="email"
                                value={email}
                                placeholder="Email"
                                onChange={handleEmailChange}
                            />
                        </JoinBoxStyle>
                        <ButtonInsideInput
                            type="button"
                            onClick={sendVerificationCode}
                            // disabled={isVerified}
                        >
                            인증메일 전송
                        </ButtonInsideInput>
                    </JoinBox>
                    <JoinBox disabled={!emailSent || isVerified}>
                        <JoinBoxStyle>
                            <img src={keyImg} alt='Verification'/>
                            <Input
                                type="text"
                                id="verification-code"
                                value={verificationCode}
                                placeholder="Verification Code"
                                onChange={handleVerificationCodeChange}
                                disabled={!emailSent || isVerified}
                            />
                        </JoinBoxStyle>
                        <ButtonInsideInput
                            type="button"
                            onClick={checkVerificationCode}
                            disabled={!emailSent || isVerified}
                        >
                            확인
                        </ButtonInsideInput>
                    </JoinBox>
                    <JoinBox disabled={!isVerified}>
                        <JoinBoxStyle>
                            <img src={passwordImg} alt='New Password'/>
                            <Input
                                type="password"
                                id="new-password"
                                value={newPassword}
                                placeholder="New Password"
                                onChange={handleNewPasswordChange}
                                disabled={!isVerified}
                            />
                        </JoinBoxStyle>
                    </JoinBox>
                    <JoinBox disabled={!isVerified}>
                        <JoinBoxStyle>
                            <img src={passwordImg} alt='New Password Confirm'/>
                            <Input
                                type="password"
                                id="confirm-password"
                                value={confirmPassword}
                                placeholder="Confirm Password"
                                onChange={handleConfirmPasswordChange}
                                disabled={!isVerified}
                            />
                        </JoinBoxStyle>
                    </JoinBox>
                </JoinFormContainer>
                <ButtonContainer>
                    <NewButtons text='Continue' dnStatus={dnStatus} myClick={handleSubmitClick}
                                backGround={'white'}/>
                </ButtonContainer>
            </JoinContainer>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Alert Modal"
                className="modalAlertBox"
                style={{
                    overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.75)",
                    },
                }}
            >
                <h2>{modalMessage}</h2>
                <button onClick={closeModal}>OK</button>
            </Modal>
        </MobileContainer>
    );
};

export default ForgotPassword;
