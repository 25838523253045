import { useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import * as MyUtil from "../constants/MyUtil";
import imgDefault from "../img/bg_gym.jpg";
import '../css/DragItem.css';

const VideoAddItem = ({ item, idx, ModalCb }: any) => {
  const [isVideo, setIsVideo] = useState<boolean>(false);

  return (
    <div style={{ width: '200px', height: '150px', overflow: 'hidden' }} onClick={() => { setIsVideo(!isVideo) }}>
      {
        isVideo ? (
          <ReactPlayer
            width={'190px'}
            height={'190px'}
            url={item.mv_url}
            playing={true}
            controls={false}
            muted={false}
            progressInterval={1000}
            pip={false}
            loop={true}
          />
        ) : (
          <img style={{ width: '190px', height: '190px', objectFit: 'cover', borderRadius: '5px' }} src={MyUtil._isNull(item.mv_rep_img) ? imgDefault : item.mv_rep_img} alt="logo image" />
        )
      }
    </div>
  );
}

export default VideoAddItem;
