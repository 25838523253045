import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { MobileButtonProps } from "../Interface/Interface";
import { RootState } from "../../component/redux/rootReducer";
import { useSelector } from "react-redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import styled from "styled-components";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Buttons = styled.div`
  width: calc(40% - 20px);
  height: 40px;
  background: rgba(255, 255, 255, 0.17);
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 30px;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  margin-right: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;

  &:last-child {
    margin: 0;
  }

  &:hover,
  &:active {
    background: #09348a;
    color: #000;
    border: none;
  }
`;

function MobileButton({ buttonUse }: MobileButtonProps) {
  const history = useHistory();
  const mLoginInfo = useSelector(
    (state: RootState) => state.MLoginInfo.MLoginInfo
  );
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (mLoginInfo && mLoginInfo.member_type) {
      setIsLoggedIn(true);
    } else {
      // Redux에 데이터가 없으면 AsyncStorage에서 확인
      const checkLoginStatus = async () => {
        const storedLoginInfo = await AsyncStorage.getItem("@login_info");
        console.log("storedLoginInfo", storedLoginInfo);
        if (storedLoginInfo) {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      };

      checkLoginStatus();
    }
  }, [mLoginInfo]);

  const handleMobileLoginClick = () => {
    if (isLoggedIn) {
      // 이미 로그인 데이터가 존재하면 DayProgramM 페이지로 이동
      history.push("/DayProgramM");
    } else {
      // 로그인 데이터가 없으면 로그인 페이지로 이동
      history.push("/MLogin");
    }
  };

  const handleJoinBClick = () => {
    history.push("/JoinPageB");
  };

  const handleJoinTClick = () => {
    history.push("/JoinPageT");
  };

  const handleBusinessClick = () => {
    history.push("/BusinessHome");
  };

  const handleTrainerClick = () => {
    history.push("/TrainerHome");
  };

  return (
    <ButtonContainer>
      {buttonUse.includes("joinB") && (
        <Buttons onClick={handleJoinBClick}>Join</Buttons>
      )}
      {buttonUse.includes("loginB") && (
        <Buttons onClick={handleMobileLoginClick}>Login</Buttons>
      )}
      {buttonUse.includes("joinT") && (
        <Buttons onClick={handleJoinTClick}>Join</Buttons>
      )}
      {buttonUse.includes("loginT") && (
        <Buttons onClick={handleMobileLoginClick}>Login</Buttons>
      )}
      {buttonUse.includes("business") && (
        <Buttons onClick={handleBusinessClick}>Business</Buttons>
      )}
      {buttonUse.includes("trainer") && (
        <Buttons onClick={handleTrainerClick}>Trainer</Buttons>
      )}
    </ButtonContainer>
  );
}

export default MobileButton;
