const initialState = {
    videoUrls: [],
};

const videoUrlsReducer = (state = initialState, action:any) => {
    switch (action.type) {
        case "SET_VIDEO_URLS":
            return {
                ...state,
                videoUrls: action.payload,
            };
        default:
            return state;
    }
};

export default videoUrlsReducer;
