import React, { useState } from "react";
import styled from "styled-components";
import backGround from "../img/back_7.jpg";
import NewButtons from "./Compornent/NewButtons";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import allActions from "../component/redux/allActions";
import AsyncStorage from "@react-native-async-storage/async-storage";

const ProgramTiTle = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  color: #09348a;
`;

const DeniedBack = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    margin: 10px auto;
    line-height: 1.6rem;
    font-size: 1.1rem;
    color: #eee;
  }
`;

const MobileContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const BackGround = styled.div`
  height: 90%;
  background-image: linear-gradient(
      to bottom,
      rgba(3, 7, 23, 0.1),
      rgba(3, 7, 23, 0.9)
    ),
    linear-gradient(to top, rgb(3, 7, 23), transparent 30%), url(${backGround});
  background-size: cover, cover, cover;
  background-position: center, center, center top;
  background-repeat: no-repeat;
  z-index: 9;
  overflow: hidden;

  @media screen and (max-width: 400px) {
    background-position: center, center, center;
  }
`;

const Gradient = styled.div`
  height: 100%;
  background-color: rgb(3, 7, 23);
  z-index: 9;
  overflow: hidden;
`;

const TitleWrap = styled.div`
  width: 100%;
  text-align: center;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, 50%);
  color: #fff;
  font-family: Poppins-Italic, serif;
  font-size: 2.4rem;
  line-height: 2rem;
  font-weight: 800;

  span {
    font-family: Suit, serif;
    font-size: 0.9rem;
    font-weight: 500;
  }

  @media screen and (max-width: 400px) {
    top: 35%;
  }
`;

const TextWrap = styled.div`
  width: 100%;
  font-family: Suit, serif;
  font-size: 1rem;
  color: #f2f2f2;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  line-height: 1.6rem;

  span {
    margin: 20px 0;
  }

  span::after {
    content: "";
    width: calc(60% - 10px);
    border-bottom: 1px solid #ffffff;
    position: absolute;
    top: 90px;
    left: 50%;
    transform: translate(-50%, 50%);
  }

  @media screen and (max-width: 400px) {
    top: 40%;
  }
`;

const Text = styled.p`
  font-size: 0.9rem;
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translate(-50%, 50%);

  @media screen and (max-width: 400px) {
    bottom: 10%;
  }
`;

const DeniedLogin: React.FC = () => {
  const [dnStatus, setDnStatus] = useState<
    "ready" | "loading" | "error" | "success"
  >("ready");
  const history = useHistory();
  const dispatch = useDispatch();

  const handleButtonClick = () => {
    setDnStatus("loading");
    setTimeout(() => {
      setDnStatus("success");
      setTimeout(() => {
        // Redux
        dispatch(allActions.logOut());

        // AsyncStorage
        if (AsyncStorage) {
          AsyncStorage.removeItem("@login_info");
        }

        // SessionStorage
        sessionStorage.removeItem("login_info");

        // Cookie
        document.cookie.split(";").forEach((c) => {
          document.cookie = c
            .replace(/^ +/, "")
            .replace(
              /=.*/,
              "=;expires=" + new Date().toUTCString() + ";path=/"
            );
        });

        // 웹뷰에 로그아웃 메세지 전송
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ type: "LOGOUT" })
          );
        }
        history.push({ pathname: "/MLogin" });
      }, 500);
    }, 1000);
  };

  return (
    <>
      <MobileContainer>
        <BackGround />
        <Gradient />
        <TitleWrap>Sorry,</TitleWrap>
        <TextWrap>
          <Text>승인 대기중이에요.</Text>
          <Text style={{ marginTop: "15px" }}>조금만 기다려주세요</Text>
          <span></span>
          <Text>I'm waiting for your approval.</Text>
          <Text style={{ marginTop: "15px" }}>Please wait a moment.</Text>
        </TextWrap>
        <ButtonContainer>
          <NewButtons
            text="Go Back"
            dnStatus={dnStatus}
            myClick={handleButtonClick}
            backGround={"white"}
          />
        </ButtonContainer>
      </MobileContainer>
    </>
  );
};

export default DeniedLogin;
