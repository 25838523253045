import React, {useEffect, useRef} from 'react';
import styled, {css} from 'styled-components';

interface VideoPlayerProps {
    videoUrls: string[];
    thumbnailUrls: string[];
    isPlaying: boolean;
    isMuted: boolean;
    onVideoClick: (index: number) => void;
}

const VideoContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #0a0a0a;
`;

const StyledVideoItem = styled.div<{ isPlaying: boolean }>`
    position: relative;
    flex: 0 0 calc(100% - 15px);
    max-width: 300px;
    height: auto;
    margin-bottom: 10px;

    ${(props) =>
            props.isPlaying &&
            css`
                border: 2px solid red;
            `}
    video {
        width: 100%;
        height: auto;
    }
`;

const VideoPlayer = ({videoUrls, thumbnailUrls, isPlaying, isMuted, onVideoClick}: VideoPlayerProps) => {
    const videoRefs = useRef<Array<HTMLVideoElement | null>>([]);

    useEffect(() => {
        if (videoRefs.current[0]) {
            if (isPlaying) {
                videoRefs.current[0].play();
            } else {
                videoRefs.current[0].pause();
            }
        }
    }, [isPlaying]);

    useEffect(() => {
        if (videoRefs.current[0]) {
            videoRefs.current[0].muted = isMuted;
        }
    }, [isMuted]);

    return (
        <VideoContainer>
            {videoUrls.map((url, index) => (
                <StyledVideoItem
                    key={index}
                    onClick={() => onVideoClick(index)}
                    isPlaying={isPlaying && index === 0}
                >
                    <video
                        ref={(el) => videoRefs.current[index] = el}
                        src={url}
                        loop
                        autoPlay
                        muted
                        playsInline
                        preload="auto"
                        poster={thumbnailUrls[index]}
                    />
                </StyledVideoItem>
            ))}
        </VideoContainer>
    );
};

export default VideoPlayer;

