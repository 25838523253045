import { useCallback, useState, useEffect, useRef } from 'react';
import { Modal } from 'antd';
import { useMutation, QueryFunctionContext, useQuery } from 'react-query';
import Select from "react-dropdown-select";
import * as MyUtil from "../constants/MyUtil";
import * as SG from '../constants/Signature';
import * as ServerApi from "../constants/ServerApi";
import icSearch from '../img/ic_search.png';
import '../css/CssOverride.css';
import Colors from '../constants/Colors';
import VideoAddItem from './VideoAddItem';
const isMobile = MyUtil.isMobile;


const ModalVideoSelect = ({ ModalCb, isModalOpen }: SG.ModalProps) => {
  const modalWidth = isMobile ? 'calc(100% - 40px)' : '680px';
  const [loading, setLoading] = useState<boolean>(false);
  const [targetType, setTargetType] = useState<any>({ cd: "", nm: "" });
  const [arrCate, setArrCate] = useState<any>([{
    label: '________________________________',
    value: '________________________________'
  }]);
  const [arrSelectItem, setArrSelectItem] = useState<any>([]);
  const [arrVideItem, setArrVideItem] = useState<null | []>([]);
  const [moreView, setMoreView] = useState<boolean>(false);
  const loader = useRef<HTMLDivElement>(null);
  const pageNo = useRef<any>(0);
  const mData = useRef<any>([]);
  const isEndPage = useRef<any>(false);
  const [searchText, setSearchText] = useState<string>('');


  useEffect(() => {
    // const option = { root: null, rootMargin: "150px", threshold: 0 };
    // const observer = new IntersectionObserver(handleObserver, option);
    // if (loader.current) observer.observe(loader.current);
    pageNo.current = 0
    mData.current = []
    m_mng_mv(targetType.cd, '');
  }, []);


  // ** 페이징 처리
  // const handleObserver = useCallback((entries) => {
  //   console.log("***************************************")
  //   console.log("***************************************")
  //   console.log("***************************************")
  //   const target = entries[0];
  //   if (target.isIntersecting) {
  //     m_mng_mv(targetType.cd);
  //   }
  // }, [arrVideItem, targetType]);



  const m_mng_mv = useCallback(async (gettargetType, getSearchText) => {
    if (isEndPage.current) return;
    const nextPage = pageNo.current + 1;
    pageNo.current = nextPage;
    let newArray: any = [];
    let arrPrev = mData.current;

    let result: any = '';
    result = await ServerApi.m_mng_mv({ mv_category_no: gettargetType, page: nextPage + '', mv_nm: getSearchText });

    const { isOk, data } = MyUtil._isResultSucces('m_mng_mv', result);
    if (isOk) {
      const serverData: any = data.array;

      if (serverData.length === 0) {
        isEndPage.current = true
        setMoreView(false);
        newArray = arrPrev;
        mData.current = newArray;

      } else {

        if (nextPage === 1) {
          newArray = serverData;
          mData.current = newArray;
          setArrVideItem(newArray);

        } else {
          newArray = [...arrPrev, ...serverData];
          mData.current = newArray;
          setArrVideItem(newArray);
        }
        setMoreView(true);
      }

    } else {
      setArrVideItem(null);
      MyUtil._alertMsg('m_mng_mv', data);
    }

    setLoading(false);
  }, [arrVideItem]);



  const m_mng_mv_category_query = useQuery([arrCate], (ctx: QueryFunctionContext) => { return ServerApi.m_mng_mv_category({ mv_category_nm: '' }); }, {
    retry: 1, enabled: arrCate === null ? false : true, // staleTime: 2 * 1000,
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_mv_category', error); },
    onSuccess: (result: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_mv_category', result);
      if (isOk) {
        const allItem = data.array;
        const options: any = [], values: any = [];

        // for (let i = 0; i < allItem.length; i++) {
        //   options.push(allItem[i].mv_category_nm + '');
        //   values.push(allItem[i].mv_category_no + '')
        // };
        // setArrOption(options);
        // setArrValue(values);
        const demoOptions = allItem.map((option: any) => ({
          ...option,
          label: option.mv_category_nm,
          value: option.mv_category_no
        }));

        setArrCate(demoOptions);
      } else {
        setArrCate([]);
        MyUtil._alertMsg('m_mng_mv_category', data);
      };
    },
  });




  return (
    <Modal
      width={modalWidth}
      visible={isModalOpen}
      title=""
      onOk={() => { ModalCb(false, {}); }}
      onCancel={() => { ModalCb(false, {}); }}
      footer={[]}
    >
      <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', position: 'relative', flex: 1, flexDirection: 'column' }}>
        <div className="modalIpWrap" style={{ marginTop: '12px', paddingBottom: '15px' }}>
          <span className="modalIpTitle">Category</span>
          {
            !MyUtil._isNull(arrCate) && <Select style={{ minWidth: '360px' }} values={[]} options={arrCate} onChange={(values: any) => {
              const myItme = values[0];
              setTargetType({ cd: myItme.mv_category_no, nm: myItme.mv_category_nm });
              setArrVideItem([]);
              pageNo.current = 0;
              mData.current = [];
              isEndPage.current = false;
              m_mng_mv(myItme.mv_category_no,searchText);
            }} />
          }

          <div style={{ display: 'flex', flex: '1' }}></div>

          <span className='itembox01-bottomBtnWrap-btnText22' style={{ color: '#ffffff', paddingLeft: '20px', paddingRight: '20px', fontSize: '11px', borderRadius: '25px', fontWeight: '600', marginRight: '40px' }} onClick={() => {
            ModalCb(true, arrSelectItem);
          }}>ADD</span>
        </div>



        <div className="searchBox-pv" style={{}}>
          <input type="text" className="searchBox-IpWrap" placeholder="SEARCH"
            value={searchText} onChange={(evt) => setSearchText(evt.target.value)}></input>

          <img className='searchBox-ImgBtn' src={icSearch} alt="검색 버튼" onClick={() => { 
              setArrVideItem([]);
              pageNo.current = 0;
              mData.current = [];
              isEndPage.current = false;
              m_mng_mv(targetType.cd,searchText);
          }} />
        </div>



        <div className="container-wrap" style={{ paddingLeft: '0px', paddingRight: '0px', maxHeight: '350px', marginTop: '10px', overflowY: 'auto' }}>
          {
            !MyUtil._isNull(arrVideItem) && arrVideItem?.map((videoItem: any, idx) => {
              let isSelected = false;

              const isInclude = arrSelectItem.findIndex((element: any) => (element.mv_no === videoItem.mv_no));
              if (isInclude > -1) {
                isSelected = true;
              } else {
                isSelected = false;
              };

              return (
                <div key={idx} className={'itemBoxModalVideo'} style={{ backgroundColor: '#ffffff', position: 'relative', overflow: 'hidden', borderRadius: '5px' }}>

                  <VideoAddItem key={idx} item={videoItem} ModalCb={ModalCb} />

                  <div style={{ width: '100%', height: '38px', position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.40)', bottom: '0px', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                    <span style={{ fontSize: '0.8em', color: '#ffffff', flex: 1, paddingLeft: '10px', lineHeight: '1.1em' }}>{videoItem.mv_nm}</span>
                    <span className="itemBoxModal-bottomBtnWrap-btnText" style={{
                      marginRight: '10px', color: isSelected ? '#ffffff' : Colors.mainColor, borderColor: Colors.mainColor, backgroundColor: isSelected ? Colors.mainColor : '#ffffff', fontWeight: '600', fontSize: '1.4em', padding: '3px 10px 0px 10px', lineHeight: '1em'
                    }}
                      onClick={() => {
                        let selectVideo = { ...videoItem, ...{ id: parseInt(videoItem.mv_no) } };
                        const isInclude = arrSelectItem.findIndex((element: any) => (element.mv_no === selectVideo.mv_no));
                        if (isInclude > -1) {
                          arrSelectItem.splice(isInclude, 1);
                        } else {
                          arrSelectItem.push(selectVideo);
                        };

                        const newItem = [...arrSelectItem];
                        setArrSelectItem(newItem);
                        // ModalCb(true, selectVideo);
                      }}>{isSelected ? 'v' : '+'}</span>
                  </div>
                </div>
              )
            })
          }

          {
            moreView ? (
              <div className="modalSaveBtn" onClick={() => {
                m_mng_mv(targetType.cd,searchText);
              }}>
                <span style={{ color: '#ffffff', fontSize: '2.8em', fontWeight: 'bold', marginTop: '3px' }}>+</span>
              </div>
            ) : (
              <div className="modalSaveBtn" style={{ backgroundColor: 'rgba(0,0,0,0)', cursor: 'none', borderWidth: 0 }}>
                <span style={{ color: 'gray' }}>This is the last page.</span>
              </div>
            )
          }
        </div>



        {/* <div ref={loader} /> */}
      </div>
    </Modal>
  );
}
export default ModalVideoSelect;