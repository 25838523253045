import axios from "axios";
import Config from "./Config";
import CST, {STORAGE_S} from './Constants';
import AsyncStorage from "@react-native-async-storage/async-storage";
import {isBrowser} from "react-device-detect";
import {Modal} from "antd";
import {useState} from "react";

export function _consoleLog(text: any) {
    if (Config.IS_LOG) {
        console.log('\n\n\n** (myLog) ** \n' + text);
    }
}

export function _httpReq(methodName: string, data: any) {
    let url = Config.API_URL + methodName;

    _consoleLog('============ >>>>>> ' + url + ' () 요청 - ' + JSON.stringify(data));

    return axios({
        method: 'post',
        url: url,
        headers: {'Content-Type': 'application/json'},
        data: data
    })
        .then(response => {
            console.log('API 응답:', response.data);
            return response.data.resObject;
        })
        .catch(error => {
            console.error('API 호출 중 오류 발생:', error);
            throw error;
        });
}

export async function _httpLegacyReq(methodName: string, data: any) {
    let result: any;
    let url = Config.API_URL + methodName;

    _consoleLog('============ >>>>>> ' + url + ' () 요청 - ' + JSON.stringify(data));

    try {
        let response: any = await axios({
            method: 'post',
            url: url,
            headers: {'Content-Type': 'application/json'},
            data: data
        });

        let responseOK = response && response.status === 200
        if (responseOK) {
            result = response.data.resObject;
            _consoleLog('============ <<<<<< ' + methodName + '() 정상 result : ' + JSON.stringify(result));

            return {
                IS_SUCCESS: true,
                DATA_RESULT: result
            };

        } else {
            result = response.error
            _consoleLog('============ <<<<<< ' + methodName + '() 응답 status error : ' + result)

            return {
                IS_SUCCESS: false,
                DATA_RESULT: {
                    RSP_CDOE: 801,
                    MSG: `네트워크 환경이 불안정합니다. 앱을 재시작해주세요.\n\n${methodName}\n(${result})`
                }
            };
        }

    } catch (error: any) {
        _consoleLog('============ <<<<<< ' + methodName + '() 네트워크 error : ' + error);
        alert(`네트워크 환경이 불안정합니다. 앱을 재시작해주세요.\n\n${methodName}\n(${error.message})`);

        return {
            IS_SUCCESS: false,
            DATA_RESULT: {
                RSP_CDOE: 801,
                MSG: `네트워크 환경이 불안정합니다. 앱을 재시작해주세요.\n\n${methodName}\n(${error.message})`
            }
        };
    }
}

export function _httpGetReq(methodName: string, data: any) {
    let url = Config.API_URL + methodName;

    _consoleLog('============ >>>>>> ' + url + ' () 요청 - ' + JSON.stringify(data));

    return axios({
        method: 'get',
        url: url,
        headers: {'Content-Type': 'application/json'},
        data: data
    });
}

export async function _httpReq2(methodName: string, data: any) {
    let result: any;
    let url = Config.API_URL + methodName;

    _consoleLog('============ >>>>>> ' + url + ' () 요청 - ' + JSON.stringify(data));

    try {
        let response: any = await axios({
            method: 'post',
            url: url,
            headers: {'Content-Type': 'application/json'},
            data: data
        });

        let responseOK = response && response.status === 200;
        if (responseOK) {
            result = response.data;
            _consoleLog('============ <<<<<< ' + methodName + '() 정상 result : ' + JSON.stringify(result));

            return {
                IS_SUCCESS: true,
                DATA_RESULT: result
            };

        } else {
            result = response.error;
            _consoleLog('============ <<<<<< ' + methodName + '() 응답 status error : ' + result);

            return {
                IS_SUCCESS: false,
                DATA_RESULT: {
                    RSP_CDOE: 801,
                    MSG: `네트워크 환경이 불안정합니다. 앱을 재시작해주세요.\n\n${methodName}\n(${result})`
                }
            };
        }
    } catch (error: any) {
        _consoleLog('============ <<<<<< ' + methodName + '() 네트워크 error : ' + error);
        alert(`네트워크 환경이 불안정합니다. 앱을 재시작해주세요.\n\n${methodName}\n(${error.message})`);

        return {
            IS_SUCCESS: false,
            DATA_RESULT: {
                RSP_CDOE: 801,
                MSG: `네트워크 환경이 불안정합니다. 앱을 재시작해주세요.\n\n${methodName}\n(${error.message})`
            }
        };
    }
}

export async function _httpGetReq2(reqURL: any) {
    let result = '';

    _consoleLog('============ >>>>>> ' + reqURL + ' () 요청 - ');

    try {
        let response: any = await axios({
            method: 'get',
            url: reqURL,
        });

        let responseOK = response && response.status === 200;
        if (responseOK) {
            result = response.data;
            _consoleLog('============ <<<<<< ' + '() 정상 result : ' + JSON.stringify(result));

            return {
                IS_SUCCESS: true,
                DATA_RESULT: result
            };

        } else {
            result = response.error;
            _consoleLog('============ <<<<<< ' + '() 응답 status error : ' + result);
            alert(`네트워크 환경이 불안정합니다. 앱을 재시작해주세요.\n\n(${result})`);

            return {
                IS_SUCCESS: false,
                DATA_RESULT: result
            };
        }

    } catch (error: any) {
        _consoleLog('============ <<<<<< ' + '() 네트워크 error : ' + error);
        alert(`네트워크 환경이 불안정합니다. 앱을 재시작해주세요.\n\n(${error.message})`);

        return {
            IS_SUCCESS: false,
            DATA_RESULT: error
        };
    }
}

export async function _multiPartReq(methodName: any, formData: any) {
    let result: any = {'result': true};
    let url = Config.API_URL + methodName;
    let logs = "";

    for (var pair of formData.entries()) {
        logs = logs + '\n' + pair[0] + ', ' + pair[1];
    }

    _consoleLog('============ >>>>>> ' + methodName + ' () 요청 - ' + logs);
    try {
        let response: any = await axios({
            method: 'post',
            url: url,
            headers: {'content-type': 'multipart/form-data'},
            data: formData
        });

        let responseOK = response && response.status === 200;
        if (responseOK) {
            result = response.data;
            _consoleLog('============ <<<<<< () 정상 result : ' + JSON.stringify(result));

            return {
                IS_SUCCESS: true,
                DATA_RESULT: result
            };

        } else {
            result = response.error;
            _consoleLog('============ <<<<<< () 응답 status error : ' + result);
            return {
                IS_SUCCESS: false,
                DATA_RESULT: result
            };
        }

    } catch (error) {
        result = error;
        _consoleLog('============ <<<<<< () 응답 status error : ' + result);
        return {
            IS_SUCCESS: false,
            DATA_RESULT: result
        };
    }
}

export function _isNullExceptZero(obj: any) {
    if (typeof (obj) === 'undefined') {
        return true;
    } else if (obj === 'undefined') {
        return true;
    } else if (obj === null) {
        return true;
    } else if (obj === 'null') {
        return true;
    } else if (obj === '') {
        return true;
    } else if (obj === 'NaN') {
        return true;
    } else if (obj === 0) { // 0을 유효한 값으로 간주
        return false;
    } else if (obj.length === 0) {
        return true;
    } else return obj === '0.0';
}

export function _isNull(obj: any) {
    if (typeof (obj) === 'undefined') {
        return true;
    } else if (obj === 'undefined') {
        return true;
    } else if (obj === null) {
        return true;
    } else if (obj === 'null') {
        return true;
    } else if (obj === '') {
        return true;
    } else if (obj === 'NaN') {
        return true;
    } else if (obj === 0) {
        return true;
    } else if (obj === '0') {
        return true;
    } else if (obj.length === 0) {
        return true;
    } else if (obj.length === '0.0') {
        return true;
    } else {
        return false;
    }
};

export async function _sessionCheck(MLoginInfo: any, dispatch: any, history: any, allActions: any) {
    // 실행 환경이 웹인지 확인
    const isBrowser = typeof window !== "undefined";

    // 로그인 정보 불러오기
    const getLoginInfo = async () => {
        try {
            let jsonValue;
            if (isBrowser) {
                jsonValue = localStorage.getItem('@login_info');
            } else {
                jsonValue = await AsyncStorage.getItem('@login_info');
            }
            return jsonValue != null ? JSON.parse(jsonValue) : null;
        } catch (e) {
            console.error("Error fetching login info", e);
            return null;
        }
    };

    let loginInfo = await getLoginInfo();

    // 로그인 정보 유효성 검사
    if (!loginInfo || !loginInfo.center_no) {
        alert('로그인 정보가 만료되었습니다. 다시 로그인해주세요.');
        // 로그인 정보 초기화
        if (isBrowser) {
            localStorage.removeItem('@login_info');
        } else {
            await AsyncStorage.removeItem('@login_info');
        }
        dispatch(allActions.logOut());

        // 사용자를 로그인 페이지로 리디렉션
        history.push('/MLogin');
    } else {
        // 로그인 정보가 유효한 경우
        console.log('로그인 상태 유지');
    }
}

// export async function _sessionCheck(getRxInfo: any, history: any, dispatch: any, allActions: any) {
//     // 리액트 네이티브에서의 로그인 정보 불러오기
//     const getLoginInfoNative = async () => {
//         try {
//             const jsonValue = await AsyncStorage.getItem('@login_info');
//             return jsonValue != null ? JSON.parse(jsonValue) : null;
//         } catch (e) {
//             // 오류 처리
//         }
//     };
//
//     // 리액트 웹에서의 로그인 정보 불러오기
//     const getLoginInfoWeb = () => {
//         const loginInfo = sessionStorage.getItem(STORAGE_S.LOGIN_INFO);
//         return loginInfo ? JSON.parse(loginInfo) : null;
//     };
//
//     let loginInfo;
//     if (isBrowser) {
//         loginInfo = getLoginInfoWeb();
//     } else {
//         loginInfo = await getLoginInfoNative();
//     }
//
//     // 로그인 정보 유효성 검사
//     if (!loginInfo || _isNullExceptZero(loginInfo.center_no)) {
//         Modal.info({
//             title: '알림',
//             content: '로그인 정보가 만료되었습니다. 다시 로그인해주세요.',
//             onOk() {
//                 if (isBrowser) {
//                     history.push({pathname: '/'});
//                 } else {
//                     history.push({pathname: '/MLogin'});
//                 }
//             },
//         });
//
//         sessionStorage.setItem(STORAGE_S.LOGIN_INFO, "");
//
//     } else {
//         return '';
//     }
// }

export function _addBackSlash(getString: string) {
    let chString = "";
    try {
        chString = getString + '';
        chString = getString.replace(/\'/gi, "\\'");
        chString = chString.replace(/\"/gi, '\\"');
        chString = chString.replace(/\`/gi, '\\`');

        return {isOk: true, chString: chString};

    } catch (error) {
        alert(`내용에 특수문자와\n이모지는 제외해주세요!`);
        return {isOk: false, chString: 'ADDBS error'};
    }
}

export function _isNullToStr(obj: any) {
    if (typeof (obj) === 'undefined') {
        return '';
    } else if (obj === 'undefined') {
        return '';
    } else if (obj === null) {
        return '';
    } else if (obj === 'null') {
        return '';
    } else if (obj === '') {
        return '';
    } else if (obj === 'NaN') {
        return '';
    } else if (obj === 0) {
        return '';
    } else if (obj === '0') {
        return '';
    } else if (obj.length === 0) {
        return '';
    } else if (obj.length === '0.0') {
        return '';
    } else {
        return obj;
    }
}

export function _isResultSucces(apiNm: any, dataResult: any) {
    _consoleLog('<<<<<<< ============ ' + apiNm + ' () 응답 - ' + JSON.stringify(dataResult));
    try {
        const formatResult = dataResult?.resObject;

        _consoleLog('<<<<<<< ============ ' + apiNm + ' () 응답 - ' + JSON.stringify(formatResult));

        if (formatResult?.rsp_code === CST.DB_SUCSESS) {
            return {isOk: true, data: formatResult};
        } else {
            return {isOk: false, data: formatResult};
        }
    } catch (error) {
        return {isOk: false, data: ''};
    }
}

export function _alertMsg(apiNm: any, dataResult: any) {
    if (!_isNull(dataResult?.msg)) {
        alert('' + dataResult?.msg)
    } else if (!_isNull(dataResult?.error)) {
        alert(`네트워크 환경이 불안정 합니다!\n${apiNm} : ${dataResult?.error}`)
    } else if (!_isNull(dataResult?.rsp_code)) {
        alert(`네트워크 환경이 불안정 합니다!\n${apiNm} : ${dataResult?.rsp_code}`)
    } else if (!_isNull(dataResult?.message)) {
        alert(`네트워크 환경이 불안정 합니다!\n${apiNm} : ${dataResult?.message}`)
    } else {
        alert(`네트워크 환경이 불안정 합니다!\n${apiNm}`)
    }
}

export function NumFormat(getNumber: string) {
    try {
        getNumber = getNumber.replace(/-/g, "");
        getNumber = getNumber.replace(/ /g, "");

        if (getNumber.length < 9) {
            if (getNumber.length > 5) {
                return getNumber.substr(0, 4) + "-" + getNumber.substr(4)
            } else {
                return getNumber;
            }
        } else {
            return getNumber.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3")
        }
    } catch (error) {
        return getNumber;
    }
}

// 천단위 콤마
export function _toThousandsCommas(num: any) {
    if (typeof (num) === 'undefined') {
        return "0";
    } else if (num === null) {
        return "0";
    } else if (num === "0") {
        return "0";
    } else if (num === "") {
        return "";
    } else {
        try {
            num = parseFloat(num);
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } catch (error) {
            return num;
        }
    }
}

export function _secondToTimeFormat(totalSeconds: any) {
    let returnData = 'err';
    try {
        // getSecond = parseInt(getSecond + '');
        // returnData = new Date(getSecond * 1000).toISOString().substr(11, 8);
        // totalSeconds = Number(totalSeconds);
        // if (isNaN(totalSeconds)) {
        //     return 'err'; // 또는 적절한 에러 메시지 반환
        // }

        let hours: any = Math.floor(totalSeconds / 3600);
        hours = (hours < 10) ? '0' + hours : hours;
        totalSeconds %= 3600;
        let minutes: any = Math.floor(totalSeconds / 60);
        minutes = (minutes < 10) ? '0' + minutes : minutes;
        let seconds: any = totalSeconds % 60;
        seconds = (seconds < 10) ? '0' + seconds : seconds;

        returnData = hours + ':' + minutes + ':' + seconds
    } catch (error) {
        console.log('_secondToTimeFormat error');
    }
    return returnData;
}

export function _secondToTimeSmallFormat(totalSeconds: any) {
    let returnData = 'err';
    try {
        // getSecond = parseInt(getSecond + '');
        // returnData = new Date(getSecond * 1000).toISOString().substr(11, 8);

        let hours: any = Math.floor(totalSeconds / 3600);
        hours = (hours < 10) ? '0' + hours : hours;
        totalSeconds %= 3600;
        let minutes: any = Math.floor(totalSeconds / 60);
        minutes = (minutes < 10) ? '0' + minutes : minutes;
        let seconds: any = totalSeconds % 60;
        seconds = (seconds < 10) ? '0' + seconds : seconds;

        if (hours === '00' && minutes === '00') {
            returnData = seconds;
        } else if (hours === '00') {
            returnData = minutes + ':' + seconds;
        } else {
            returnData = hours + ':' + minutes + ':' + seconds;
        }
    } catch (error) {
        console.log('_secondToTimeFormat error');
    }
    return returnData;
}

export function _secondToTimeArray(totalSeconds: any) {
    let returnData = ['0', '0', '0'];
    try {
        // getSecond = parseInt(getSecond + '');
        // returnData = new Date(getSecond * 1000).toISOString().substr(11, 8);

        let hours: any = Math.floor(totalSeconds / 3600);
        hours = (hours < 10) ? '0' + hours : hours;
        totalSeconds %= 3600;
        let minutes: any = Math.floor(totalSeconds / 60);
        minutes = (minutes < 10) ? '0' + minutes : minutes;
        let seconds: any = totalSeconds % 60;
        seconds = (seconds < 10) ? '0' + seconds : seconds;

        returnData = [hours, minutes, seconds];
    } catch (error) {
        console.log('_secondToTimeFormat error');
    }
    return returnData;
}
