import React from "react";
import styled from "styled-components";
import backGround from "../img/new3.png";
import NewMobileButton from "./Compornent/NewMobileButton";
import Logo from "../img/logo_mobile.png";

const MobileContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const BackGround = styled.div`
  height: 100%;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.9)
    ),
    url(${backGround});
  background-size: cover;
  background-position: calc(50% + 120px) center;
  z-index: 9;

  @media screen and (max-width: 400px) {
    background-position: center;
  }
`;

const TitleWrap = styled.div`
  width: 100%;
  z-index: 10;
  text-align: center;
  position: absolute;
  bottom: 24%;
  left: 50%;
  transform: translate(-50%, 50%);
  color: #fff;
  font-family: Poppins-Italic, serif;
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-weight: 800;

  @media screen and (max-width: 400px) {
    background-position: center;
  }
`;

const LogoWrap = styled.div`
  width: 100%;
  z-index: 10;
  text-align: center;
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 50%);

  img {
    width: 100px;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(161deg)
      brightness(101%) contrast(101%);
  }
`;

const ButtonWrap = styled.div`
  width: 100%;
  z-index: 99;
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 50%);
`;

const BusinessHome = () => {
  const buttonUse = ["joinB", "loginB"];

  return (
    <MobileContainer>
      <BackGround />
      <LogoWrap>
        <img src={Logo} alt="Logo" />
      </LogoWrap>
      <TitleWrap>
        <p>Business</p>
      </TitleWrap>
      <ButtonWrap>
        <NewMobileButton buttonUse={buttonUse} />
      </ButtonWrap>
    </MobileContainer>
  );
};

export default BusinessHome;
