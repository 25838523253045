import React from "react";
import { ApprovalButton, ToggleButton } from "./ToggleButton";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import circleMan from "../../img/person_circle_black.svg";
import { M_IMG } from "../../constants/Config";
import { Member } from "../Interface/Interface";

interface SetupSquareImgProps {
  ml10?: boolean;
}

interface SetupSquareProps {
  userData: Member;
  setupSquareUse: string;
  onUpdate?: (updatedMember: Member) => void;
}

const SetupSquareContainer = styled.div`
  border: 1px solid #000;
  background-color: #fff;
  border-radius: 15px;
  width: 90%;
  margin: 0 auto 20px auto;
`;

const TitleWrap = styled.div`
  margin-left: 15px;
`;

const SetupSquareWrap = styled.div<SetupSquareImgProps>`
  height: 100px;
  display: flex;
  text-align: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  color: #000;

  & .setupSquareOffice {
    margin-left: 30px;
  }

  & .setupSquareImg {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    text-align: center;
    align-items: center;
    margin-left: ${(props) => (props.ml10 ? "10px" : "0")};
  }
`;

const SetupSquareImgWrap = styled.div`
  margin-left: 10px;

  & .setupSquareImg {
    margin-bottom: 10px;
  }
`;

const SetupSquareButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  scale: 0.9;

  p {
    margin: 0 0 8px 0;
    font-size: 0.8rem;
  }
`;

const SetupSquareImg = styled.div<SetupSquareImgProps>`
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  text-align: center;
  align-items: center;
  margin-left: ${(props) => (props.ml10 ? "10px" : "0")};
  object-fit: cover;
`;

const SquareName = styled.div`
  font-size: 0.7rem;
`;

const StyledImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
`;

const NoPhoto = styled.img`
  /* filter: invert(16%) sepia(48%) saturate(2895%) hue-rotate(208deg)
    brightness(104%) contrast(107%); */
  scale: 0.8;
  width: 50px;
  height: 50px;
`;

const SetupSquare: React.FC<SetupSquareProps> = ({
  userData,
  setupSquareUse,
  onUpdate,
}) => {
  const history = useHistory();
  console.log("userData", userData);
  const AccountHandler = () => {
    history.push({ pathname: "/MyAccount" });
  };

  console.log("Image URL:", `${M_IMG}${userData.profile_img}`);
  return (
    <SetupSquareContainer>
      {setupSquareUse.includes("styleOne") && (
        <SetupSquareWrap onClick={AccountHandler}>
          <SetupSquareImg ml10>
            {userData.profile_img ? (
              <StyledImage
                src={`${M_IMG}profile/${userData.profile_img}`}
                alt="profile"
              />
            ) : (
              <NoPhoto src={circleMan} alt="profile" />
            )}
          </SetupSquareImg>
          <TitleWrap>{userData.center_nm}</TitleWrap>
        </SetupSquareWrap>
      )}
      {setupSquareUse.includes("styleTwo") && (
        <SetupSquareWrap>
          <SetupSquareImgWrap>
            <SetupSquareImg>
              {userData.profile_img ? (
                <StyledImage
                  src={`${M_IMG}profile/${userData.profile_img}`}
                  alt="profile"
                />
              ) : (
                <NoPhoto src={circleMan} alt="profile" />
              )}
            </SetupSquareImg>
            <SquareName>{userData.name}</SquareName>
          </SetupSquareImgWrap>
          <SetupSquareButtons>
            <p>프로그램 설정</p>
            <ToggleButton
              id={userData.u_id}
              type="mod_yn"
              initialAccountYn={userData.account_yn}
              initialModYn={userData.mod_yn}
              onUpdate={(newValue) =>
                onUpdate?.({ ...userData, mod_yn: newValue })
              }
            />
          </SetupSquareButtons>
          <SetupSquareButtons>
            <p>계정 활성화</p>
            <ToggleButton
              id={userData.u_id}
              type="account_yn"
              initialAccountYn={userData.account_yn}
              initialModYn={userData.mod_yn}
              onUpdate={(newValue) =>
                onUpdate?.({ ...userData, account_yn: newValue })
              }
            />
          </SetupSquareButtons>
          <SetupSquareButtons>
            <p>강사 승인</p>
            <ApprovalButton
              id={userData.u_id}
              state={userData.state}
              onUpdate={(newValue) =>
                onUpdate?.({ ...userData, state: newValue })
              }
            />
          </SetupSquareButtons>
        </SetupSquareWrap>
      )}
    </SetupSquareContainer>
  );
};

export default SetupSquare;
