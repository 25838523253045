import React from "react";
import AccountButton from "../mobile-pages/Compornent/AccountButton";
import userData from "./Interface/userData";

function MyAccount() {

    return (
        <div className="myAccountContainer">
            <div className="myAccountHeader">
                <p>MY ACCOUNT</p>
            </div>
            <div className="userInfoContainer accountB">
                <div className="userInfoWrap userInfoFirst">
                    <p className="userInfoHeader">상호명</p>
                    <p className="userInfoData name">{userData.office}</p>
                    <p className="userInfoData">{userData.session}</p>
                </div>
                <div className="userInfoWrap">
                    <p className="userInfoHeader">대표자명</p>
                    <p className="userInfoData">{userData.name}</p>
                </div>
                <div className="userInfoWrap">
                    <p className="userInfoHeader">운영기간</p>
                    <p className="userInfoData">{userData.term}</p>
                </div>
                <div className="userInfoWrap">
                    <p className="userInfoHeader">이메일</p>
                    <p className="userInfoData">{userData.email}</p>
                </div>
                <div className="userInfoWrap">
                    <p className="userInfoHeader">전화번호</p>
                    <p className="userInfoData">{userData.phone}</p>
                </div>
                <div className="userInfoWrap">
                    <p className="userInfoHeader">등급</p>
                    <p className="userInfoData">{userData.rating}</p>
                </div>
                <div className="userInfoWrap">
                    <p className="userInfoHeader">이용요금</p>
                    <p className="userInfoData">{userData.pay}</p>
                </div>
                <div className="userInfoWrap">
                    <p className="userInfoHeader">비밀번호</p>
                    <p className="userInfoData">{userData.password}</p>
                </div>
                <div className="userInfoWrap">
                    <p className="userInfoHeader">인증코드</p>
                    <p className="userInfoData">{userData.certification}</p>
                </div>
                <div className="userInfoWrap">
                    <p className="userInfoHeader">셋업 인증코드</p>
                    <p className="userInfoData">{userData.setupCertification}</p>
                </div>
            </div>
            <div className="buttonContainer">
                <AccountButton buttonUse={["logo", "play", "profile", "setup"]}/>
            </div>
        </div>
    );
}

export default MyAccount;
