import React, {useEffect} from "react";
import {motion, useAnimationControls} from "framer-motion";
import Ripples from 'react-ripples';
import ic_exclamation_mark_wh from '../../img/ic_exclamation_mark_wh.png';
import ic_check_wh from '../../img/ic_check_wh.png';
import '../../css/Mobile.css';
import styled from "styled-components";
import {LoginButtonProps, MbProps} from "../Interface/Interface"

const MobileButton = styled.div<MbProps>`
    width: ${({btnWidth}) => btnWidth};
    height: ${({btnHeight}) => btnHeight};
    border-radius: 25px;
    border: ${({dnStatus}) =>
            dnStatus === 'ready'
                    ? '2px solid #09348a'
                    : dnStatus === 'error'
                            ? '2px solid rgba(235, 44, 30, 1)'
                            : dnStatus === 'success'
                                    ? '2px solid rgba(13, 212, 33, 1)'
                                    : 'none'};
    background-color: ${({dnStatus}) =>
            dnStatus === 'ready'
                    ? 'rgba(0, 0, 0, 0)'
                    : dnStatus === 'error'
                            ? 'rgba(235, 44, 30, 1)'
                            : dnStatus === 'success'
                                    ? 'rgba(13, 212, 33, 1)'
                                    : 'initial'};
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
`;

const LoginButton: React.FC<LoginButtonProps> = ({
                                                     myClick,
                                                     imgBtn = null,
                                                     dnStatus,
                                                     setDnStatus,
                                                     txtBtn = '',
                                                     btnWidth = '50px',
                                                     btnHeight = '50px'
                                                 }) => {
    const controls = useAnimationControls();

    const animationConfig = {
        ready: {
            width: btnWidth,
            height: btnHeight,
            borderRadius: "50%",
            borderWidth: '3px',
            backgroundColor: 'rgba(0, 0, 0,0)'
        },
        loading: {
            width: btnWidth,
            borderRadius: "50%",
            border:'none'
        },
        error: {
            width: btnWidth,
            borderRadius: "50%",
            borderWidth: '0',
            borderColor: 'transparent',
            backgroundColor: 'rgba(235, 44, 30,1)'
        },
        success: {
            width: btnWidth,
            borderRadius: "50%",
            borderWidth: '0',
            borderColor: 'transparent',
            backgroundColor: 'rgba(13, 212, 33,1)'
        }
    };

    useEffect(() => {
        animateButton();
        if (dnStatus === 'error') {
            shakeButton();
        }
    }, [dnStatus, btnWidth, controls]);

    const animateButton = () => {
        const config = animationConfig[dnStatus];
        if (config) {
            controls.start(config);
        }
    };

    const shakeButton = () => {
        controls.start({x: [5, -5, 5, -5, 3, -3, 3, -3, 2, -2, 0]});
        setTimeout(() => {
            setDnStatus('ready');
        }, 900);
    };

    return (
        <div className="button-container">
            <div className="button-wrapper">
                <Ripples onClick={() => myClick()}>
                    <div className="button">
                        <motion.div
                            style={{width: btnWidth, height: btnHeight}}
                            initial={{opacity: 1, scale: 1}}
                            animate={controls}
                            transition={{
                                repeat: 0,
                                ease: "easeInOut",
                                duration: 0.4
                            }}/>
                    </div>

                    <MobileButton dnStatus={dnStatus} btnWidth={btnWidth} btnHeight={btnHeight}>
                        {dnStatus === 'ready' ? (
                            <>
                                {imgBtn ? (
                                    <img style={{width: '24px', height: '24px'}} src={imgBtn} alt="next button"
                                         className="readyImg"/>
                                ) : txtBtn && (
                                    <span className="loginBtnTxt" style={{
                                        color: '#09348a',
                                        fontFamily: 'Pretendard',
                                        fontWeight: '600',
                                        marginTop: '1px'
                                    }}>{txtBtn}</span>
                                )}
                            </>
                        ) : dnStatus === 'loading' ? (
                            <div className="loader"></div>
                        ) : dnStatus === 'error' ? (
                            <img style={{width: '24px', height: '24px'}} src={ic_exclamation_mark_wh}
                                 alt="next button"/>
                        ) : dnStatus === 'success' && (
                            <img style={{width: '24px', height: '24px'}} src={ic_check_wh} alt="next button"/>
                        )}
                    </MobileButton>
                </Ripples>
            </div>
        </div>
    );
};

export default LoginButton;
