import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import styled, { css } from "styled-components";
import { FiLoader, FiCheck, FiArrowRight } from "react-icons/fi";

interface NewButtonsProps {
  text: string;
  dnStatus: "ready" | "loading" | "error" | "success";
  setDnStatus: any;
  myClick: any;
}

const ButtonContainer = styled(motion.button)<{
  dnStatus?: "ready" | "loading" | "error" | "success";
}>`
  width: 70px;
  height: 30px;
  border-radius: 25px;
  border: none;
  background: #09348a;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ButtonText = styled.p`
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
`;

const ExclamationMark = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: #fff;
`;

const rotateAnimation = {
  rotate: [0, 360],
  transition: { loop: Infinity, ease: "linear", duration: 1 },
};

const NewButtons: React.FC<NewButtonsProps> = ({
  text,
  dnStatus,
  myClick,
  setDnStatus,
}) => {
  const controls = useAnimation();
  const animationConfig = {
    ready: {
      width: "70px",
      height: "30px",
      borderRadius: "25px",
      borderWidth: "3px",
      backgroundColor: "#09348a",
    },
    loading: {
      width: "34px",
      height: "34px",
      borderRadius: "50%",
      border: "none",
    },
    error: {
      width: "34px",
      height: "34px",
      borderRadius: "50%",
      borderWidth: "0",
      borderColor: "transparent",
    },
    success: {
      width: "34px",
      height: "34px",
      borderRadius: "50%",
      borderWidth: "0",
      borderColor: "transparent",
    },
  };

  const iconVariants = {
    ready: <ButtonText>{text}</ButtonText>,
    loading: (
      <motion.div
        animate={rotateAnimation}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FiLoader color="#fff" />
      </motion.div>
    ),
    error: <ExclamationMark>!</ExclamationMark>,
    success: <FiCheck color="#fff" />,
  };

  useEffect(() => {
    animateButton();
    if (dnStatus === "error") {
      shakeButton();
    }
  }, [dnStatus, controls]);

  const animateButton = () => {
    const config = animationConfig[dnStatus];
    if (config) {
      controls.start(config);
    }
  };

  const shakeButton = () => {
    controls.start({ x: [5, -5, 5, -5, 3, -3, 3, -3, 2, -2, 0] });
    setTimeout(() => {
      setDnStatus("ready");
    }, 900);
  };

  return (
    <ButtonContainer animate={controls} onClick={myClick} type="submit">
      {iconVariants[dnStatus]}
    </ButtonContainer>
  );
};
export default NewButtons;
