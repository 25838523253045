import GlobalStyles from "./constants/GlobalStyles";
import './css/Basic.css';
import './css/Component.css';
import RouterM from "./mobile-pages/RouterM";
import { STORAGE_S } from './constants/Constants';

// ##### 리덕스 관련 ######
import rootReducer from "./component/redux/rootReducer";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from 'react-redux';
import React from "react";

const store = configureStore({
    reducer: rootReducer,
    devTools: false
});

function App() {
    const isMobile = window.innerWidth < 768;

    return (
        <Provider store={store}>
            <GlobalStyles/>
            {/*{isMobile ? <RouterM /> : <Router />}*/}
            <RouterM/>
        </Provider>
    );
}

export default App;
