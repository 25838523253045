import Logo from "../mobile-pages/Compornent/Logo";
import React from "react";
import checkMark from "../img/check_black.png"
import {Link, useHistory} from "react-router-dom";
import { RootState } from "../component/redux/rootReducer";
import * as ServerApi from "../constants/ServerApiM";
import {useSelector} from "react-redux";

interface PlanBodyTextProps {
    text: string;
}

const PlanBodyText: React.FC<PlanBodyTextProps> = (props) => {
    return (
        <div className="planBodyText">
            <img src={checkMark} alt="체크아이콘"/>
            <span>{props.text}</span>
        </div>
    );
}

function RatePlan() {
    const history = useHistory();
    const signUpData = useSelector((state: RootState) => state.signUpData);

    const handlePayment = async (planType: 'monthly' | 'subscription') => {
        if (!signUpData) {
            alert("회원가입 데이터가 없습니다.");
            return;
        }
        // 서버에 회원가입 및 결제 요청
        try {
            const response = await ServerApi.m_app_join(signUpData);
            if (response.rsp_code === '100') {
                history.push('/SignUpCompleteB');
            } else {
                alert("결제 처리에 실패했습니다.");
            }
        } catch (error) {
            console.error("결제 처리 중 오류 발생:", error);
            alert("결제 처리 중 문제가 발생했습니다.");
        }
    };

    return (
        <div className="ratePlanContainer">
            <div className="headerContainer">
                <Logo onChangeMode={() => {
                }} logoType={1}/>
            </div>
            <div className="ratePlanWrap">
                <div className="planHeader">
                    <p>PERSONAL</p>
                    <h2>99,000원</h2>
                    <p>사용자당 매월, 정기구독시 89,000원(1년 약정) 정기구독 해지 시, 월 이용금액 적용</p>
                    <p># 개인 이용자 또는 엔컴파스 2대 이하 사업장에서 이용가능한 요금제. 운동시간, 휴식시간, 운동프로그램 가져오기 등 모든 기능</p>
                </div>
                <div className="planBodyContainer">
                    <PlanBodyText text="Business starter + Plus 기능 포함"/>
                    <PlanBodyText text="운동시간, 휴식시간 설정"/>
                    <PlanBodyText text="운동프로그램 생성"/>
                    <PlanBodyText text="시퀀스 생성"/>
                    <PlanBodyText text="운동 동작별 동작가이드 기록"/>
                    <PlanBodyText text="타 지점 운동프로그램 사용"/>
                    <PlanBodyText text="8개 화면 분할"/>
                    <PlanBodyText text="폴더생성"/>
                    <PlanBodyText text="사용자 권한 설정"/>
                </div>
                <div className="planButtonContainer">
                    <button className="planButton" onClick={() => handlePayment('monthly')}>월간결제</button>
                    <button className="planButton noMargin" onClick={() => handlePayment('subscription')}>정기구독</button>
                {/*  어떤 세부 기능이 들어갈지 몰라서 버튼으로 처리  */}
                </div>
            </div>
        </div>
    )
}

export default RatePlan;
