import styled from "styled-components";
import React, { useState, useRef, useEffect } from "react";
import {
  faPlus,
  faCheck,
  faPause,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as MyUtil from "../../constants/MyUtil";
import * as Interface from "../Interface/Interface";
import { useSelector } from "react-redux";
import { RootState } from "../../component/redux/rootReducer";
import * as ServerApi from "../../constants/ServerApiM";
import { ReactSortable } from "react-sortablejs";

interface MusicContentsProps {
  musicList?: Interface.MusicItem[];
  setMusicList?: any;
  selectAll?: boolean;
  handleAllClick?: () => void;
  onSelectedConfigNo?: (selectedConfigNo: string[]) => void;
  onMusicSelectionChange?: any;
  editMode?: boolean;
  fetchMusicList?: () => Promise<void>;
  pro_no?: string;
  play_fold_no?: string;
  selectedMusic?: any;
}

const MusicContent = styled.div`
  width: 100%;
  height: 100%;
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const MusicTitleWrap = styled.div`
  font-weight: 500;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: auto auto auto 0;
  width: 50%;

  > .musicTitle {
    color: #000;
    overflow-wrap: break-word;
  }
`;

const MusicSelect = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  input[type="checkbox"] + label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    user-select: none;
  }

  input[type="checkbox"] + label::before {
    content: "";
    position: absolute;
    left: 14px;
    bottom: 0;
    transform: translate(-50%, 50%);
    width: 12px;
    height: 12px;
    border: 1px solid #ddd;
    border-radius: 3px;
    background: white;
  }

  input[type="checkbox"]:checked + label::before {
    background: #09348a;
    border-color: #09348a;
  }

  input[type="checkbox"]:checked + label::after {
    position: absolute;
    left: 3px;
    top: 0px;
    color: #000;
    font-size: 16px;
  }
`;

const MusicIndex = styled.div`
  margin: auto 15px;
`;

const SelectionIcon = styled.div`
  margin: auto 15px;
  cursor: pointer;
`;

const MusicPlayTime = styled.div`
  margin: auto 15px;
`;

const PlayButton = styled.div`
  cursor: pointer;
  margin: auto 15px;
`;

const MusicContents: React.FC<MusicContentsProps> = ({
  musicList,
  setMusicList,
  onMusicSelectionChange,
  selectedMusic,
  editMode,
  fetchMusicList,
  pro_no,
}: any) => {
  const MLoginInfo = useSelector((state: RootState) => state.MLoginInfo);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [currentPlaying, setCurrentPlaying] = useState<number | null>(null);
  const [audioDurations, setAudioDurations] = useState<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    musicList.forEach((music: any) => {
      const audio = new Audio(music.file_nm);
      audio.onloadedmetadata = () => {
        setAudioDurations((prevDurations) => ({
          ...prevDurations,
          [music.file_nm]: audio.duration
            ? MyUtil._secondToTimeSmallFormat(Math.floor(audio.duration))
            : "",
        }));
      };
    });
  }, [musicList]);

  const togglePlay = (file_nm: string, index: number) => {
    if (currentPlaying === index) {
      audioRef.current?.pause();
      setCurrentPlaying(null);
    } else {
      setCurrentPlaying(index);
      if (audioRef.current) {
        audioRef.current.src = file_nm;
        audioRef.current
          .play()
          .catch((error) => console.log("Playback failed", error));
      }
    }
  };

  const toggleSelection = async (index: number) => {
    const item = musicList[index];
    const isSelected = item.music_reg_yn === "y";
    try {
      let response;

      if (isSelected) {
        const listResponse = await ServerApi.m_app_pro_my_play_list({ pro_no });
        if (listResponse && listResponse.rsp_code === "100") {
          console.log("m_app_pro_my_play_list listResponse", listResponse);

          const currentItem = listResponse.array.find(
            (x: any) => x.config_no === item.config_no
          );
          console.log("currentItem", currentItem);

          if (currentItem) {
            const deselectData = {
              pro_no: pro_no,
              array: [
                {
                  pro_music_no: currentItem.pro_music_no,
                },
              ],
            };
            console.log("deselectData", deselectData);
            const response = await ServerApi.m_app_pro_play_d(deselectData);
            if (response && response.rsp_code === "100") {
              console.log("선택 상태 업데이트 성공:", response);
              // 로컬 상태 업데이트
              const updatedList = [...musicList];
              updatedList[index] = { ...item, music_reg_yn: "n" };
              setMusicList(updatedList);
            } else {
              console.error("선택 상태 업데이트 실패:", response);
            }
          } else {
            console.error("해당 config_no를 가진 항목을 찾을 수 없습니다.");
          }
        } else {
          console.error("음악 리스트 조회 실패:", listResponse);
        }
      } else {
        const selectData = {
          pro_no: pro_no,
          array: [
            {
              config_no: item.config_no,
            },
          ],
        };
        response = await ServerApi.m_app_pro_play_i(selectData);
        console.log("m_app_pro_play_i response", response);
        if (response && response.rsp_code === "100") {
          console.log("선택 상태 업데이트 성공:", response);
          // 로컬 상태 업데이트
          const updatedList = [...musicList];
          updatedList[index] = { ...item, music_reg_yn: "y" };
          setMusicList(updatedList);
        } else {
          console.error("선택 상태 업데이트 실패:", response);
        }
      }
    } catch (error) {
      console.error("선택 상태 업데이트 중 오류 발생:", error);
    }
  };

  // 서버에 새 순서 업데이트
  const updateMusicOrderOnServer = async (newList: any) => {
    const mappedList = newList.map((item: any) => ({
      config_no: item.config_no,
      seq: item.seq,
    }));
    try {
      const response = await ServerApi.m_app_play_assign({
        array: mappedList,
        center_no: MLoginInfo.MLoginInfo.center_no,
        play_folder_no: "",
      });
      console.log("m_app_play_assign response", response);
      if (response && response.rsp_code === "100") {
        console.log("순서 업데이트 성공:", response);
        fetchMusicList();
      } else {
        console.error("순서 업데이트 실패:", response);
      }
    } catch (error) {
      console.error("순서 업데이트 중 오류 발생:", error);
    }
  };

  return (
    <ReactSortable
      list={musicList}
      setList={setMusicList}
      animation={200}
      disabled={!editMode}
      handle=".drag-handle"
      onSort={async (evt) => {
        // 목록 순서 변경 후 호출될 콜백
        const updatedList = [...musicList];
        setMusicList(updatedList); // 상태 업데이트

        await updateMusicOrderOnServer(updatedList);
      }}
    >
      {musicList.map((music: any, index: number) => (
        <MusicContent key={music.config_no}>
          {editMode && (
            <MusicSelect>
              <input
                type="checkbox"
                id={`musicSelection-${music.config_no}`}
                name={`musicSelection-${music.config_no}`}
                checked={selectedMusic.has(music.config_no)}
                onChange={(e) =>
                  onMusicSelectionChange(music.config_no, e.target.checked)
                }
              />
              <label htmlFor={`musicSelection-${music.config_no}`}></label>
            </MusicSelect>
          )}
          <MusicIndex>{index + 1}</MusicIndex>
          <MusicTitleWrap className="drag-handle">
            <div className="musicTitle">{music.dis_nm}</div>
          </MusicTitleWrap>
          <MusicPlayTime>{audioDurations[music.file_nm] || ""}</MusicPlayTime>
          <PlayButton onClick={() => togglePlay(music.file_nm, index)}>
            <FontAwesomeIcon
              icon={currentPlaying === index ? faPause : faPlay}
            />
          </PlayButton>
          <SelectionIcon onClick={() => toggleSelection(index)}>
            <FontAwesomeIcon
              icon={music.music_reg_yn === "y" ? faCheck : faPlus}
            />
          </SelectionIcon>
        </MusicContent>
      ))}
      <audio ref={audioRef} controls style={{ display: "none" }} />
    </ReactSortable>
  );
};

export default MusicContents;
