import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import BannerItem2 from "../../component/BannerItem";
import icTrash from "../../img/ic_trash.png";
import NewSaveButton from "./NewSaveButton";

interface InfoModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  managerPhone: string;
  setManagerPhone: (value: string) => void;
  videoUrl: string;
  imgFiles: string[];
  videoFile: any;
  setImgFile1: (file: any) => void;
  setImgFile2: (file: any) => void;
  setImgFile3: (file: any) => void;
  setImgFile4: (file: any) => void;
  setVideoFile: (file: any) => void;
  setDelImgUrl1: (file: any) => void;
  setDelImgUrl2: (file: any) => void;
  setDelImgUrl3: (file: any) => void;
  setDelImgUrl4: (file: any) => void;
  setADDVideoFile: (file: any) => void;
  m_mng_pro_info_i: () => void;
  dnStatus: any;
  setDnStatus: (status: any) => void;
}

const InfoModal: React.FC<InfoModalProps> = ({
  isOpen,
  onRequestClose,
  managerPhone,
  setManagerPhone,
  videoUrl,
  imgFiles,
  videoFile,
  setImgFile1,
  setImgFile2,
  setImgFile3,
  setImgFile4,
  setVideoFile,
  setDelImgUrl1,
  setDelImgUrl2,
  setDelImgUrl3,
  setDelImgUrl4,
  setADDVideoFile,
  m_mng_pro_info_i,
  dnStatus,
  setDnStatus,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Information Modal"
      className="informationBox"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          zIndex: "999",
        },
      }}
    >
      <div
        className="modalIpWrap"
        style={{ borderBottomWidth: "0px", marginTop: "-50px" }}
      >
        <span className="modalIpTitleAdmin">Information</span>
      </div>
      <div className="modalIpWrap" style={{ marginTop: "-10px" }}>
        <ModalTextBox
          value={managerPhone}
          placeholder="Please enter the comment"
          onChange={(evt) => setManagerPhone(evt.target.value)}
          style={{ width: "100%", padding: "10px" }}
        />
      </div>

      <div className="modalIpWrap" style={{ borderBottomWidth: "0px" }}>
        <span className="modalIpTitleAdmin">Video</span>
      </div>
      <div className="modalIpWrap" style={{ marginTop: "-5px" }}>
        <input
          style={{ display: "none" }}
          type="file"
          id="ex_file"
          accept={"video/mp4,video/mkv, video/x-m4v,video/*"}
          placeholder={"비디오 선택"}
          onChange={(event: any) => {
            setVideoFile(event.target.files[0]);
            setADDVideoFile(event.target.files[0]);
          }}
          multiple={false}
        />
        <label htmlFor="ex_file" className="fileBtn">
          FILE +
        </label>
        <span
          className="fileBtnSub"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {videoFile?.name
            ? videoFile?.name
            : videoUrl
            ? videoUrl
            : "Please select a file"}
        </span>

        <img
          style={{
            width: "22px",
            height: "22px",
            marginLeft: "6px",
            cursor: "pointer",
            marginRight: "8px",
          }}
          src={icTrash}
          alt="logo image"
          onClick={() => {
            setVideoFile(null);
            setADDVideoFile(null);
          }}
        />
      </div>

      <div
        className="modalIpWrap"
        style={{ borderBottomWidth: "0px", marginBottom: "-10px" }}
      >
        <span className="modalIpTitleAdmin">Image</span>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <BannerItem2
          item={{ url: "", uid: "", anchor: "" }}
          width={"50px"}
          height={"50px"}
          setImgFile={setImgFile1}
          defaultImg={imgFiles[0]}
          setDelImgUrl={setDelImgUrl1}
        />
        <BannerItem2
          item={{ url: "", uid: "", anchor: "" }}
          width={"50px"}
          height={"50px"}
          setImgFile={setImgFile2}
          defaultImg={imgFiles[1]}
          setDelImgUrl={setDelImgUrl2}
        />
        <BannerItem2
          item={{ url: "", uid: "", anchor: "" }}
          width={"50px"}
          height={"50px"}
          setImgFile={setImgFile3}
          defaultImg={imgFiles[2]}
          setDelImgUrl={setDelImgUrl3}
        />
        <BannerItem2
          item={{ url: "", uid: "", anchor: "" }}
          width={"50px"}
          height={"50px"}
          setImgFile={setImgFile4}
          defaultImg={imgFiles[3]}
          setDelImgUrl={setDelImgUrl4}
        />
      </div>
      <ButtonWrap>
        <NewSaveButton
          text={"SAVE"}
          dnStatus={dnStatus}
          setDnStatus={setDnStatus}
          myClick={() => {
            m_mng_pro_info_i();
          }}
        />
      </ButtonWrap>
    </Modal>
  );
};

export default InfoModal;

const ButtonWrap = styled.div`
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 50%);
`;

const SaveButton = styled.button`
  width: 100px;
  height: 30px;
`;

const ModalTextBox = styled.textarea`
  border: none;
  width: 100%;
  height: 80px;
  font-size: 0.8rem;
  color: #000000;
  padding: 10px 0 0 10px;
  resize: none;
  font-family: "NanumSquare", sans-serif;

  &::placeholder {
    font-family: "Suit", serif;
  }

  :focus {
    outline: none;
  }
`;
