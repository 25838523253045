import React, { useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Modal, Collapse, Select } from "antd";
import * as ServerApi from "../../constants/ServerApiM";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faSearch,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { ItemType } from "../Interface/Interface";
import NewSaveButton from "./NewSaveButton";
import ReactPlayer from "react-player";

const { Option } = Select;

interface CategoryType {
  mv_category_nm: string;
  mv_category_no: string;
  mv_ct: string;
}

interface ModalVideoSelectProps {
  isModalOpen: boolean;
  videoList: ItemType[];
  ModalCb: (isOk: boolean, selectedVideos: ItemType[]) => void;
}

type CheckedOverlayProps = {
  isSelected: boolean;
};

const GlobalStyle = createGlobalStyle`
  .ios-device {
    /* iOS 기기에 대한 전역 스타일 */
    .ant-select-item-option-content {
      font-size: 16px !important;
    }

    input, select, textarea {
      font-size: 16px !important;
    }

  }
`;

const ModalWrapper = styled.div`
  z-index: 99;
`;

const StyledModal = styled(Modal)`
  height: 80vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 15px;
  z-index: 10000;

  .ant-modal-content {
    width: 100%;
    background-color: #ffffff !important;
    overflow-y: auto;
  }

  .ant-modal-body {
    height: 80vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .ant-modal-close {
    width: 16px;
    height: 16px;
    font-size: 0.6rem;
    font-weight: 800;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    transform: translate(-50%, 50%);
    z-index: 1001;
    color: #fff;
    background-color: #09348a;
    border-radius: 50%;
  }

  .ant-select-arrow {
    display: none;
  }
`;

const ModalTitle = styled.div`
  font-size: 0.9rem;
  font-weight: 700;
  color: #000;
  text-align: start;
  margin-bottom: 20px;
`;

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: 2px solid #000;
  border-radius: 4px;
  padding: 4px;
  background-color: #ffffff;
  margin: 0 auto;
`;

const SearchInput = styled.input`
  border: none;
  padding: 5px 12px;
  flex-grow: 1;
  outline: none;
  color: #000;
  font-size: 14px;
  font-weight: 300;
  width: 100%;

  &::placeholder {
    color: #ccc;
    font-size: 14px;
  }

  /* iOS 기기 감지 및 폰트 크기 조정 */
  @supports (-webkit-touch-callout: none) {
    font-size: 16px;

    &::placeholder {
      font-size: 16px;
    }
  }
`;

const SearchIcon = styled(FontAwesomeIcon)`
  color: #000;
  margin-right: 10px;
`;

const SelectContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: 2px solid #000;
  border-radius: 4px;
  padding: 5px;
  background-color: #ffffff;
  margin: 5px auto;
`;

const StyledButton = styled.button`
  padding: 5px 30px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 30px;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: 20px 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 1000;
`;

const StyledCollapse = styled(Collapse)`
  overflow-y: auto;
  margin: 5px 0;
`;

const SmallButton = styled(StyledButton)`
  padding: 4px 10px;
  font-size: 14px;
  line-height: 1.1rem;
  font-weight: 400;
  background: none;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
`;

const CheckedOverlay = styled.div<CheckedOverlayProps>`
  position: absolute;
  top: 0;
  right: 0;
  border: 2px solid #09348a;
  width: 100%;
  height: 100%;
  display: ${({ isSelected }) => (isSelected ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  pointer-events: none;
  overflow: hidden;

  .fa-icon {
    position: absolute;
    top: 15%;
    left: 85%;
    transform: translate(-50%, -50%);
    color: #09348a;
  }
`;

const VideoItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  border: 1px solid #eaeaea;
  width: calc((100% - 10px) / 3);
  margin: 5px 0;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    background-color: #f0f0f0;
  }

  img,
  video {
    width: 100px;
    height: auto;
  }

  span {
    text-align: center;
    font-size: 0.9rem;
  }
`;

const VideosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 5px;
  padding: 0 5px;
  overflow: hidden;
  margin-bottom: 60px;
`;

const TitleWrap = styled.div`
  position: sticky;
  top: 0;
  /* transform: translateX(-50%); */
  width: 100%;
  padding: 20px 0;
  z-index: 10;
  background-color: #ffffff;
  border-radius: 15px 15px 0 0;
`;

const VideoName = styled.div`
  width: 100%;
  height: 100%;
  align-content: center;
  backdrop-filter: blur(10px);
  padding: 7px 0;
  font-size: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;

const VideosWrap = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;

  /* 스크롤바 숨기기 */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`;

const ModalVideoSelectM2: React.FC<ModalVideoSelectProps> = ({
  isModalOpen,
  videoList,
  ModalCb,
}) => {
  const [dnStatus, setDnStatus] = useState<
    "ready" | "loading" | "error" | "success"
  >("ready");
  const [selectedVideos, setSelectedVideos] = useState<ItemType[]>([]);
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [currentVideos, setCurrentVideos] = useState<ItemType[]>([]);
  const [selectedCategoryNo, setSelectedCategoryNo] = useState<string | null>(
    null
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedCategoryName, setSelectedCategoryName] = useState("EXERCISE");
  // 체크 상태 추적을 위한 state
  const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>(
    {}
  );
  // 검색 결과 상태
  const [searchResults, setSearchResults] = useState<ItemType[]>([]);
  // 검색 텍스트 상태
  const [searchText, setSearchText] = useState("");
  // 현재 재생 중인 비디오 상태
  const [playingVideo, setPlayingVideo] = useState<string | null>(null);
  // 현재 표시되는 비디오 목록을 관리하는 상태
  const [displayedVideos, setDisplayedVideos] = useState<ItemType[]>([]);

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  if (isIOS) {
    document.documentElement.classList.add("ios-device");
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchText = e.target.value;
    setSearchText(newSearchText);
  };

  // 검색어에 따라 비디오 검색 결과 업데이트
  useEffect(() => {
    const fetchSearchResults = async () => {
      if (searchText.trim()) {  // 검색어가 있을 때만 검색 실행
        try {
          // 현재 선택된 카테고리의 비디오들 중에서 검색
          const filteredVideos = currentVideos.filter(video => 
            video.mv_nm.toLowerCase().includes(searchText.toLowerCase())
          );
          
          setSearchResults(filteredVideos);
          setDisplayedVideos(filteredVideos);
          
        } catch (error) {
          console.error("Error searching videos:", error);
          setSearchResults([]);
          setDisplayedVideos([]);
        }
      } else {
        // 검색어가 없으면 현재 카테고리의 모든 비디오 표시
        setSearchResults([]);
        setDisplayedVideos(currentVideos);
      }
    };

    fetchSearchResults();
  }, [searchText, currentVideos]);

  const handleSelectVideo = (video: any) => {
    const isCurrentlySelected = checkedItems[video.mv_no];
    setCheckedItems((prev) => ({
      ...prev,
      [video.mv_no]: !isCurrentlySelected,
    }));

    if (isCurrentlySelected) {
      setSelectedVideos(selectedVideos.filter((v) => v.mv_no !== video.mv_no));
    } else {
      setSelectedVideos([...selectedVideos, video]);
    }
  };

  const handleSelectSearchResult = (selectedVideoId: string) => {
    // 선택된 비디오 ID를 사용하여 전체 비디오 목록에서 해당 비디오 찾기
    const selectedVideo = videoList.find(
      (video) => video.mv_no === selectedVideoId
    );

    if (selectedVideo) {
      const isAlreadySelected = selectedVideos.some(
        (v) => v.mv_no === selectedVideoId
      );
      if (!isAlreadySelected) {
        setSelectedVideos([...selectedVideos, selectedVideo]);
      }
    }
  };

  useEffect(() => {
    console.log("Updated categories:", categories);
  }, [categories]);

  const renderVideoItems = (videos: ItemType[]) => {
    return videos.map((video) => {
      const isPlaying = playingVideo === video.mv_no;
      const videoThumbnailUrl = video.mv_rep_img || "/images/logo.png"; // 썸네일 URL 사용 또는 기본 이미지

      return (
        <VideoItem key={video.mv_no} onClick={() => handleVideoClick(video)}>
          {isPlaying ? (
            <ReactPlayer
              key={video.mv_no}
              width={100}
              height={100}
              url={video.mv_url}
              playing={true}
              controls={false}
              muted={true}
              volume={0}
              progressInterval={1000}
              pip={false}
              loop={true}
            />
          ) : (
            <img src={videoThumbnailUrl} alt={video.mv_nm} />
          )}
          <VideoName onClick={(e) => handleVideoItemClick(e, video)}>
            {video.mv_nm}
          </VideoName>
          <CheckedOverlay isSelected={checkedItems[video.mv_no] || false}>
            <FontAwesomeIcon icon={faCheck} className="fa-icon" />
          </CheckedOverlay>
        </VideoItem>
      );
    });
  };

  const handleVideoItemClick = (event: any, video: any) => {
    event.stopPropagation(); // 이벤트 버블링 중지
    handleSelectVideo(video);
  };

  const handleVideoClick = (video: any) => {
    const isPlaying = playingVideo === video.mv_no;
    if (isPlaying) {
      // 현재 재생 중인 비디오를 다시 클릭하면 일시 정지
      setPlayingVideo(null);
    } else {
      // 다른 비디오 클릭 시 해당 비디오 재생
      setPlayingVideo(video.mv_no);
    }
  };

  useEffect(() => {
    let isMounted = true; // 마운트 상태를 추적하는 플래그

    const fetchCategories = async () => {
      try {
        const response = await ServerApi.m_app_mv_category({});
        if (response && Array.isArray(response.array) && isMounted) {
          // 컴포넌트가 마운트된 상태에서만 상태 업데이트
          setCategories(response.array);
        } else if (!isMounted) {
          // 컴포넌트가 언마운트되었으면 아무것도 하지 않음
        } else {
          console.error("카테고리 데이터가 배열 형태가 아닙니다:", response);
          setCategories([]);
        }
        console.log("response.array", response.array);
      } catch (error) {
        if (isMounted) {
          console.error(
            "카테고리 데이터를 불러오는 중 오류가 발생했습니다:",
            error
          );
          setCategories([]);
        }
      }
      console.log("categories", categories);
    };

    fetchCategories();

    // 클린업 함수
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;

    const fetchVideos = async () => {
      try {
        const response = await ServerApi.m_app_mv({});
        if (response && response.array && isMounted) {
          setCurrentVideos(response.array);
          setDisplayedVideos(response.array);
        } else if (!isMounted) {
        } else {
          setCurrentVideos([]);
          setDisplayedVideos([]);
        }
      } catch (error) {
        if (isMounted) {
          console.error("Error loading videos:", error);
          setCurrentVideos([]);
          setDisplayedVideos([]);
        }
      }
    };

    fetchVideos();

    // 클린업 함수
    return () => {
      isMounted = false;
    };
  }, []);

  const handleOk = async () => {
    setDnStatus("loading");
    await new Promise((resolve) => setTimeout(resolve, 1000)); // 1초 지연
    setDnStatus("success");
    try {
      ModalCb(true, selectedVideos);
    } catch (error) {
      console.error("제출 중 오류 발생:", error);
    }
  };

  const handleCancel = () => {
    ModalCb(false, []);
  };

  const handleCategorySelect = async (value: string | string[]) => {
    if (typeof value === "string") {
      setSelectedCategoryNo(value);

      if (value) {
        try {
          console.log("value", value);
          const response = await ServerApi.m_app_mv({ mv_category_no: value });
          if (response && response.array) {
            setCurrentVideos(response.array);
            setDisplayedVideos(response.array);
          } else {
            setCurrentVideos([]);
            setDisplayedVideos([]);
          }
        } catch (error) {
          console.error("Error loading videos by category:", error);
          setCurrentVideos([]);
          setDisplayedVideos([]);
        } finally {
          setDropdownOpen(false);
        }
      } else {
        // 선택된 카테고리가 없으면 전체 비디오 목록을 다시 불러옴
        try {
          const response = await ServerApi.m_app_mv({});
          if (response && response.array) {
            setCurrentVideos(response.array);
            setDisplayedVideos(response.array);
          } else {
            setCurrentVideos([]);
            setDisplayedVideos([]);
          }
        } catch (error) {
          console.error("Error loading initial videos:", error);
          setCurrentVideos([]);
          setDisplayedVideos([]);
        }
      }
      // 카테고리 선택 시 검색어 초기화
      setSearchText("");
    }
  };

  const handleSelect = async (value: string) => {
    setDropdownOpen(false);
    await handleCategorySelect(value);
    setSelectedCategoryNo(value);
    // 선택한 카테고리 이름 업데이트
    const selectedCategory = categories.find((c) => c.mv_category_no === value);
    setSelectedCategoryName(
      selectedCategory ? selectedCategory.mv_category_nm : "EXERCISE"
    );
  };

  const openDropdown = () => {
    setDropdownOpen(true);
  };

  const handleModalClick = (event: any) => {
    event.stopPropagation(); // 이벤트 버블링 방지
  };

  useEffect(() => {
    const modalMask = document.querySelector(".ant-modal-mask") as HTMLElement;
    if (modalMask && isModalOpen) {
      modalMask.style.backgroundColor = "rgba(0, 0, 0, 0.75)";
      modalMask.style.zIndex = "99999";
    }
  }, [isModalOpen]);

  useEffect(() => {
    console.log("선택된 카테고리 번호", selectedCategoryNo);
  }, [selectedCategoryNo]);

  return (
    <ModalWrapper onClick={handleModalClick}>
      <GlobalStyle />
      <StyledModal
        centered
        open={isModalOpen}
        onCancel={handleCancel}
        width="80%"
        footer={null}
        className="styled-modal"
      >
        <TitleWrap>
          <ModalTitle>Category</ModalTitle>
          <SelectContainer>
            {dropdownOpen ? (
              <Select
                style={{ flexGrow: 1 }}
                value={selectedCategoryNo || undefined}
                dropdownStyle={{
                  width: "100%",
                  height: "270px",
                  overflowY: "auto",
                  backgroundColor: "#fff",
                  zIndex: 99999,
                }}
                onSelect={handleSelect}
                open={dropdownOpen}
                onDropdownVisibleChange={(open) => setDropdownOpen(open)}
              >
                {categories.map((category) => (
                  <Option
                    value={category.mv_category_no}
                    key={category.mv_category_no}
                    style={{
                      padding: "7px 10px",
                      textAlign: "left",
                      fontSize: "14px",
                    }}
                  >
                    {category.mv_category_nm}
                  </Option>
                ))}
              </Select>
            ) : (
              <SmallButton onClick={openDropdown}>
                {selectedCategoryName}
                <ArrowIcon icon={faChevronDown} />
              </SmallButton>
            )}
          </SelectContainer>
          <SearchContainer>
            <SearchInput
              type="text"
              placeholder="SEARCH"
              value={searchText}
              onChange={handleInputChange}
            />
            <SearchIcon icon={faSearch} />
          </SearchContainer>
        </TitleWrap>
        <VideosWrap>
          {searchResults.length > 0 && (
            <Select
              style={{ width: "100%" }}
              value={null}
              onChange={handleSelectSearchResult}
            ></Select>
          )}
          <StyledCollapse accordion onChange={handleCategorySelect} />
          <VideosContainer>{renderVideoItems(displayedVideos)}</VideosContainer>
        </VideosWrap>
        <ButtonContainer>
          <NewSaveButton
            text={"SAVE"}
            dnStatus={dnStatus}
            setDnStatus={setDnStatus}
            myClick={handleOk}
          />
        </ButtonContainer>
      </StyledModal>
    </ModalWrapper>
  );
};
export default ModalVideoSelectM2;
