import { useEffect, useCallback, useState } from "react";
import styled from "styled-components";
import * as ServerApi from "../constants/ServerApi";
import * as MyUtil from "../constants/MyUtil";
import { useSelector } from "react-redux";
import { RootState } from "../component/redux/rootReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #222;
  padding: 20px 0;
`;

const Display = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 60px;
  font-size: 24px;
  background-color: white;
  border: 2px solid #999;
  border-radius: 10px;
  margin: 50px 0;
`;

const KeypadContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const Keypad = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 200px;
  padding: 10px 0;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  font-size: 24px;
  background-color: white;
  border: 2px solid #999;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.2s;

  &:active {
    background-color: #e0e0e0;
  }
`;

const ControlButton = styled(Button)`
  margin: 30px 0;
`;

const sendMessage = (value: any, center_no: any) => {
  fetch("http://3.37.242.5:4000/send", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ center_no, value }),
  }).catch((err) => console.error("Error sending message:", err));
  console.log("sendMessage", value);
  // 0.5초 후에 "C" 메시지 전송
  setTimeout(() => {
    fetch("http://3.37.242.5:4000/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ center_no, value: "C" }),
    }).catch((err) => console.error("Error sending reset message:", err));
    console.log("sendMessage", "C");
  }, 500);
};

const NumberPad = ({ location }: any) => {
  const MLoginInfo = useSelector((state: RootState) => state.MLoginInfo);
  const pro_no = location.state.pro_no;
  const center_no = MLoginInfo.MLoginInfo.center_no;
  const [sequences, setSequences] = useState([]);
  const [lastNumber, setLastNumber] = useState("C");
  const [isPlaying, setIsPlaying] = useState(false);

  const m_center_pro_m_dt = useCallback(async () => {
    let result = await ServerApi.m_center_pro_m_dt({ pro_no: pro_no });

    console.log("m_center_pro_m_dt", result);
    const { isOk, data } = MyUtil._isResultSucces("m_center_pro_m_dt", result);
    if (isOk) {
      setSequences(data.pro_array);
    } else {
      console.error("Failed to fetch data");
    }
  }, [pro_no]);

  useEffect(() => {
    if (pro_no) {
      m_center_pro_m_dt();
      // sendMessage("RESET", center_no);  // 초기화 메시지 전송
    }
  }, [m_center_pro_m_dt, pro_no, center_no]);

  const handleButtonClick = (number: any) => {
    sendMessage(number, center_no);
    setLastNumber(number);
    setIsPlaying(false);
    console.log(sendMessage);
  };

  const handlePlayPause = () => {
    const newStatus = !isPlaying ? "PAUSE" : "PLAY";
    sendMessage(newStatus, center_no);
    setIsPlaying(!isPlaying);
  };

  return (
    <Container>
      <Display>
        {lastNumber !== null && lastNumber !== "C" ? lastNumber : ""}
      </Display>
      <KeypadContainer>
        <Keypad>
          {sequences.map((item: any, idx: any) => (
            <Button key={idx} onClick={() => handleButtonClick(item.seq)}>
              {item.seq}
            </Button>
          ))}
        </Keypad>
      </KeypadContainer>
      <ControlButton onClick={handlePlayPause}>
        <FontAwesomeIcon
          icon={isPlaying ? faPlay : faPause}
          style={{
            width: "22px",
            height: "22px",
            marginLeft: isPlaying ? "4px" : "0",
          }}
        />
      </ControlButton>
    </Container>
  );
};

export default NumberPad;
