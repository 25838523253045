import React from "react";
import NewMobileButton from "../mobile-pages/Compornent/NewMobileButton";
import styled from "styled-components";
import backGround from "../img/new1.png";

const MobileContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const BackGround = styled.div`
  height: 100%;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.9)
    ),
    url(${backGround});
  background-size: cover;
  background-position: calc(50% - 70px) center;
  z-index: 9;

  @media screen and (max-width: 400px) {
    background-position: center;
  }
`;

const TitleWrap = styled.div`
  width: 100%;
  z-index: 10;
  text-align: center;
  position: absolute;
  bottom: 24%;
  left: 50%;
  transform: translate(-50%, 50%);
  color: #fff;
  font-family: Poppins-Italic, serif;
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-weight: 800;

  @media screen and (max-width: 400px) {
    font-size: 2.6rem;
  }
`;

const TextWrap = styled.div`
  width: 100%;
  z-index: 10;
  text-align: left;
  position: absolute;
  top: 30%;
  left: 65%;
  transform: translate(-50%, 50%);
  color: #eeeeee;
  font-size: 1rem;
  line-height: 1.6rem;
  font-weight: 500;
`;

const TextWrapTwo = styled.div`
  width: 100%;
  z-index: 10;
  text-align: left;
  position: absolute;
  top: 38%;
  left: 65%;
  transform: translate(-50%, 50%);
  color: #eeeeee;
  font-size: 1rem;
  line-height: 1.6rem;
  font-weight: 300;
`;

const ButtonWrap = styled.div`
  width: 100%;
  z-index: 99;
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 50%);
`;

const Home = () => {
  const buttonUse = ["business", "trainer"];

  return (
    <MobileContainer>
      <BackGround />
      <TextWrap>
        <p>[밋 코치앱]</p>
        <p>'운동지도의 시스템화' 선언</p>
      </TextWrap>
      <TextWrapTwo>
        <p>음악별 운동지도 가이드</p>
        <p>시퀀스별 음악설정</p>
        <p>동작별 운동시간 및 휴식시간 개별설정</p>
      </TextWrapTwo>
      <TitleWrap>
        <p>Don't Think,</p>
        <p>Let's MIIT!</p>
      </TitleWrap>
      <ButtonWrap>
        <NewMobileButton buttonUse={buttonUse} />
      </ButtonWrap>
    </MobileContainer>
  );
};

export default Home;
