import styled from "styled-components";
import { CircularProgress } from "@mui/material";

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(255, 255, 255);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: #09348a !important;
  margin-bottom: 20px;
`;

const LoadingProgress = () => {
  return (
    <LoadingContainer>
      <StyledCircularProgress />
    </LoadingContainer>
  );
};

export default LoadingProgress;
