import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import VideoPopup from "./VideoPopup";
import { IoIosCloseCircle } from "react-icons/io";
import { RiFileListLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { RootState } from "../../component/redux/rootReducer";

interface VideoListModalProps {
  videos: { url: string; mv_nm: string; mv_no: string; mv_rep_img: string }[];
  title: string;
  onClose: () => void;
}

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const ModalContainer = styled.div`
  background: #ffffff;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  max-width: 90%;
  max-height: 80%;
  overflow: hidden;
  position: relative;
`;

const VideoControl = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px auto;
  width: 90%;
`;

const VideoNumber = styled.div`
  color: #0a0a0a;
  font-size: 0.6rem;
  font-weight: 500;
  z-index: 10;
  line-height: 1rem;
  margin-left: 2px;
`;

const VideoInfoClick = styled.div`
  background: none;
  border: none;
`;

const VideoImg = styled.img`
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
  display: block;
  object-fit: cover;
  overflow: hidden;
`;

const VideoWrapper = styled.div`
  flex-basis: calc(33% - 10px);
  flex-grow: 0;
  flex-shrink: 0;
  margin: 5px;
  background-color: #ffffff;
  position: relative;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
  overflow: hidden;
  box-sizing: border-box;

  video {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  font-size: 1rem;
  font-weight: 600;
  position: sticky;
  top: 0;
  background: #ffffff;
  padding: 10px 0 -5px 0;
  z-index: 2;
`;

const TitleContainerTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  box-orient: vertical;
`;

const ContentContainer = styled.div`
  overflow: auto;
  max-height: 100%;
`;

const CloseButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  color: #09348a;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -15px;
  right: -15px;
  transform: translate(-50%, 50%);
`;

const DroppableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
`;

const VideoTitleWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 5px;
`;

const VideoTitle = styled.div`
  font-size: 8px;
  flex: 0 0 90%;
`;

const VideoItem = ({ video }: any) => {
  const [isSelected, setIsSelected] = useState(false);

  const handleVideoClick = () => {
    setIsSelected(!isSelected);
  };

  return (
    <>
      {!isSelected ? (
        <VideoImg
          src={video.mv_rep_img}
          alt="thumbnail"
          onClick={handleVideoClick}
        />
      ) : (
        <video src={video.url} autoPlay loop muted onClick={handleVideoClick} />
      )}
    </>
  );
};

const VideoListModal: React.FC<VideoListModalProps> = ({
  videos,
  onClose,
  title,
}) => {
  const MLoginInfo = useSelector((state: RootState) => state.MLoginInfo);
  const [localVideos, setLocalVideos] = useState<
    {
      url: string;
      mv_nm: string;
      mv_no: string;
      mv_rep_img: string;
    }[]
  >([]);
  const [selectedVideo, setSelectedVideo] = useState<{
    url: string;
    title: string;
    mv_no: string;
  } | null>(null);

  useEffect(() => {
    // 모달이 열리면 body 스크롤을 비활성화
    document.body.style.overflow = "hidden";

    return () => {
      // 모달이 닫히면 body 스크롤을 다시 활성화
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    setLocalVideos(videos);
    console.log("비디오 데이터들", videos);
  }, [videos]);

  const handleVideoClick = (video: any) => {
    setSelectedVideo(video);
  };

  return (
    <ModalBackdrop onClick={onClose}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <TitleContainer>
          <TitleContainerTitle>{title}</TitleContainerTitle>
          <CloseButton onClick={onClose}>
            <IoIosCloseCircle />
          </CloseButton>
        </TitleContainer>
        <ContentContainer>
          <DroppableContainer>
            {localVideos.map((video, index) => (
              <VideoWrapper>
                <VideoControl>
                  <VideoNumber>No{index + 1}</VideoNumber>
                  {MLoginInfo.MLoginInfo.guide_yn === "y" && (
                    <VideoInfoClick
                      onClick={() => {
                        handleVideoClick(video);
                      }}
                    >
                      <RiFileListLine />
                    </VideoInfoClick>
                  )}
                </VideoControl>
                <VideoItem key={index} video={video} />
                <VideoTitleWrap>
                  <VideoTitle>{video.mv_nm}</VideoTitle>
                </VideoTitleWrap>
              </VideoWrapper>
            ))}
          </DroppableContainer>
        </ContentContainer>
      </ModalContainer>
      {selectedVideo && (
        <VideoPopup
          videoUrl={selectedVideo.url}
          title={selectedVideo.title}
          mv_no={selectedVideo.mv_no}
          onClose={() => setSelectedVideo(null)}
        />
      )}
    </ModalBackdrop>
  );
};

export default VideoListModal;
