import React from 'react';
import ReactDOM from 'react-dom';
import {QueryClient, QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools";
import './index.css';
import './fonts/font.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './css/ModalAntd.css';
import RouterM from "./mobile-pages/RouterM";
import Router from "./component/Router";

const queryClient = new QueryClient();
const isMobile = window.innerWidth < 768;
const initialDevtoolsOpen = !isMobile;

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <App/>
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
