import { useEffect, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import { useMutation } from "react-query";
import { Picker } from "antd-mobile";
import { Modal } from "antd";
import { useDispatch } from "react-redux";
import Switch from "react-switch";
import ic_video_add from "../img/ic_video_add.png";
import allActions from "../component/redux/allActions";
import * as ServerApi from "../constants/ServerApi";
import * as MyUtil from "../constants/MyUtil";
import * as MyUiFun from "../constants/MyUiFun";
import ModalVideoSelect from "../component/ModalVideoSelect";
import ExVideoItem from "../component/ExVideoItem";
import "../css/DragItem.css";
import Colors from "../constants/Colors";
import icRepeat from "../img/ic_repeat.png";

// id 필수임.
interface ItemType {
  id: number;
  mv_no: string;
  mv_nm: string;
  mv_rep_img: string;
  mv_url: string;
}

const ProgramsVideo = ({ location }: any) => {
  const dispatch = useDispatch();
  const [updateSessionInfo, setUpdateSessionInfo] = useState(
    MyUtil._isNull(location?.state?.updateSessionInfo)
      ? ""
      : location?.state?.updateSessionInfo
  );
  const history = useHistory();
  const [branchName, setBranchName] = useState("");
  const [isVideoIuModalOpen, setIsVideoIuModalOpen] = useState(false);
  // const [arrProVideos, setArrProVideos] = useState<ItemType[]>(MyUtil._isNull(location?.state?.arrProVideos) ? [] : location?.state?.arrProVideos);
  const [loading, setLoading] = useState<boolean>(false);
  const pro_no = MyUtil._isNull(location?.state?.pro_no)
    ? ""
    : location?.state?.pro_no;
  const isMobile = MyUtil.isMobile;
  const [modalType, setModalType] = useState(""); // e , r
  const [isModalSelect, setIsModalSelect] = useState(false);
  const [exTime, setExTime] = useState<any>("0");
  const [restTime, setRestTime] = useState<any>("0");
  const [waitTime, setWaitTime] = useState<any>("0");
  const [modalSelectValue, setModalSelectValue] = useState<(string | null)[]>([
    "0",
    "0",
    "0",
  ]);

  const [seqType, setSeqType] = useState<
    "A" | "B" | "C" | "D" | "E" | "F" | "G" | "H"
  >("A");
  const [arrVideoA, setArrVideoA] = useState<[]>([]);
  const [arrVideoB, setArrVideoB] = useState<[]>([]);
  const [arrVideoC, setArrVideoC] = useState<[]>([]);
  const [arrVideoD, setArrVideoD] = useState<[]>([]);
  const [arrVideoE, setArrVideoE] = useState<[]>([]);
  const [arrVideoF, setArrVideoF] = useState<[]>([]);
  const [arrVideoG, setArrVideoG] = useState<[]>([]);
  const [arrVideoH, setArrVideoH] = useState<[]>([]);

  const [checked, setChecked] = useState(false);
  const handleChange = (nextChecked: any) => {
    setChecked(nextChecked);
  };

  function generateNumberArray(begin: number, end: number, addStr: string) {
    let array = [];
    for (let i = begin; i <= end; i++) {
      array.push({
        value: (i < 10 ? "0" : "") + i,
        label: (i < 10 ? "0" : "") + i + addStr,
      });
    }
    return array;
  }

  useEffect(() => {
    MyUtil._sessionCheck(
      updateSessionInfo,
      history,
      dispatch,
      allActions,
      true
    );
    if (MyUtil._isNull(pro_no)) {
      setLoading(false);
    } else {
      m_mng_pro_m_dt();
    }
  }, []);

  const m_mng_pro_m_dt = useCallback(async () => {
    let result: any = "";
    result = await ServerApi.m_mng_pro_m_dt({ pro_no: pro_no });

    const { isOk, data } = MyUtil._isResultSucces("m_mng_pro_m_dt", result);
    if (isOk) {
      // setTempData(data.array);
      setBranchName(data.pro_m_nm);
      setArrVideoA(data.list_a);
      setArrVideoB(data.list_b);
      setArrVideoC(data.list_c);
      setArrVideoD(data.list_d);
      setArrVideoE(data.list_e);
      setArrVideoF(data.list_f);
      setArrVideoG(data.list_g);
      setArrVideoH(data.list_h);
      setExTime(data.ex_time);
      setRestTime(data.br_time);
      setWaitTime(data.wait_time);
      setChecked(data.auto_yn === "y" ? true : false);
    } else {
      // setTempData(null);
      MyUtil._alertMsg("m_mng_pro_m_dt", data);
    }
    setLoading(false);
  }, [pro_no]);

  const { mutate: m_mng_pro_m_i } = useMutation(ServerApi.m_mng_pro_m_i, {
    onMutate: () => {
      setLoading(true);
    },
    onError: (error) => {
      setLoading(false);
      MyUtil._alertMsg("m_mng_pro_m_i", error);
    },
    onSuccess: (result: any, params: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces("m_mng_pro_m_i", result);

      if (isOk) {
        Modal.info({ content: "Your request has been completed." });
        history.goBack();
        // history.push({ pathname: '/ProgramsItem', state: { korName: 'EXERCISE' } });
      } else {
        MyUtil._alertMsg("m_mng_pro_m_i", data);
      }
    },
  });

  const ModalVideoCb = useCallback(
    (isOk, jData) => {
      setIsVideoIuModalOpen(false);
      if (isOk) {
        if (seqType === "A") {
          const newArr: any = [...arrVideoA, ...jData];
          setArrVideoA(newArr);
        } else if (seqType === "B") {
          const newArr: any = [...arrVideoB, ...jData];
          setArrVideoB(newArr);
        } else if (seqType === "C") {
          const newArr: any = [...arrVideoC, ...jData];
          setArrVideoC(newArr);
        } else if (seqType === "D") {
          const newArr: any = [...arrVideoD, ...jData];
          setArrVideoD(newArr);
        } else if (seqType === "E") {
          const newArr: any = [...arrVideoE, ...jData];
          setArrVideoE(newArr);
        } else if (seqType === "F") {
          const newArr: any = [...arrVideoF, ...jData];
          setArrVideoF(newArr);
        } else if (seqType === "G") {
          const newArr: any = [...arrVideoG, ...jData];
          setArrVideoG(newArr);
        } else if (seqType === "H") {
          const newArr: any = [...arrVideoH, ...jData];
          setArrVideoH(newArr);
        }
      }
    },
    [
      seqType,
      arrVideoA,
      arrVideoB,
      arrVideoC,
      arrVideoD,
      arrVideoE,
      arrVideoF,
      arrVideoG,
      arrVideoH,
    ]
  );

  let arrProVideos: any = [];
  let setArrProVides: any = [];

  arrProVideos =
    seqType === "A"
      ? arrVideoA
      : seqType === "B"
      ? arrVideoB
      : seqType === "C"
      ? arrVideoC
      : seqType === "D"
      ? arrVideoD
      : seqType === "E"
      ? arrVideoE
      : seqType === "F"
      ? arrVideoF
      : seqType === "G"
      ? arrVideoG
      : arrVideoH;

  setArrProVides =
    seqType === "A"
      ? setArrVideoA
      : seqType === "B"
      ? setArrVideoB
      : seqType === "C"
      ? setArrVideoC
      : seqType === "D"
      ? setArrVideoD
      : seqType === "E"
      ? setArrVideoE
      : seqType === "F"
      ? setArrVideoF
      : seqType === "G"
      ? setArrVideoG
      : setArrVideoH;

  let allTime = 0;
  const exCnt = Math.max(
    arrVideoA.length,
    arrVideoB.length,
    arrVideoC.length,
    arrVideoD.length,
    arrVideoE.length,
    arrVideoF.length,
    arrVideoG.length,
    arrVideoH.length
  );

  if (exCnt > 0) {
    allTime = exCnt * exTime + (exCnt - 1) * restTime;
  }
  return (
    <div className="container">
      <div
        className="container-wrap"
        style={{
          marginBottom: "30px",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <span className="container-title">
          {!MyUtil._isNull(location?.state?.korName)
            ? location.state.korName
            : ""}
        </span>
        <span className="container-sub">
          {!MyUtil._isNull(location?.state?.subName)
            ? location.state.subName
            : ""}
        </span>

        <div className="b-empty-row"></div>

        {isMobile && <div style={{ width: "530px", height: "15px" }}></div>}

        <Switch
          onChange={handleChange}
          checked={checked}
          // handleDiameter={20}
          // offColor="#08f"
          onColor="#09348a"
          // offHandleColor="#0ff"
          // onHandleColor="#08f"
          height={34}
          width={72}
          className="react-switch"
          uncheckedHandleIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img style={{ height: "24px" }} src={icRepeat} alt="logo image" />
            </div>
          }
          checkedHandleIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img style={{ height: "24px" }} src={icRepeat} alt="logo image" />
            </div>
          }
          uncheckedIcon={false}
          checkedIcon={false}
        />

        <div
          className="titleBtn-Con"
          onClick={() => {
            setIsVideoIuModalOpen(true);
          }}
        >
          {/* <span className='titleBtn-text'>영상 추가</span> */}
          <img style={{ height: "25px" }} src={ic_video_add} alt="logo image" />
        </div>

        <span
          className="itembox01-bottomBtnWrap-btnText22"
          style={{
            color: "#ffffff",
            paddingLeft: "20px",
            paddingRight: "20px",
            fontSize: "16px",
            borderRadius: "25px",
          }}
          onClick={() => {
            if (MyUtil._isNull(branchName)) {
              return Modal.warning({ content: "Please enter a title." });
            }
            // if (MyUtil._isNull(arrProVideos)) { return Modal.warning({ content: 'Please add video.' }); };
            m_mng_pro_m_i({
              pro_m_nm: branchName,
              pro_no: pro_no,
              center_no: updateSessionInfo.center_no,
              br_time: restTime,
              ex_time: exTime,
              wait_time: waitTime,
              array_a: arrVideoA,
              array_b: arrVideoB,
              array_c: arrVideoC,
              array_d: arrVideoD,
              array_e: arrVideoE,
              array_f: arrVideoF,
              array_g: arrVideoG,
              array_h: arrVideoH,
              auto_yn: checked ? "y" : "n",
            });
          }}
        >
          SAVE
        </span>
      </div>

      <div className="container-wrap"></div>

      <div className="container-wrap" style={{ marginBottom: "20px" }}>
        <div
          className="myExTimeInnerWrap"
          style={{ marginTop: "5px", minWidth: "250px" }}
        >
          <span className="modalIpTitleExTime">TITLE</span>
          <input
            type="text"
            className="myTimeExTxt"
            required
            placeholder="Please enter"
            value={branchName}
            onChange={(evt) => setBranchName(evt.target.value)}
          ></input>
        </div>

        <div
          className="myExTimeInnerWrap"
          style={{ marginTop: isMobile ? "20px" : "5px" }}
          onClick={() => {
            setModalSelectValue(MyUtil._secondToTimeArray(exTime));
            setModalType("e");
            setIsModalSelect(true);
          }}
        >
          <span className="modalIpTitleExTime">EXERCISE</span>
          <div className="myTimeExTxt">
            {MyUtil._secondToTimeFormat(parseInt(exTime))}
          </div>
          {/* <input type="number" className="modalTimeExIp" required placeholder="입력해주세요"
                  value={exTime} onChange={(evt) => setExTime(evt.target.value)}></input> */}
        </div>

        <div
          className="myExTimeInnerWrap"
          style={{ marginTop: isMobile ? "20px" : "5px" }}
          onClick={() => {
            setModalSelectValue(MyUtil._secondToTimeArray(restTime));
            setModalType("r");
            setIsModalSelect(true);
          }}
        >
          <span className="modalIpTitleExTime">REST</span>
          <div className="myTimeExTxt">
            {MyUtil._secondToTimeFormat(parseInt(restTime))}
          </div>
          {/* <input type="number" className="modalTimeExIp" required placeholder="입력해주세요"
                  value={restTime} onChange={(evt) => setRestTime(evt.target.value)}></input> */}
        </div>

        <div
          className="myExTimeInnerWrap"
          style={{ marginTop: isMobile ? "20px" : "5px" }}
          onClick={() => {
            setModalSelectValue(MyUtil._secondToTimeArray(waitTime));
            setModalType("w");
            setIsModalSelect(true);
          }}
        >
          <span className="modalIpTitleExTime">BREAK TIME</span>
          <div className="myTimeExTxt">
            {MyUtil._secondToTimeFormat(parseInt(waitTime))}
          </div>
          {/* <input type="number" className="modalTimeExIp" required placeholder="입력해주세요"
                  value={restTime} onChange={(evt) => setRestTime(evt.target.value)}></input> */}
        </div>
        <div style={{ flex: 1 }}></div>

        <div
          className="myExTimeInnerWrap"
          style={{
            marginTop: isMobile ? "20px" : "25px",
            marginLeft: "10px",
            borderWidth: "2px",
            borderColor: Colors.mainColor,
            borderStyle: "solid",
            padding: "14px 20px 8px 20px",
          }}
        >
          <div className="myTimeExTxtAll">
            {MyUtil._secondToTimeFormat(parseInt(allTime + ""))}
          </div>
        </div>
      </div>

      <div className="container-wrap" style={{ marginBottom: "30px" }}>
        <div
          className={
            seqType === "A" ? "packagesItem-tab-btn-on" : "packagesItem-tab-btn"
          }
          onClick={() => {
            setSeqType("A");
          }}
        >
          <span className="packagesItem-tab-txt">A</span>
        </div>
        <div
          className={
            seqType === "B" ? "packagesItem-tab-btn-on" : "packagesItem-tab-btn"
          }
          onClick={() => {
            setSeqType("B");
          }}
        >
          <span className="packagesItem-tab-txt">B</span>
        </div>
        <div
          className={
            seqType === "C" ? "packagesItem-tab-btn-on" : "packagesItem-tab-btn"
          }
          onClick={() => {
            setSeqType("C");
          }}
        >
          <span className="packagesItem-tab-txt">C</span>
        </div>
        <div
          className={
            seqType === "D" ? "packagesItem-tab-btn-on" : "packagesItem-tab-btn"
          }
          onClick={() => {
            setSeqType("D");
          }}
        >
          <span className="packagesItem-tab-txt">D</span>
        </div>
        <div
          className={
            seqType === "E" ? "packagesItem-tab-btn-on" : "packagesItem-tab-btn"
          }
          onClick={() => {
            setSeqType("E");
          }}
        >
          <span className="packagesItem-tab-txt">E</span>
        </div>
        <div
          className={
            seqType === "F" ? "packagesItem-tab-btn-on" : "packagesItem-tab-btn"
          }
          onClick={() => {
            setSeqType("F");
          }}
        >
          <span className="packagesItem-tab-txt">F</span>
        </div>
        <div
          className={
            seqType === "G" ? "packagesItem-tab-btn-on" : "packagesItem-tab-btn"
          }
          onClick={() => {
            setSeqType("G");
          }}
        >
          <span className="packagesItem-tab-txt">G</span>
        </div>
        <div
          className={
            seqType === "H" ? "packagesItem-tab-btn-on" : "packagesItem-tab-btn"
          }
          onClick={() => {
            setSeqType("H");
          }}
        >
          <span className="packagesItem-tab-txt">H</span>
        </div>
      </div>

      <div className="container-wrap">
        <ReactSortable
          group="groupName"
          swap={true}
          direction={"horizontal"}
          animation={50}
          list={arrProVideos}
          setList={setArrProVides}
          handle={".handle"}
          style={{ display: "contents" }}
          onChoose={(chooseData: any) => {
            MyUiFun.ClassAdd(
              chooseData.item,
              ".dndItemWrap-in",
              "dndItemWrap-in dndItemWrap-select"
            );
          }}
          onUnchoose={(chooseData: any) => {
            MyUiFun.ClassDel(
              chooseData.item,
              ".dndItemWrap-in",
              "dndItemWrap-select"
            );
          }}
          onEnd={(updateData: any) => {
            // console.log('이동된 인덱스 : ' + updateData.newIndex);
            // console.log('변경할 UID : ' + updateData.item.querySelector('.dndItem-key').textContent);
          }}
        >
          {!MyUtil._isNull(arrProVideos) &&
            arrProVideos?.map((item: any, idx: number) => (
              <ExVideoItem
                key={idx}
                item={item}
                idx={idx}
                arrProVideos={arrProVideos}
                setArrProVideos={setArrProVides}
              />
            ))}
        </ReactSortable>
      </div>

      {isVideoIuModalOpen && (
        <ModalVideoSelect
          ModalCb={ModalVideoCb}
          isModalOpen={isVideoIuModalOpen}
        />
      )}

      <Picker
        confirmText={"OK"}
        cancelText={"CANCEL"}
        columns={[
          generateNumberArray(0, 12, "시"),
          generateNumberArray(0, 59, "분"),
          generateNumberArray(0, 59, "초"),
        ]}
        visible={isModalSelect}
        onClose={() => {
          setIsModalSelect(false);
        }}
        value={modalSelectValue}
        onConfirm={(timeValue: any) => {
          // console.log('timeValue  시 : ' + timeValue[0]);
          // console.log('timeValue  분 : ' + timeValue[1]);
          // console.log('timeValue  초 : ' + timeValue[2]);
          if (!MyUtil._isNull(timeValue)) {
            let formatSec = 0;
            formatSec = formatSec + parseInt(timeValue[0]) * 3600;
            formatSec = formatSec + parseInt(timeValue[1]) * 60;
            formatSec = formatSec + parseInt(timeValue[2]) * 1;
            if (modalType === "e") {
              setExTime(formatSec + "");
            } else if (modalType === "w") {
              setWaitTime(formatSec + "");
            } else {
              setRestTime(formatSec + "");
            }
          }
        }}
      />
    </div>
  );
};

export default ProgramsVideo;
