import React, { useCallback, useEffect, useRef, useState } from "react";
import MobileHeader from "./Compornent/MobileHeader";
import SetupSquare from "./Compornent/SetupSquare";
import AccountButton from "./Compornent/AccountButton";
import { useSelector } from "react-redux";
import { RootState } from "../component/redux/rootReducer";
import * as ServerApiM from "../constants/ServerApiM";
import { useHistory } from "react-router-dom";
import { Member } from "./Interface/Interface";

const AccountSetup: React.FC = ({ location }: any) => {
  const MLoginInfo = useSelector((state: RootState) => state.MLoginInfo);
  const [members, setMembers] = useState<Member[]>([]);
  const [activePage, setActivePage] = useState("accountSetup");

  // iOS 체크
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  // 아래로는 결제 페이지
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(0);

  const handleWheel = useCallback((e) => {
    // 스크롤 방향이 아래쪽이면 다음 페이지로, 위쪽이면 이전 페이지로
    if (e.deltaY > 0) {
      // 아래로 스크롤
      setCurrentPage((prevPage) => Math.min(prevPage + 1, 2)); // 최대 페이지 번호를 초과하지 않게 설정
    } else if (e.deltaY < 0) {
      // 위로 스크롤
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 0)); // 0보다 작아지지 않게 설정
    }
  }, []);

  useEffect(() => {
    // 터치 이벤트 리스너 등록
    window.addEventListener("wheel", handleWheel);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, [handleWheel]);

  // 결제 페이지 끝

  useEffect(() => {
    // API 호출 함수
    const fetchMembers = async () => {
      try {
        const response = await ServerApiM.m_app_center_member({
          center_no: MLoginInfo.MLoginInfo.center_no,
        });
        console.log("accountsetup API Response:", response); // 로깅
        setMembers(response.array);
      } catch (error) {
        console.error("멤버 데이터를 불러오는 중 오류 발생:", error);
      }
    };

    fetchMembers();
  }, [MLoginInfo.MLoginInfo.center_no]);

  const updateMemberData = (updatedMember: Member) => {
    setMembers(
      members.map((member) =>
        member.u_id === updatedMember.u_id ? updatedMember : member
      )
    );
  };

  const goPayment = () => {
    history.push("/SABPay", {
      MLoginInfo: MLoginInfo,
    });
  };

  return (
    <div className="accountSetupContainer">
      {isIOS ? (
        // iOS용 화면
        <>
          <MobileHeader
            textOne={"ACCOUNT SETUP"}
            onTextOneClick={() => setActivePage("accountSetup")}
            onTextTwoClick={() => {}}
            initialActive={"textOne"}
          />
          {activePage === "accountSetup" && (
            <div className="setupSquareBox">
              <SetupSquare
                userData={MLoginInfo.MLoginInfo}
                setupSquareUse={"styleOne"}
                onUpdate={() => {}}
              />
              {members.map((member) => (
                <SetupSquare
                  key={member.u_id}
                  userData={member}
                  setupSquareUse={"styleTwo"}
                  onUpdate={updateMemberData}
                />
              ))}
            </div>
          )}
          <div className="buttonContainer">
            <AccountButton
              buttonUse={["logo", "play", "profileOn", "setup"]}
              mod_yn={MLoginInfo.MLoginInfo.mod_yn}
              member_type={MLoginInfo.MLoginInfo.member_type}
            />
          </div>
        </>
      ) : (
        // 안드로이드/웹용 화면
        <>
          <MobileHeader
            textOne={"ACCOUNT SETUP"}
            textTwo={"PAYMENT"}
            onTextOneClick={() => setActivePage("accountSetup")}
            onTextTwoClick={goPayment}
            initialActive={"textOne"}
          />
          {activePage === "accountSetup" && (
            <div className="setupSquareBox">
              <SetupSquare
                userData={MLoginInfo.MLoginInfo}
                setupSquareUse={"styleOne"}
                onUpdate={() => {}}
              />
              {members.map((member) => (
                <SetupSquare
                  key={member.u_id}
                  userData={member}
                  setupSquareUse={"styleTwo"}
                  onUpdate={updateMemberData}
                />
              ))}
            </div>
          )}
          <div className="buttonContainer">
            <AccountButton
              buttonUse={["logo", "play", "profileOn", "setup"]}
              mod_yn={MLoginInfo.MLoginInfo.mod_yn}
              member_type={MLoginInfo.MLoginInfo.member_type}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default AccountSetup;
