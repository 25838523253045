import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import styled, { css } from "styled-components";
import { FiLoader, FiCheck, FiArrowRight } from "react-icons/fi";

interface NewButtonsProps {
  text: string;
  dnStatus: "ready" | "loading" | "error" | "success";
  myClick: any;
  backGround?: string;
}

const ButtonContainer = styled(motion.button)<{
  backGround?: string;
  dnStatus?: "ready" | "loading" | "error" | "success";
}>`
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 20px;
  height: 40px;
  min-width: 150px;
  background-color: ${(props) =>
    props.backGround === "white" ? "#ffffff" : "#09348a"};
  border: none;
  border-radius: 30px;
  cursor: pointer;
  outline: none;
  color: ${(props) =>
    props.backGround === "white" ||
    props.dnStatus === "success" ||
    props.dnStatus === "error"
      ? "#09348a"
      : "#ffffff"};
  font-size: 16px;
  position: relative;
`;

const StatusCircle = styled(motion.div)<{
  dnStatus: string;
  backGround?: string;
}>`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.backGround === "white" ? "#09348a" : "#ffffff"};
  color: ${(props) => (props.backGround === "white" ? "#ffffff" : "#09348a")};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -16px;
  right: -16px;
  transform: translate(-50%, 50%);

  ${(props) =>
    props.dnStatus === "error" &&
    css`
      color: #ff0000;
    `}

  ${(props) =>
    props.dnStatus === "success" &&
    css`
      background-color: #09348a;
      color: #ffffff;
    `}
`;

const ButtonText = styled(motion.span)<{
  backGround?: string;
  dnStatus?: "ready" | "loading" | "error" | "success";
}>`
  color: ${(props) =>
    props.backGround === "white"
      ? "#09348a"
      : "#fff" || (props.dnStatus === "success" && "#09348a")};
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
`;

const ExclamationMark = styled.span`
  font-size: 24px;
  font-weight: bold;
`;

const rotateAnimation = {
  rotate: [0, 360],
  transition: { loop: Infinity, ease: "linear", duration: 1 },
};

const NewButtons: React.FC<NewButtonsProps> = ({
  text,
  dnStatus,
  myClick,
  backGround,
}) => {
  const controls = useAnimation();
  const textControls = useAnimation();

  useEffect(() => {
    switch (dnStatus) {
      case "error":
        controls.start({
          backgroundColor: "#ff0000",
          x: 0,
        });
        textControls.start({
          color: "#ffffff",
        });
        break;
      case "success":
        controls.start({
          backgroundColor: "#ffffff",
          borderColor: "#09348a",
          x: 0,
        });
        textControls.start({
          color: "#000",
        });
        break;
      case "loading":
        controls.start({
          x: 0,
        });
        textControls.start({
          color: backGround === "white" ? "#09348a" : "#ffffff",
        });
        break;
      default:
        controls.start({
          backgroundColor: backGround === "white" ? "#ffffff" : "#09348a",
          x: 0,
        });
        textControls.start({
          color: backGround === "white" ? "#000" : "#ffffff",
        });
        break;
    }
  }, [dnStatus, controls, textControls, backGround]);

  const iconVariants = {
    ready: <FiArrowRight />,
    loading: (
      <motion.div
        animate={rotateAnimation}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FiLoader />
      </motion.div>
    ),
    error: <ExclamationMark>!</ExclamationMark>,
    success: <FiCheck />,
  };

  return (
    <ButtonContainer
      backGround={backGround}
      animate={controls}
      onClick={() => {
        myClick();
      }}
      type="submit"
    >
      <ButtonText backGround={backGround} animate={textControls}>
        {text}
      </ButtonText>
      <StatusCircle backGround={backGround} dnStatus={dnStatus}>
        {iconVariants[dnStatus]}
      </StatusCircle>
    </ButtonContainer>
  );
};
export default NewButtons;
