import React, {useState, useEffect, useRef} from 'react';
import ReactPlayer from 'react-player/lazy';
import ReactAudioPlayer from 'react-audio-player';
import {useHistory} from 'react-router-dom';
import * as MyUtil from "../../constants/MyUtil";

const VideoModal = ({tutoUrl, arrMusic, onClose}: any) => {
    const history = useHistory();
    const [musicIdx, setMusicIdx] = useState(0);
    const [isBgSound, setIsBgSound] = useState(false);
    const audioPlayer = useRef<any>(null);

    // 배경 음악 상태 변경 시 처리
    useEffect(() => {
        if (!MyUtil._isNull(audioPlayer) && !MyUtil._isNull(arrMusic)) {
            if (isBgSound) {
                audioPlayer.current.audioEl.current.play();
            } else {
                audioPlayer.current.audioEl.current.pause();
            }
        }
    }, [isBgSound, arrMusic]);

    const isMobileSize = window.innerWidth <= 768;
    const playerWidth = isMobileSize ? '80vw' : '94vw';
    const playerHeight = isMobileSize ? '80vh' : '94vh';

    const closeModal = () => {
        if (typeof onClose === 'function') {
            onClose();
        }
    };

    // 음악 재생이 끝나면 다음 음악 재생 처리
    const handleMusicEnded = () => {
        let getIdx = musicIdx;

        if (musicIdx >= (arrMusic.length - 1)) {
            getIdx = 0;
        } else {
            getIdx = getIdx + 1;
        }

        setMusicIdx(getIdx);

        // optional chaining 연산자로 null 체크
        audioPlayer.current?.audioEl.current?.pause();

        setTimeout(() => {
            // optional chaining 연산자로 null 체크
            audioPlayer.current?.audioEl.current?.play();
        }, 500);
    };

    return (
        <div className="videoContainer" onClick={closeModal}>
            <ReactAudioPlayer
                ref={audioPlayer}
                style={{display: 'none'}}
                src={arrMusic?.[musicIdx]?.file_nm}
                autoPlay
                controls
                loop={false}
                volume={1}
                onEnded={handleMusicEnded} // 음악 재생이 끝나면 다음 음악 재생 처리
            />
            <div className='videoPlayContainer'>
                <div className="videoPlayWrap">
                    <ReactPlayer
                        width={playerWidth}
                        height={playerHeight}
                        url={tutoUrl}
                        playing={true}
                        controls={false}
                        muted={true}
                        volume={0}
                        progressInterval={1000}
                        pip={false}
                        loop={true}
                    />
                </div>
            </div>
        </div>
    );
};

export default VideoModal;
