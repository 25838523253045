import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faDollarSign } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import allActions from "../../component/redux/allActions";
import AsyncStorage from "@react-native-async-storage/async-storage";

const BackButton = ({
  isLogIn,
  disableLogout,
  whiteLogout,
  showBackButton,
  showWhiteBackButton,
  proNo,
  showMainBackButton,
}: {
  isLogIn: boolean;
  disableLogout?: boolean;
  whiteLogout?: boolean;
  showBackButton?: boolean;
  showWhiteBackButton?: boolean;
  proNo?: string;
  showMainBackButton?: boolean;
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const openModal = (message: any) => {
    setModalMessage(message);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const confirmLogout = () => {
    openModal("로그아웃 하시겠습니까?");
  };

  const handleMainBack = () => {
    history.push("/");
  };

  const handleLogout = () => {
    // Redux
    dispatch(allActions.logOut());

    // AsyncStorage
    if (AsyncStorage) {
      AsyncStorage.removeItem("@login_info");
    }

    // SessionStorage
    sessionStorage.removeItem("login_info");

    // Cookie
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });

    // 웹뷰에 로그아웃 메세지 전송
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: "LOGOUT" }));
    }

    history.push("/MLogin");
  };

  const handleBack = () => {
    if (isLogIn) {
      history.push("/DayProgramM");
    } else if (location.pathname === "/MLogin" && !isLogIn) {
      history.push("/");
    } else {
      history.goBack();
    }
  };

  return (
    <>
      {showMainBackButton && (
        <button
          onClick={handleMainBack}
          style={{
            position: "fixed",
            top: "12px",
            left: "12px",
            border: "none",
            backgroundColor: "transparent",
            color: "#000",
            fontSize: "18px",
            zIndex: "9",
          }}
          aria-label={"뒤로가기"}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
      )}

      {showBackButton && (
        <button
          onClick={handleBack}
          style={{
            position: "fixed",
            top: "12px",
            left: "12px",
            border: "none",
            backgroundColor: "transparent",
            color: "#000",
            fontSize: "18px",
            zIndex: "9",
          }}
          aria-label={"뒤로가기"}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
      )}

      {showWhiteBackButton && (
        <button
          onClick={handleBack}
          style={{
            position: "fixed",
            top: "15px",
            left: "10px",
            border: "none",
            backgroundColor: "transparent",
            color: "#fff",
            fontSize: "18px",
            zIndex: "9",
          }}
          aria-label={"뒤로가기"}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
      )}

      {!disableLogout && (
        <button
          onClick={confirmLogout}
          style={{
            position: "fixed",
            top: "17px",
            right: "10px",
            background: "none",
            border: "none",
            color: "#000",
            fontWeight: "600",
            fontSize: "0.7rem",
            zIndex: "9",
          }}
          aria-label={"로그아웃"}
        >
          로그아웃
        </button>
      )}

      {whiteLogout && (
        <button
          onClick={confirmLogout}
          style={{
            position: "fixed",
            top: "18px",
            right: "10px",
            background: "none",
            border: "none",
            color: "#fff",
            fontWeight: "600",
            fontSize: "0.7rem",
            zIndex: "9",
          }}
          aria-label={"로그아웃"}
        >
          로그아웃
        </button>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Alert Modal"
        className="modalAlertBox"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: "99999",
          },
        }}
      >
        <h2>{modalMessage}</h2>
        <div>
          <button onClick={handleLogout} style={{ marginRight: "10px" }}>
            예
          </button>
          <button
            onClick={closeModal}
            style={{
              backgroundColor: "rgba(255,255,255,0.6)",
              color: "#000000",
            }}
          >
            아니오
          </button>
        </div>
      </Modal>
    </>
  );
};

export default BackButton;

export const LoginBackButton = ({ plusFunc }: { plusFunc: () => void }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleBack = async () => {
    // 로그아웃 처리
    dispatch(allActions.logOut());
    dispatch(allActions.setMLoginInfo({}));

    // AsyncStorage 초기화
    if (AsyncStorage) {
      await AsyncStorage.removeItem("@login_info");
    }

    // SessionStorage 초기화
    sessionStorage.removeItem("login_info");

    // Cookie 초기화
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });

    // 웹뷰에 로그아웃 메시지 전송
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: "LOGOUT" }));
    }
    plusFunc();
    // 초기 페이지로 이동
    history.push("/");
  };

  return (
    <button
      onClick={handleBack}
      style={{
        position: "fixed",
        top: "15px",
        left: "10px",
        border: "none",
        backgroundColor: "transparent",
        color: "#fff",
        fontSize: "18px",
        zIndex: "9",
      }}
      aria-label={"뒤로가기"}
    >
      <FontAwesomeIcon icon={faArrowLeft} />
    </button>
  );
};
