import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import imgDefault from "../img/bg_regImage.jpg";
import Loader from "./Loader";

const BannerItem2 = ({ width = "400px", height = "300px", marginTop = "15px", marginRight = '0px', item, setImgFile, defaultImg = null, setDelImgUrl, isClick = true }: any) => {
  const initBgImg = (item.url === null || item.url === "") ? (`url(${imgDefault})`) : (`url(${item.url})`);
  // const initAnchor = item.anchor === null || typeof (item.anchor) === 'undefined' ? "" : item.anchor;
  // const uid = item.uid === null || typeof (item.uid) === 'undefined' ? "" : item.uid;

  const [loading, setLoading] = useState<boolean>(false);
  const [isLocal, setIsLocal] = useState<boolean>(false);
  const [bgStyle, setBgStyle] = useState({ backgroundImage: initBgImg, width, height });
  const [bgStyleLocal, setBgStyleLocal] = useState({ backgroundImage: initBgImg, width, height });

  useEffect(() => {
        if (defaultImg !== null) {
          const newStyle = {backgroundImage: `url("${defaultImg}")`, width, height};
          setBgStyle(newStyle);
        }
      },
      [defaultImg]);


  const _hcImage = useCallback((e) => {
    setLoading(true);

    setTimeout(() => {
      let reader = new FileReader();
      const file = e.target.files[0];

      // 파일을 읽어서 로드가 되면 프리뷰 합니다.
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        const newStyle = { backgroundImage: `url(${reader.result})`, width, height };
        setDelImgUrl(defaultImg)
        setImgFile(file);
        setIsLocal(true);
        setBgStyleLocal(newStyle);
        setLoading(false)
      }
    }, 1000);
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <DivWrap style={{ marginTop, width, marginRight }}>
      {
        loading ? (<Loader />) : (
          <span onClick={(e: any) => {
            if (isClick) {
              e.currentTarget.childNodes[0].click()
            }
          }}>
            <input type="file" accept="image/*" onChange={(e) => _hcImage(e)} style={{ display: 'none' }} />  {/* input 숨김 */}

            {
              isLocal ? (
                <DivImgBg style={{ ...bgStyleLocal }}></DivImgBg>
              ) : (
                <DivImgBg style={{ ...bgStyle }}></DivImgBg>
              )
            }
          </span>
        )
      }
    </DivWrap>
  );
}


const DivWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

const DivImgBg = styled.div`
  display: flex;
  height: 270px;
  margin-top: 15px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-width: 1px;
  border-color: #d8d8d8;
  border-style: solid;
`;

export default BannerItem2;
