const userData = {
    office: '그래비티 스튜디오',
    name: '홍길동',
    term: '1년 155일',
    email: 'abcdefg@gmail.com',
    phone: '010-1234-5678',
    rating: '프리미엄',
    rating2: '강사',
    pay: '295,000',
    class: '69개',
    session: '2023.01.25\n' +
        '~2024.06.25',
    password:'0000',
    certification: '0000',
    setupCertification: '0000',
    profileImage: 'https://via.placeholder.com/50x50'
};

export default userData
