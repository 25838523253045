export default {
    // ### DB 코드
    DB_SUCSESS: '100',    // 정상 응답
}

export const STORAGE_S = {
    LOGIN_INFO : "loginInfo"
}

export const STORAGE_L = {
    LOCATION : "sl_location",
    TEMP_LOGIN : "templogin",
    HISTORY : "sl_history"
}
