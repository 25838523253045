import { JoinFormData } from '../../mobile-pages/Interface/Interface';

const initialState: JoinFormData | null = null;

export const signUpReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case "SET_SIGN_UP_DATA":
            return action.payload;
        default:
            return state;
    }
};
