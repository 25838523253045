import CryptoJS from "crypto-js";

// 암호화/복호화에 사용할 키
const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTED_KEY ?? "";

// 테스트용 함수
const testEncryptionDecryption = () => {
  // AWS 키 테스트
  const originalAccessKey = process.env.REACT_APP_AWSAccessKeyId ?? "";
  const originalSecretKey = process.env.REACT_APP_AWSSecretKey ?? "";

  console.log("\n=== AWS Access Key 테스트 ===");
  const encryptedAccessKey = encrypt(originalAccessKey);
  console.log("원본 Access Key:", originalAccessKey);
  console.log("암호화된 Access Key:", encryptedAccessKey);
  console.log("복호화된 Access Key:", decrypt(encryptedAccessKey));

  console.log("\n=== AWS Secret Key 테스트 ===");
  const encryptedSecretKey = encrypt(originalSecretKey);
  console.log("원본 Secret Key:", originalSecretKey);
  console.log("암호화된 Secret Key:", encryptedSecretKey);
  console.log("복호화된 Secret Key:", decrypt(encryptedSecretKey));

  // 원본과 복호화된 값이 일치하는지 확인
  console.log("\n=== 검증 ===");
  console.log(
    "Access Key 일치 여부:",
    originalAccessKey === decrypt(encryptedAccessKey)
  );
  console.log(
    "Secret Key 일치 여부:",
    originalSecretKey === decrypt(encryptedSecretKey)
  );
};

export const encrypt = (text: string): string => {
  if (!text) return "";
  return CryptoJS.AES.encrypt(text, ENCRYPTION_KEY).toString();
};

export const decrypt = (encryptedText: string): string => {
  if (!encryptedText) return "";
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedText, ENCRYPTION_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error("복호화 중 오류 발생:", error);
    return "";
  }
};

// 테스트 실행
// testEncryptionDecryption();
