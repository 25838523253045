import React, {useState, useCallback, useEffect} from "react";
import styled from "styled-components";
import imgDefault from "../img/bg_regImage.jpg";
import Loader from "./Loader";
import circleMan from '../img/person_circle.svg'

const ImgProfile = ({
                        width = "400px",
                        height = "300px",
                        marginTop = "0px",
                        marginRight = '0px',
                        item,
                        setImgFile,
                        defaultImg = null,
                        setDelImgUrl,
                        onImageSelected
                    }: any) => {

    const initBgImg = (item.url === null || item.url === "") ? (`url(${circleMan})`) : (`url(${item.url})`);
    const initAnchor = item.anchor === null || typeof (item.anchor) === 'undefined' ? "" : item.anchor;
    const uid = item.uid === null || typeof (item.uid) === 'undefined' ? "" : item.uid;

    const [loading, setLoading] = useState<boolean>(false);
    const [isLocal, setIsLocal] = useState<boolean>(false);
    const [bgStyle, setBgStyle] = useState({
        backgroundImage: initBgImg,
        width,
        height,
        backgroundSize: "cover", 
        backgroundPosition: "center", 
    });
    const [bgStyleLocal, setBgStyleLocal] = useState({
        backgroundImage: initBgImg,
        width,
        height,
        backgroundSize: "cover", 
        backgroundPosition: "center", 
    });

    useEffect(() => {
        if (defaultImg !== null) {
            const newStyle = {
                backgroundImage: `url("${defaultImg}")`,
                width,
                height,
                backgroundSize: "cover",
                backgroundPosition: "center",
            };
            setBgStyle(newStyle);
        }
    }, [defaultImg]);


    const _hcImage = useCallback((e) => {
        setLoading(true);

        setTimeout(() => {
            let reader = new FileReader();
            const file = e.target.files[0];

            // 파일을 읽어서 로드가 되면 프리뷰 합니다.
            reader.readAsDataURL(file)
            reader.onloadend = () => {
                const newStyle = {
                    backgroundImage: `url(${reader.result})`,
                    width,
                    height,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                };
                setDelImgUrl(defaultImg)
                setImgFile(file);
                setIsLocal(true);
                setBgStyleLocal(newStyle);
                setLoading(false)

                if (onImageSelected) onImageSelected(file);
            }
        }, 1000);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <DivWrap style={{
            marginTop,
            width,
            minWidth: width,
            height,
            minHeight: height,
            marginRight,
            borderRadius: '50%',
            overflow: 'hidden'
        }}>
            {
                loading ? (<Loader/>) : (
                    <span onClick={(e: any) => {
                        e.currentTarget.childNodes[0].click()
                    }}>
            <input type="file" accept="image/*" onChange={(e) => _hcImage(e)}
                   style={{display: 'none'}}/> {/* input 숨김 */}

                        {
                            isLocal ? (
                                <DivImgBg style={{...bgStyleLocal}}></DivImgBg>
                            ) : (
                                <DivImgBg style={{...bgStyle}}></DivImgBg>
                            )
                        }
          </span>
                )
            }
        </DivWrap>
    );
}


const DivWrap = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 40px;
`;

const DivImgBg = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 270px;
    cursor: pointer;
    
`;

const IpUrl = styled.input`
    height: 30px;
    font-size: 14px;
    margin: 10px 0px 0px 0px;
    font-weight: lighter;
    box-sizing: border-box;
`;
export default ImgProfile;
