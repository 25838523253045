import React, { useEffect, useState } from "react";
import "../../css/mobileButton.css";
import logo from "../../img/logo_mobile.png";
import play from "../../img/play.png";
import video from "../../img/video.png";
import setup from "../../img/ic_set.png";
import activemove from "../../img/activemove.png";
import activecopy from "../../img/activecopy.png";
import activedelete from "../../img/activedelete.png";
import activeall from "../../img/activeall.png";
import music from "../../img/Music.svg";
import vibe from "../../img/vibe.png";
import vibeOn from "../../img/vibeOn.png";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGamepad } from "@fortawesome/free-solid-svg-icons";
import { GoStopwatch } from "react-icons/go";
import { RiVideoAddLine } from "react-icons/ri";
import styled from "styled-components";
import { PiUserCircleLight } from "react-icons/pi";
import AlertModal from "react-modal";
import { Modal } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../component/redux/rootReducer";

const ButtonWrap = styled.div`
  grid-column: 1 / span 2;
  width: 100%;
  height: 50px;
  background-color: #ededed;
  display: flex;
  justify-content: space-evenly;
  z-index: 999;
`;

const Button = styled.button`
  width: calc(100% / 3);
  background: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:active {
    transform: scale(1.05);
  }

  .logo {
    height: 14px;
    filter: invert(58%) sepia(0%) saturate(0%) hue-rotate(212deg)
      brightness(91%) contrast(89%);
  }

  .logoOn {
    height: 14px;
    filter: brightness(0) saturate(100%) invert(20%) sepia(78%) saturate(1556%)
      hue-rotate(199deg) brightness(88%) contrast(105%);
  }

  :first-child img:active {
    filter: brightness(0) saturate(100%) invert(20%) sepia(78%) saturate(1556%)
      hue-rotate(199deg) brightness(88%) contrast(105%);
  }

  img {
    height: 18px;
    object-fit: cover;
    filter: invert(58%) sepia(0%) saturate(0%) hue-rotate(212deg)
      brightness(91%) contrast(89%);
  }

  svg {
    filter: invert(58%) sepia(0%) saturate(0%) hue-rotate(212deg)
      brightness(91%) contrast(89%);
  }

  img:active {
    filter: brightness(0) saturate(100%) invert(20%) sepia(78%) saturate(1556%)
      hue-rotate(199deg) brightness(88%) contrast(105%);
  }

  svg:active {
    filter: brightness(0) saturate(100%) invert(20%) sepia(78%) saturate(1556%)
      hue-rotate(199deg) brightness(88%) contrast(105%);
  }

  .gray {
    filter: invert(58%) sepia(0%) saturate(0%) hue-rotate(212deg)
      brightness(91%) contrast(89%);
  }

  .on {
    filter: brightness(0) saturate(100%) invert(20%) sepia(78%) saturate(1556%)
      hue-rotate(199deg) brightness(88%) contrast(105%);
  }
`;

const NaviIconImg = styled.img`
  height: 18px;
  object-fit: cover;
  filter: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(20%)
    hue-rotate(39deg) brightness(93%) contrast(107%);
`;

const StyledModal = styled(AlertModal)`
  padding: 10px 20px;
  line-height: 1.4rem;
`;

const ModalText = styled.div`
  font-size: 0.9rem;
  line-height: 1.4rem;
  font-weight: 400;
`;

function AccountButton({
  buttonUse,
  mod_yn,
  member_type,
  pro_no,
  setIsVideoIuModalOpen,
  onLogoClick,
}: any) {
  const history = useHistory();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [firstLine, setFirstLine] = useState("");
  const [secondLine, setSecondLine] = useState("");

  const MLoginInfo = useSelector((state: RootState) => state.MLoginInfo);

  useEffect(() => {
    console.log("버튼 눌렀을떄 pro_no", pro_no);
  }, [pro_no]);

  const handleMenuItemClick = (path?: string, pro_no?: string) => {
    const state = pro_no ? { state: { pro_no } } : {};
    if (path) {
      history.push({
        pathname: path,
        ...state,
      });
    }
  };

  const handleLogoClick = () => {
    if (onLogoClick) {
      onLogoClick(); // props로 전달된 핸들러가 있으면 실행
    } else {
      history.push("/DayProgramM"); // 없으면 기존 동작 수행
    }
  };

  const handlePlayClick = () => {
    console.log("Play button clicked");
    history.push("/DayProgramTutorialM");
  };

  const handleProfileClick = () => {
    console.log("Current Login Data:", MLoginInfo);
    if (!MLoginInfo) {
      setModalIsOpen(true);
      setFirstLine("다시 시도해주세요.");
      return;
    }
    if (member_type === "m1") {
      history.push("/AccountSetup", { MLoginInfo: MLoginInfo });
    } else if (member_type === "m2") {
      history.push("/MyAccount", { MLoginInfo: MLoginInfo });
    }
  };

  const handleVideoClick = () => {
    console.log("Play button clicked");
  };

  const handleSetupClick = () => {
    console.log("Play button clicked");
    history.push("/ProgramsItemM");
  };

  const handleMoveClick = () => {
    console.log("Play button clicked");
  };

  const handleCopyClick = () => {
    console.log("Play button clicked");
  };

  const handleAllClick = () => {
    console.log("Play button clicked");
  };

  const handleDeleteClick = () => {
    console.log("Play button clicked");
  };

  const handleRemoteControl = () => {
    history.push("/RemotePage");
  };

  const handleStopWatch = () => {
    if (!pro_no) {
      setModalIsOpen(true);
      setFirstLine("운동영상 등록 후");
      setSecondLine("시간설정이 가능합니다.");
    } else {
      handleMenuItemClick("./TimeSetup", pro_no);
    }
  };

  const handleVideoAdd = () => {
    setIsVideoIuModalOpen(true);
  };

  const handleVibe = () => {
    if (!pro_no) {
      setModalIsOpen(true);
      setFirstLine("운동영상 등록 후");
      setSecondLine("음원설정이 가능합니다.");
    } else {
      handleMenuItemClick("./MusicSetup", pro_no);
    }
  };

  const closeAlertModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <ButtonWrap>
        {buttonUse.includes("logo") && (
          <Button onClick={handleLogoClick}>
            <img className="logo" src={logo} alt="logo" />
          </Button>
        )}
        {buttonUse.includes("logoOn") && (
          <Button onClick={handleLogoClick}>
            <img className="logoOn" src={logo} alt="logo" />
          </Button>
        )}
        {buttonUse.includes("play") && (
          <Button onClick={handlePlayClick}>
            <NaviIconImg src={play} alt="play" />
          </Button>
        )}
        {buttonUse.includes("playOn") && (
          <Button onClick={handlePlayClick}>
            <NaviIconImg className="on" src={play} alt="play" />
          </Button>
        )}
        {buttonUse.includes("profile") && (
          <Button onClick={handleProfileClick}>
            <PiUserCircleLight style={{ fontSize: "20px" }} />
          </Button>
        )}
        {buttonUse.includes("profileOn") && (
          <Button onClick={handleProfileClick}>
            <PiUserCircleLight className="on" style={{ fontSize: "20px" }} />
          </Button>
        )}
        {buttonUse.includes("video") && (
          <Button onClick={handleVideoClick}>
            <NaviIconImg src={video} alt="video" />
          </Button>
        )}
        {buttonUse.includes("videoOn") && (
          <Button onClick={handleVideoClick}>
            <NaviIconImg className="on" src={video} alt="video" />
          </Button>
        )}
        {buttonUse.includes("setup") && mod_yn === "y" && (
          <Button onClick={handleSetupClick} disabled={mod_yn !== "y"}>
            <NaviIconImg src={setup} alt="setup" />
          </Button>
        )}
        {buttonUse.includes("setupOn") && mod_yn === "y" && (
          <Button onClick={handleSetupClick} disabled={mod_yn !== "y"}>
            <NaviIconImg className="on" src={setup} alt="setup" />
          </Button>
        )}
        {buttonUse.includes("activeMove") && (
          <Button onClick={handleMoveClick}>
            <NaviIconImg src={activemove} alt="activeMove" />
          </Button>
        )}
        {buttonUse.includes("activeCopy") && (
          <Button onClick={handleCopyClick}>
            <NaviIconImg src={activecopy} alt="activeCopy" />
          </Button>
        )}
        {buttonUse.includes("activeAll") && (
          <Button onClick={handleAllClick}>
            <NaviIconImg src={activeall} alt="activeAll" />
          </Button>
        )}
        {buttonUse.includes("activeDelete") && (
          <Button onClick={handleDeleteClick}>
            <NaviIconImg src={activedelete} alt="activeDelete" />
          </Button>
        )}
        {buttonUse.includes("remote") && (
          <Button onClick={handleRemoteControl}>
            <FontAwesomeIcon icon={faGamepad} />
          </Button>
        )}
        {buttonUse.includes("videoAdd") && (
          <Button onClick={handleVideoAdd}>
            <RiVideoAddLine style={{ fontSize: "20px" }} className="gray" />
          </Button>
        )}
        {buttonUse.includes("stopWatch") && (
          <Button onClick={handleStopWatch}>
            <GoStopwatch
              style={{ fontSize: "18px", marginBottom: "-3px" }}
              className="gray"
            />
          </Button>
        )}
        {buttonUse.includes("stopWatchOn") && (
          <Button onClick={handleStopWatch}>
            <GoStopwatch
              className="on"
              style={{ fontSize: "18px", marginBottom: "-3px" }}
            />
          </Button>
        )}
        {buttonUse.includes("vibe") && (
          <Button onClick={handleVibe}>
            <img src={music} alt="music" />
          </Button>
        )}
        {buttonUse.includes("vibeOn") && (
          <Button onClick={handleVibe}>
            <img className="on" src={music} alt="music" />
          </Button>
        )}
      </ButtonWrap>

      <StyledModal
        isOpen={modalIsOpen}
        onRequestClose={closeAlertModal}
        contentLabel="Alert Modal"
        className="modalAlertBox"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            zIndex: "999",
          },
        }}
      >
        <ModalText>{firstLine}</ModalText>
        <ModalText>{secondLine}</ModalText>
        <button onClick={closeAlertModal} style={{ marginTop: "20px" }}>
          OK
        </button>
      </StyledModal>
    </>
  );
}

export default AccountButton;
