export default {
  // 디폴트 컬러
  mainColor: "#09348a",
  default_BgColor: "#fcfcfc",

  // 컴포넌트 컬러
  NavSide_BgColor: "#fcfcfc",
  notiRed: "#e83a56",
  notiGreen: "#21a621",

  // 모바일 컬러
  mobileColor: "#09348a",
};
