import React, {useState} from 'react';
import {useQuery, QueryFunctionContext} from 'react-query';
import * as ServerApi from '../constants/ServerApiM';
import * as MyUtil from '../constants/MyUtil';
import Loader from '../component/Loader';
import VideoModal from './Compornent/VideoModal';
import AccountButton from './Compornent/AccountButton';
import {useSelector} from "react-redux";
import {RootState} from "../component/redux/rootReducer";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "styled-components";

interface TutorialItem {
    tu_nm: string;
    tu_url: string;
    tu_rep_img: string;
}

const TutorialItemContainer = styled.div`
    width: 100%;
    height: 30px;
    position: absolute;
    background-color: #999999;
    bottom: 0;
    padding: 0 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
`;

const TrashIcon = styled(FontAwesomeIcon)`
    flex: 1;
`;

const TutorialName = styled.span`
    flex: 9;
    color: #ffffff;
    font-size: 0.85em;
    text-align: left;
`;

const DayProgramTutorialM: React.FC = () => {
    const MLoginInfo = useSelector((state: RootState) => state.MLoginInfo);
    const [hiddenIndex, setHiddenIndex] = useState<number | null>(null);

    const {data: response, isLoading, isError} = useQuery(
        'm_app_tu',
        async (ctx: QueryFunctionContext) => {
            const today = new Date().toISOString().split('T')[0];
            if (!MLoginInfo || !MLoginInfo.MLoginInfo) {
                console.error('로그인 정보가 없습니다.');
                return;
            }

            // API 호출
            const apiResponse = await ServerApi.m_app_tu({page: ''});
            return apiResponse;
        },
        {
            retry: 1,
            enabled: true,
            onError: (error) => {
                MyUtil._alertMsg('m_mng_tu', error);
            },
        }
    );

    const [selectedVideo, setSelectedVideo] = useState<string | null>(null);

    const closeModal = () => {
        setSelectedVideo(null);
    };

    const openVideoModal = (videoUrl: string) => {
        setSelectedVideo(videoUrl);
    };

    const handleHideVideo = (idx: number) => {
        setHiddenIndex(idx);
        console.log('handleHideVideo 클릭')
    };

    const buttonToShow = ['logo', 'playOn', 'profile', 'setup'];

    return (
        <div className="tutorialContainer">
            <div className="titleContainer">
                <p>TUTORIAL</p>
            </div>
            <div className="tutorialWrap">
                {isLoading ? (
                    <Loader/>
                ) : isError ? (
                    <div>데이터를 가져오는데 실패했습니다.</div>
                ) : (
                    <>
                        {response?.rsp_code === '100' && response?.array?.length > 0 ? (
                            response.array.map((item: TutorialItem, idx: number) => (
                                <div key={idx} className={`tutorialItem ${idx === hiddenIndex ? 'hidden' : ''}`}>
                                    <img
                                        className="tutorialImg"
                                        src={item.tu_rep_img}
                                        alt="logo image"
                                        onClick={() => openVideoModal(item.tu_url)}
                                    />
                                    <TutorialItemContainer>
                                        <TutorialName>{item.tu_nm}</TutorialName>
                                        <TrashIcon
                                            icon={faTrash}
                                            className="deleteIcon"
                                            onClick={() => handleHideVideo(idx)}
                                        />
                                    </TutorialItemContainer>
                                </div>
                            ))
                        ) : (
                            <div>데이터가 없습니다.</div>
                        )}
                    </>
                )}
            </div>
            <div className="buttonContainer">
                <AccountButton buttonUse={buttonToShow} mod_yn={MLoginInfo.MLoginInfo.mod_yn}
                               member_type={MLoginInfo.MLoginInfo.member_type}/>
            </div>
            {selectedVideo && <VideoModal tutoUrl={selectedVideo} onClose={closeModal}/>}
        </div>
    );
};

export default DayProgramTutorialM;
