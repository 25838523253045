import { faCheck, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import * as ServerApi from "../../constants/ServerApiM";
import { useEffect, useState } from "react";
import { RootState } from "../../component/redux/rootReducer";
import { useSelector } from "react-redux";

const Folder = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const FolderCount = styled.div`
  width: 50px;
  height: 50px;
  border: 1px solid #000;
  border-radius: 10px;
  margin: 0 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FolderDetails = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 70%;
`;

const FolderTitle = styled.span`
  font-weight: bold;
  color: #000;
`;

const FolderSubTitle = styled.span`
  color: #666;
`;

const FolderSelect = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  input[type="checkbox"] + label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    user-select: none;
  }

  input[type="checkbox"] + label::before {
    content: "";
    position: absolute;
    left: 14px;
    bottom: 0;
    transform: translate(-50%, 50%);
    width: 12px;
    height: 12px;
    border: 1px solid #ddd;
    border-radius: 3px;
    background: white;
  }

  input[type="checkbox"]:checked + label::before {
    background: #09348a;
    border-color: #09348a;
  }

  input[type="checkbox"]:checked + label::after {
    position: absolute;
    left: 3px;
    top: 0px;
    color: #000;
    font-size: 16px;
  }
`;

const SelectionIcon = styled.div`
  margin: auto 15px;
  cursor: pointer;
`;

function MusicFolder({
  editMode,
  folderList,
  selectedFolders,
  onFolderSelectionChange,
  onClick,
  pro_no,
  folderSelections,
  setFolderSelections,
  fetchMusicList,
}: any) {
  const MLoginInfo = useSelector((state: RootState) => state.MLoginInfo);

  const toggleFolderSelection = async (
    event: React.MouseEvent,
    folder: any
  ) => {
    event.stopPropagation();

    try {
      // 폴더 내 음악 목록 조회
      const folderData = {
        center_no: MLoginInfo.MLoginInfo.center_no,
        pro_no,
        play_fold_no: folder.play_fold_no,
      };

      const folderMusicList = await ServerApi.m_app_play_list(folderData);
      const isSelected = folderSelections[folder.play_fold_no] || false;

      if (isSelected) {
        // 폴더 내 음악 일괄 해제
        const listResponse = await ServerApi.m_app_pro_my_play_list({ pro_no });
        if (listResponse?.rsp_code === "100") {
          const registeredMusics = listResponse.array;

          // 폴더 내 음악들의 pro_music_no 수집
          const musicNosToDelete = folderMusicList.array1
            .map((music: any) => {
              const registeredMusic = registeredMusics.find(
                (x: any) => x.config_no === music.config_no
              );
              return registeredMusic ? registeredMusic.pro_music_no : null;
            })
            .filter(Boolean);

          console.log("musicNosToDelete", musicNosToDelete);

          // 한 번의 API 호출로 일괄 삭제
          if (musicNosToDelete.length > 0) {
            const deleteData = {
              pro_no,
              array: musicNosToDelete.map((pro_music_no: any) => ({
                pro_music_no,
              })),
            };
            const response = await ServerApi.m_app_pro_play_d(deleteData);
            console.log("m_app_pro_play_d response", response, deleteData);
            if (response?.rsp_code === "100") {
              console.log("폴더 내 음악 일괄 해제 성공");
              fetchMusicList();
            } else {
              console.error("폴더 내 음악 일괄 해제 실패:", response);
            }
          }
        }
      } else {
        // 폴더 내 모든 음악 일괄 등록
        const selectData = {
          pro_no,
          array: folderMusicList.array1.map((music: any) => ({
            config_no: music.config_no,
          })),
          gb: "all",
        };
        const response = await ServerApi.m_app_pro_play_i(selectData);
        console.log("m_app_pro_play_i response", response, selectData);
        if (response?.rsp_code === "100") {
          console.log("폴더 내 음악 일괄 등록 성공");
          fetchMusicList();
        } else {
          console.error("폴더 내 음악 일괄 등록 실패:", response);
        }
      }

      // 토글 상태 업데이트
      setFolderSelections((prev: any) => ({
        ...prev,
        [folder.play_fold_no]: !isSelected,
      }));
    } catch (error) {
      console.error("폴더 음악 등록/해제 중 오류 발생:", error);
    }
  };

  return (
    <>
      {folderList.map((folder: any, index: number) => (
        <Folder key={index}>
          {editMode && (
            <FolderSelect>
              <input
                type="checkbox"
                id={`folderSelection-${folder.play_fold_no}`}
                name={`folderSelection-${folder.play_fold_no}`}
                checked={selectedFolders.has(folder.play_fold_no)}
                onChange={(e) =>
                  onFolderSelectionChange(folder.play_fold_no, e.target.checked)
                }
              />
              <label htmlFor={`folderSelection-${folder.play_fold_no}`}></label>
            </FolderSelect>
          )}
          <FolderCount>{folder.play_ct}</FolderCount>
          <FolderDetails onClick={() => onClick(folder)}>
            <FolderTitle>{folder.title}</FolderTitle>
            <FolderSubTitle>{folder.sub_title}</FolderSubTitle>
          </FolderDetails>
          <SelectionIcon
            onClick={(event) => toggleFolderSelection(event, folder)}
          >
            <FontAwesomeIcon
              icon={folder.fold_check === "y" ? faCheck : faPlus}
            />
          </SelectionIcon>
        </Folder>
      ))}
    </>
  );
}

export default MusicFolder;
