import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CenterInfo, ModalProps } from "../Interface/Interface";
import * as ServerApi from "../../constants/ServerApiM";

const ModalOverlay = styled.div`
  position: absolute;
  bottom: 70%;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`;

const ModalContainer = styled.div`
  width: 80%;
  max-width: 600px;
  height: 60%;
  background: #09348a;
  overflow: hidden;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  position: relative;
`;

const ModalTitle = styled.div`
  width: 100%;
  padding: 10px 20px;
  background: #09348a;
  color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.6rem;

  p {
    font-size: 0.7rem;
    color: #ddd;
  }
`;

const ModalContent = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 90%;
  padding: 0 10px 20px 10px;
`;

const ModalButton = styled.button`
  width: 100%;
  height: 50px;
  background: none;
  border: none;
  border-bottom: 1px solid #b6b6b6;
  cursor: pointer;
  color: #fff;

  &:hover {
    background-color: #fff;
    color: #09348a;
  }

  &:active {
    background-color: #09348a;
    color: #fff;
  }
`;

function CenterModal({ onClose, onButtonClick }: ModalProps) {
  const [centers, setCenters] = useState<CenterInfo[]>([]);

  useEffect(() => {
    const fetchCenters = async () => {
      try {
        const response = await ServerApi.m_app_center({ center_nm: "" });

        if (response && response.array) {
          setCenters(response.array);
        } else {
          console.log("조건문 미진입");
        }
      } catch (error) {
        console.error("센터 데이터를 가져오는 데 실패했습니다.", error);
      }
    };
    fetchCenters();
  }, []);

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <ModalTitle>
          Select
          <p>지점을 선택해주세요.</p>
        </ModalTitle>
        <ModalContent>
          {centers.map((center) => (
            <ModalButton
              key={center.center_no}
              onClick={() => onButtonClick(center)}
            >
              {center.center_nm}
            </ModalButton>
          ))}
        </ModalContent>
      </ModalContainer>
    </ModalOverlay>
  );
}

export default CenterModal;
