import React, { useEffect, useRef, useState } from "react";
import ic_list from "../../img/ic_list2.png";
import ic_heart_ol from "../../img/ic_heart_ol.png";
import ic_heart_on from "../../img/ic_heart_on.png";
import ic_copy_ol from "../../img/ic_copy_ol.png";
import ic_chat_ol from "../../img/ic_chat_ol.png";
import ic_setting from "../../img/ic_set.png";
import ic_trash from "../../img/ic_trash.png";
import ic_calendar from "../../img/ic_calendar.svg";
import styled from "styled-components";
import { HiOutlineClipboardList } from "react-icons/hi";
import { CiViewList } from "react-icons/ci";
import { CgList } from "react-icons/cg";
import { RiRemoteControl2Line } from "react-icons/ri";

const ItemBoxBack = styled.div`
  width: 100%;
  background-color: #09348a;
  position: absolute;
  bottom: 0;
`;

const ItemBoxWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
`;

const Icon = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(100%) sepia(25%) saturate(2%)
    hue-rotate(151deg) brightness(106%) contrast(100%);
  /* filter: brightness(0) saturate(100%) invert(0%) sepia(4%) saturate(6%)
    hue-rotate(334deg) brightness(106%) contrast(100%); */
`;

const IconOn = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

const OptionControlBoxM = ({
  options,
  handleListClick,
  onChatClick,
  onCopyClick,
  onDeleteClick,
  onSettingClick,
  handleInfoAClick,
  handleInfoBClick,
  handleCalendarClick,
  onRemoteClick,
}: any) => {
  const [isHeartOn, setIsHeartOn] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const toggleHeartIcon = () => {
    setIsHeartOn(!isHeartOn);
  };
  const renderIcon = (iconType: any) => {
    switch (iconType) {
      case "heart":
        return isHeartOn ? (
          <IconOn src={ic_heart_on} alt="heart" onClick={toggleHeartIcon} />
        ) : (
          <Icon src={ic_heart_ol} alt="heart" onClick={toggleHeartIcon} />
        );
      case "chat":
        return <Icon src={ic_chat_ol} alt="chat" onClick={onChatClick} />;
      case "copy":
        return <Icon src={ic_copy_ol} alt="copy" onClick={onCopyClick} />;
      case "list":
        return (
          <CgList
            onClick={handleListClick}
            style={{ color: "#fff", width: "18px" }}
          />
        );
      // <Icon src={ic_list} alt='list' onClick={handleListClick}/>;
      case "setting":
        return <Icon src={ic_setting} alt="setting" onClick={onSettingClick} />;
      case "trash":
        return <Icon src={ic_trash} alt="trash" onClick={onDeleteClick} />;
      case "infoA":
        return (
          <HiOutlineClipboardList
            onClick={handleInfoAClick}
            style={{ color: "#fff", width: "18px" }}
          />
        );
      case "infoB":
        return (
          <HiOutlineClipboardList
            onClick={handleInfoBClick}
            style={{ color: "#fff", width: "18px" }}
          />
        );
      case "calendar":
        return (
          <Icon
            src={ic_calendar}
            alt="calender"
            onClick={handleCalendarClick}
            style={{ marginBottom: "1px" }}
          />
        );
      case "remote":
        return (
          <RiRemoteControl2Line
            onClick={onRemoteClick}
            style={{ color: "#fff", width: "18px" }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ItemBoxBack>
      <ItemBoxWrap>
        {options.map((option: string, index: number) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "30px",
            }}
          >
            {renderIcon(option)}
          </div>
        ))}
      </ItemBoxWrap>
    </ItemBoxBack>
  );
};

export default OptionControlBoxM;
