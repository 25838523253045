import { useState } from "react";
import styled from "styled-components";
import * as ServerApi from "../../constants/ServerApiM";

interface ToggleButtonProps {
  id: string;
  type: "mod_yn" | "account_yn";
  initialAccountYn: string;
  initialModYn: string;
  onUpdate: (newValue: string) => void;
}

interface ApprovalButtonProps {
  id: string;
  state: string;
  onUpdate: (newValue: string) => void;
}

const ToggleContainer = styled.div`
  position: relative;
  cursor: pointer;

  > .toggle-container {
    width: 40px;
    height: 18px;
    border-radius: 30px;
    background-color: #bebebe;
  }

  > .toggle--checked {
    background-color: #09348a;
    transition: 0.5s;
  }

  > .toggle-circle {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: rgb(255, 254, 255);
    transition: 0.5s;
  }

  > .toggle--checked {
    left: 23px;
    transition: 0.5s;
  }
`;

// const Switch = styled.div`
//   font-size: 10px;
//   position: relative;
//   display: inline-block;
//   width: 5em;
//   height: 2.5em;
//   user-select: none;
// `;

// const Checkbox = styled.input`
//   opacity: 0;
//   width: 0;
//   height: 0;
// `;

// const Toggle = styled.div`
//   position: absolute;
//   cursor: pointer;
//   width: 100%;
//   height: 100%;
//   background-color: #373737;
//   border-radius: 0.1em;
//   transition: 0.4s;
//   text-transform: uppercase;
//   font-weight: 700;
//   overflow: hidden;
//   box-shadow: -0.3em 0 0 0 #373737, -0.3em 0.3em 0 0 #373737,
//     0.3em 0 0 0 #373737, 0.3em 0.3em 0 0 #373737, 0 0.3em 0 0 #373737;
// `;

// const Left = styled.div<{ isOn: boolean }>`
//   position: absolute;
//   display: flex;
//   width: 50%;
//   height: 88%;
//   background-color: #f3f3f3;
//   color: ${({ isOn }) => (isOn ? "rgb(206, 206, 206)" : "#373737")};
//   left: 0;
//   bottom: 0;
//   align-items: center;
//   justify-content: center;
//   transform-origin: right;
//   transform: ${({ isOn }) =>
//     isOn ? "rotateX(10deg) rotateY(45deg)" : "rotateX(10deg)"};
//   transform-style: preserve-3d;
//   transition: all 150ms;

//   &::before {
//     position: absolute;
//     content: "";
//     width: 100%;
//     height: 100%;
//     background-color: rgb(206, 206, 206);
//     transform-origin: center left;
//     transform: rotateY(90deg);
//   }

//   &::after {
//     position: absolute;
//     content: "";
//     width: 100%;
//     height: 100%;
//     background-color: rgb(112, 112, 112);
//     transform-origin: center bottom;
//     transform: rotateX(90deg);
//   }
// `;

// const Right = styled.div<{ isOn: boolean }>`
//   position: absolute;
//   display: flex;
//   width: 50%;
//   height: 88%;
//   background-color: #f3f3f3;
//   color: ${({ isOn }) => (isOn ? "#487bdb" : "rgb(206, 206, 206)")};
//   right: 1px;
//   bottom: 0;
//   align-items: center;
//   justify-content: center;
//   transform-origin: left;
//   transform: ${({ isOn }) =>
//     isOn ? "rotateX(10deg) rotateY(0deg)" : "rotateX(10deg) rotateY(-45deg)"};
//   transform-style: preserve-3d;
//   transition: all 150ms;

//   &::before {
//     position: absolute;
//     content: "";
//     width: 100%;
//     height: 100%;
//     background-color: rgb(206, 206, 206);
//     transform-origin: center right;
//     transform: rotateY(-90deg);
//   }

//   &::after {
//     position: absolute;
//     content: "";
//     width: 100%;
//     height: 100%;
//     background-color: rgb(112, 112, 112);
//     transform-origin: center bottom;
//     transform: rotateX(90deg);
//   }
// `;

export const ToggleButton: React.FC<ToggleButtonProps> = ({
  id,
  type,
  initialAccountYn,
  initialModYn,
  onUpdate,
}) => {
  const initialIsOn =
    type === "account_yn" ? initialAccountYn === "y" : initialModYn === "y";
  const [isOn, setIsOn] = useState(initialIsOn);

  const updateSetting = async (settingType: string, settingValue: string) => {
    try {
      const data = {
        u_id: id,
        account_yn:
          settingType === "account_yn" ? settingValue : initialAccountYn,
        mod_yn: settingType === "mod_yn" ? settingValue : initialModYn,
      };

      const response = await ServerApi.m_app_center_member_set_u(data);
      if (response.rsp_code === "100") {
        console.log("성공:", response);
        onUpdate(settingValue);
      } else {
        console.error("실패:", response);
      }
    } catch (error) {
      console.error("API 호출 오류:", error);
    }
  };

  const handleToggle = () => {
    const newValue = isOn ? "n" : "y";
    setIsOn(!isOn);
    updateSetting(type, newValue);
  };

  return (
    <>
      <ToggleContainer onClick={handleToggle}>
        <div className={`toggle-container ${isOn ? "toggle--checked" : ""}`} />
        <div className={`toggle-circle ${isOn ? "toggle--checked" : ""}`} />
      </ToggleContainer>
    </>
    // <Switch onClick={handleToggle}>
    //   <Checkbox type="checkbox" checked={isOn} readOnly />
    //   <Toggle className="toggle">
    //     <Left className="left">Off</Left>
    //     <Right className="right">On</Right>
    //   </Toggle>
    // </Switch>
  );
};

export const ApprovalButton: React.FC<ApprovalButtonProps> = ({
  id,
  state,
  onUpdate,
}) => {
  const initialState = state === "1";
  const [isOn, setIsOn] = useState(initialState);

  const updateSetting = async (settingValue: string) => {
    try {
      const data = {
        u_id: id,
        state: settingValue === "0" ? "0" : "1",
      };
      console.log("m_app_member_state_u 데이터:", data);
      const response = await ServerApi.m_app_member_state_u(data);
      console.log("m_app_member_state_u 응답:", response);
      // 응답 구조에 맞게 수정
      if (response && response.rsp_code === "100") {
        onUpdate(settingValue); // isOn 상태에 따라 '0' 또는 '1'로 업데이트
      } else {
        console.error("실패:", response);
      }
    } catch (error) {
      console.error("API 호출 오류:", error);
    }
  };

  const handleToggle = () => {
    const newValue = isOn ? "0" : "1";
    setIsOn(!isOn);
    updateSetting(newValue);
    console.log(newValue);
  };

  return (
    <>
      <ToggleContainer onClick={handleToggle}>
        <div className={`toggle-container ${isOn ? "toggle--checked" : ""}`} />
        <div className={`toggle-circle ${isOn ? "toggle--checked" : ""}`} />
      </ToggleContainer>
    </>
    // <Switch onClick={handleToggle}>
    //   <Checkbox type="checkbox" checked={isOn} readOnly />
    //   <Toggle className="toggle">
    //     <Left className="left" isOn={isOn}>
    //       Off
    //     </Left>
    //     <Right className="right" isOn={isOn}>
    //       On
    //     </Right>
    //   </Toggle>
    // </Switch>
  );
};
