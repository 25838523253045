import {useEffect, useCallback, useState, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import ReactPlayer from 'react-player/lazy';
import ReactAudioPlayer from 'react-audio-player';
import {faVolumeMute, faVolumeHigh, faPlay, faPause, faForward} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from 'react-redux';
import * as MyUtil from "../constants/MyUtil";
import * as ServerApi from "../constants/ServerApi";
import logo from '../img/symbol.png'
import ic_wait_logo from '../img/logo_mobile.png';
import gifHeartQuick from '../img/gif_heart_red_q.gif';
import gifHeartSlow from '../img/gif_heart_red_s.gif';
import {RootState} from '../component/redux/rootReducer';
import Loader from '../component/Loader';
import '../css/ModalAntd.css';

// *** local path audio file NOT WORKING in android.
// http://simonmacdonald.blogspot.com/2011/05/using-media-class-in-phonegap.html?m=1

// *** .mp3 확장자 import 이슈
// https://stackoverflow.com/questions/59247861/how-to-import-a-sound-file-into-react-typescript-component
import sound_go from '../music/sound_go.ogg';
import MemoPlayer from '../component/MemoPlayer';
import {m_app_center_pro_m} from "../constants/ServerApiM";

const SBVideoPlay = ({location}: any) => {
    const MLoginInfo = useSelector((state: RootState) => state.MLoginInfo);
    const history = useHistory();
    const [pro_no, setPro_no] = useState(MyUtil._isNull(location?.state?.pro_no) ? '' : location?.state?.pro_no);
    const [selectProgram, setSelectProgram] = useState<any>([]);
    const [playIndex, setPlayIndex] = useState(0);
    const [musicIdx, setMusicIdx] = useState(0);
    const [playSoundFile, setPlaySoundFile] = useState('sound_go');
    const [playStatus, setPlayStatus] = useState('p');
    const [volumeLevel, setVolumeLevel] = useState(0.7);
    const [countVolumeLevel, setCountVolumeLevel] = useState(0);
    const [isLogoScreen, setIsLogoScreen] = useState(true);
    const [isBgSound, setIsBgSound] = useState(true);
    const [isCountSound, setIsCountSound] = useState(false);
    const [isPlayEx, setIsPlayEx] = useState(false);
    const [isTimerVisible, setIsTimerVisible] = useState(false);

    const [exTime, setExTime] = useState(0);
    const [waitTime, setWaitTime] = useState(0);
    const [exTimer, setExTimer] = useState<number>(-1);
    const audioPlayer = useRef<any>(null);
    const countPlayer = useRef<any>(null);
    const interval = useRef<any>(null);
    const [tempVideo, setTempVideo] = useState<any>([]);
    const [mvUrl2, setMvUrl2] = useState<any>('');
    const [mvUrl3, setMvUrl3] = useState<any>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [arrMusic, setArrMusic] = useState<any>([]);

    //  고용량
    // const tempVideo = [
    //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1657877338196_%EB%B8%8C%EB%A6%BF%EC%A7%80+%EC%B2%B4%EC%8A%A4%ED%8A%B8+%ED%94%84%EB%A0%88%EC%8A%A4.mp4',
    //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1663925412760_Side+band+start+position.mp4',
    //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1664966068399_One+leg+knee+extension+(out+side).mp4',
    //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1665994672538_Upright+supine.mp4',
    //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1663923833495_4POINT+START+POSITION.mp4',
    //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1665558024294_Arm circle reverse.mp4',
    //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1665558358157_Cross body shoulder raise.mp4',
    //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1665558601939_Shrug.mp4',
    // ]

    // 30mb 언저리
    // const tempVideo = [
    //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1657877945258_Dipbar+triple+extension.mp4',
    //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1662996366031_Combine+cable.mp4',
    //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1663924821967_Forward+seated.mp4',
    //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1663924865890_Sprint+start+position.mp4',
    //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1664360821117_Shouler+press+(hinge+to+tall+kneeling).mp4',
    //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1664360833056_Shouler+press+(tall+kneeling).mp4',
    //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1664467978193_Inverted+pike+(knee+touch).mp4',
    //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1664633682091_Step+down.mp4',
    // ]

    const EMPTY_REST_TIME = 4;
    // **********************************************************************************************************************************************
    // let EX_TIME = 0;
    // let REST_TIME = 0;
    // let WAIT_TIME = 0;
    // const isRestTime = false;

    // let EX_TIME = 0;
    // let REST_TIME = 0; // 쉬는 시간
    // let WAIT_TIME = 0; // 대기 시간
    // let isRestTime = false;
    // **********************************************************************************************************************************************

    // MyUtil._consoleLog("\n\n\n*********************************************************************************************");
    // MyUtil._consoleLog("EX_TIME : " + EX_TIME);
    // MyUtil._consoleLog("REST_TIME : " + REST_TIME);
    // MyUtil._consoleLog("WAIT_TIME : " + WAIT_TIME);
    // MyUtil._consoleLog("isRestTime : " + isRestTime);

    const mParams = useRef<any>({
        exTimer: 0,
        exStatus: 'p',
        playIndex: 0,
        EX_TIME: 0,
        REST_TIME: 0,
        WAIT_TIME: 0,
        isRestTime: false,
        videoLength: 0,
        autoYn: 'n'
    });  // exTstatus : p 준비 , e 운동 , r 쉬는시간

    useEffect(() => {
        async function fetchData() {
            m_center_pro_m_dt(true);
        }

        fetchData();
        return () => {
            if (interval.current) {
                clearInterval(interval.current);
            }

        };
    }, []);

    useEffect(() => {
        timerHandler();
        return () => {
            if (interval.current) {
                clearInterval(interval.current);
            }
        };
    }, []);

    const m_center_pro_m_dt = useCallback(async (isFirst) => {
        let result = await ServerApi.m_center_pro_m_dt({ pro_no: pro_no });
        const { isOk, data } = MyUtil._isResultSucces('m_center_pro_m_dt', result);

        if (isOk) {
            const videos = data?.pro_array;
            if (videos && videos.length > 0) {
                setSelectProgram(videos);
                const firstVideoDetails = videos[0].mv_array[0];
                setExTime(firstVideoDetails.ex_time ? parseInt(firstVideoDetails.ex_time, 10) : 0);
                setWaitTime(firstVideoDetails.wait_time ? parseInt(firstVideoDetails.wait_time, 10) : 0);
                mParams.current.EX_TIME = firstVideoDetails.ex_time ? parseInt(firstVideoDetails.ex_time, 10) : 0;
                mParams.current.WAIT_TIME = firstVideoDetails.wait_time ? parseInt(firstVideoDetails.wait_time, 10) + 1 : 1;
            }

            const configResult = await ServerApi.m_mng_config({
                center_no: MLoginInfo.MLoginInfo.center_no,
                config_type: 'c1'
            });
            setLoading(false);

            if (configResult.IS_SUCCESS === true && configResult.DATA_RESULT.rsp_code === '100') {
                setArrMusic(configResult.DATA_RESULT.array);
                let getIdx = Math.floor(Math.random() * configResult.DATA_RESULT.array.length);
                setMusicIdx(getIdx);
            }

            mParams.current.autoYn = data?.auto_yn;
            const restTime = MyUtil._isNull(data?.br_time) ? 4 : parseInt(data?.br_time);
            mParams.current.REST_TIME = restTime;
            mParams.current.isRestTime = !MyUtil._isNull(data?.br_time);
            mParams.current.videoLength = videos.length;

            if (isFirst) {
                setPlayStatus('p');
                setPlayIndex(0);
                setIsPlayEx(true);
                setIsTimerVisible(true);
                setIsLogoScreen(false);
                timerHandler();
            }
        }
    }, [pro_no]);

    const timerHandler = useCallback(async () => {
        if (audioPlayer.current && audioPlayer.current.audioEl.current) {
            audioPlayer.current.audioEl.current.play();
        }

        if (interval.current) {
            clearInterval(interval.current);
        }
        // console.log('*********** mParams.current.EX_TIME ************* : ' + mParams.current.EX_TIME)
        // console.log('*********** mParams.current.REST_TIME ************* : ' + mParams.current.REST_TIME)

        // 둘다 미 설정시 아무것도 안함
        if (mParams.current.EX_TIME === 0) {
            return setExTimer(-1);
        }

        interval.current = setInterval(function () {
            let curTime = mParams.current.exTimer;
            let nextTime: any = curTime - 1;
            let nowStatus = mParams.current.exStatus;
            let nextStatus = nowStatus;

            if (nextTime < 0) {
                if (nowStatus === 'p') {
                    nextStatus = 'e';
                    curTime = exTime;
                    nextTime = mParams.current.EX_TIME;
                    setIsTimerVisible(true);
                    setIsPlayEx(true);
                    setIsLogoScreen(false);
                    setVolumeLevel(0.7);

                } else if (nowStatus === 'e') {
                    nextStatus = 'r';
                    curTime = waitTime;
                    nextTime = mParams.current.REST_TIME;
                    setIsTimerVisible(true);
                    setIsPlayEx(true);
                    setIsLogoScreen(false);
                    // ****** 휴식시간 설정시 다음 영상 미리 재생
                    // if (REST_TIME !== 0) {
                    if (mParams.current.isRestTime) {
                        handleNextVideo();
                        setVolumeLevel(0.3);

                        // ****** 휴식 미설정시 자동 진행 안함
                    } else {
                        handleNextVideo();
                        setIsPlayEx(true);
                        // setIsLogoScreen(true);
                        // setIsPlayEx(false);

                        setVolumeLevel(0.3);
                        setIsTimerVisible(false);
                        setExTimer(-1);
                        if (interval.current) {
                            clearInterval(interval.current);
                        }
                        ;
                        return;
                    }

                } else if (nowStatus === 'r') {
                    nextStatus = 'e';
                    curTime = exTime;
                    nextTime = mParams.current.EX_TIME;
                    setVolumeLevel(0.7);

                    // 휴식시간 미 설정시 다음 영상 진행 안함
                    // if (REST_TIME === 0) { handleNextVideo(); };
                }
            }

            mParams.current.exStatus = nextStatus;
            mParams.current.exTimer = nextTime;
            setExTimer(curTime);
            setPlayStatus(nextStatus);

            if ((parseInt(nextTime) - 1) === 2) {
                setCountVolumeLevel(1);
                setVolumeLevel(0.1); // -------- <<<<<<<<<<<<<<
                setPlaySoundFile('sound_time');
                countPlayer.current.audioEl.current.play();

                if (nextStatus === 'e') {

                    // 마지막엔 스톱 음성
                    if ((mParams.current.playIndex === (selectProgram?.pro_array?.length - 1))) {
                        setTimeout(() => {
                            setPlaySoundFile('sound_stop');
                            if (nowStatus === 'p') {
                                setVolumeLevel(0.7);
                            } else {
                                setVolumeLevel(0.3);
                            }
                            ; // -------- <<<<<<<<<<<<<<
                            countPlayer.current.audioEl.current.play();
                        }, 3000);

                    } else {
                        if (mParams.current.isRestTime) {
                            setTimeout(() => {
                                setPlaySoundFile('sound_rest');
                                if (nowStatus === 'p') {
                                    setVolumeLevel(0.7);
                                } else {
                                    setVolumeLevel(0.3);
                                }
                                ; // -------- <<<<<<<<<<<<<<
                                countPlayer.current.audioEl.current.play();
                            }, 3000);

                        } else {
                            setTimeout(() => {
                                setPlaySoundFile('sound_stop');
                                if (nowStatus === 'p') {
                                    setVolumeLevel(0.7);
                                } else {
                                    setVolumeLevel(0.3);
                                }
                                ; // -------- <<<<<<<<<<<<<<
                                countPlayer.current.audioEl.current.play();
                            }, 3000);
                        }
                        ;
                    }

                } else {
                    setTimeout(() => {
                        setPlaySoundFile('sound_go');
                        if (nowStatus === 'p') {
                            setVolumeLevel(0.7);
                        } else {
                            setVolumeLevel(0.3);
                        }
                        ; // -------- <<<<<<<<<<<<<<
                        countPlayer.current.audioEl.current.play();
                    }, 3000);
                }
            }

            if (nextTime === 3 && countPlayer.current && countPlayer.current.audioEl.current) {
                countPlayer.current.audioEl.current.play();
            }

            setExTimer(nextTime);
            setPlayStatus(nextStatus);
        }, 1000);
    }, [audioPlayer, countPlayer, selectProgram, exTime, waitTime]);

    useEffect(() => {
        async function fetchData() {
            // console.log('******** playIndex HOOK ************* : ' + playIndex);
            // setLoading(true);
            if (playIndex >= 0 && !MyUtil._isNull(selectProgram)) {
                // if (mParams.current.playIndex !== (selectProgram?.pro_array?.length - 1)) {
                setTempVideo(selectProgram?.pro_array[playIndex]?.mv_array);
                // }
            }
            setLoading(false);
        }

        fetchData();
    }, [playIndex]);

    useEffect(() => {
        const fetchData = async () => {
            const result = await ServerApi.m_center_pro_m_dt({pro_no: pro_no});
            const {isOk, data} = MyUtil._isResultSucces('m_center_pro_m_dt', result);

            if (isOk) {
                setSelectProgram(data?.pro_array);
            }
        };

        fetchData();
    }, [pro_no]);

    useEffect(() => {
        console.log('tempVideo', tempVideo)
    }, [tempVideo]);

    useEffect(() => {
        if (audioPlayer?.current) {
            if (!MyUtil._isNull(arrMusic)) {
                if (isBgSound) {
                    audioPlayer.current.audioEl.current.load();
                    audioPlayer.current.audioEl.current.play();
                } else {
                    audioPlayer.current.audioEl.current.pause();
                }
            }
        }
    }, [isBgSound]);

    useEffect(() => {
        // if (countPlayer?.current) {
        if (isCountSound) {
            countPlayer.current.audioEl.current.play();
        } else {
            countPlayer.current.audioEl.current.pause();
            countPlayer.current.audioEl.current.currentTime = 0;
        }
        ;
        countPlayer.current.audioEl.current.play();
        // };
    }, [isCountSound]);

    // const handleNextVideo = useCallback(() => {
    //     const videoAllLength = selectProgram?.length || 0;
    //     let nextIndex = mParams.current.playIndex + 1;
    //
    //     if (nextIndex >= videoAllLength) {
    //         nextIndex = 0;
    //     }
    //
    //     const nextVideoDetails = selectProgram[nextIndex]?.mv_array[0];
    //
    //     const nextExTime = parseInt(nextVideoDetails.ex_time, 10);
    //     const nextWaitTime = parseInt(nextVideoDetails.wait_time, 10);
    //
    //     setExTime(nextExTime);
    //     setWaitTime(nextWaitTime);
    //     setPlayIndex(nextIndex);
    //
    //     mParams.current.EX_TIME = nextExTime;
    //     mParams.current.WAIT_TIME = nextWaitTime;
    //     mParams.current.playIndex = nextIndex;
    //
    // }, [selectProgram, setPlayIndex, setExTime, setWaitTime, mParams]);

    const handleNextVideo = useCallback(() => {
        const videoAllLength = mParams.current.videoLength;
        MyUtil._consoleLog('******** mParams.current.playIndex ************* : ' + mParams.current.playIndex)
        MyUtil._consoleLog('******** videoAllLength ************* : ' + videoAllLength)
        // MyUtil._consoleLog('******** (selectProgram?.pro_array?.length - 1) ************* : ' + JSON.stringify(selectProgram?.pro_array))

        const WAIT_TIME = mParams.current.WAIT_TIME;

        // 휴식시간이 있으면 영상을 미리 보여주기 때문에 분기 처리
        if (mParams.current.EX_TIME === 0) {
            if ((mParams.current.playIndex + 1 === (videoAllLength - 1))) {
                endEx()

            } else if (mParams.current.playIndex === (videoAllLength - 1)) {
                // 마지막 영상은 그대로 처리
                const nextIndex = mParams.current.playIndex + 0;
                mParams.current.playIndex = nextIndex;
                setPlayIndex(nextIndex);

            } else {
                const nextIndex = mParams.current.playIndex + 1;
                mParams.current.playIndex = nextIndex;
                setPlayIndex(nextIndex);
            }

        } else {
            if (mParams.current.playIndex === (videoAllLength - 1)) {
                endEx()
            } else {
                const nextIndex = mParams.current.playIndex + 1;
                mParams.current.playIndex = nextIndex;
                setPlayIndex(nextIndex);
            }
            ;
        }
        ;
    }, [selectProgram]);

    const endEx = useCallback(async () => {
        const WAIT_TIME = mParams.current.WAIT_TIME;
        if (interval.current) {
            clearInterval(interval.current);
        }
        setIsPlayEx(false);
        setIsTimerVisible(false);
        setPlayIndex(0);
        setPlayStatus('p');
        setExTimer(WAIT_TIME);
        setIsLogoScreen(true);
        mParams.current.exTimer = WAIT_TIME;
        mParams.current.exStatus = 'p';
        mParams.current.playIndex = 0;

        if (mParams.current.autoYn === 'n') {
            alert('Congratulations on your mission :)');

        } else {
            setLoading(true);
            setTimeout(() => {
                m_center_pro_m_dt(false);
            }, 200);
        }
    }, []);

    return (
        <div className="container-zero-row" style={{backgroundColor: '#000000'}}>

            {
                !MyUtil._isNull(arrMusic) && (
                    <ReactAudioPlayer
                        ref={audioPlayer}
                        style={{display: 'none'}}
                        src={arrMusic[musicIdx].file_nm}
                        autoPlay
                        controls
                        loop={false}
                        volume={volumeLevel}
                        onEnded={() => {
                            let getIdx = musicIdx;

                            // if (musicIdx >= (arrMusic.length - 1)) {
                            //   getIdx = 0;
                            // } else {
                            //   getIdx = getIdx + 1;
                            // };

                            if (arrMusic.length > 1) {
                                while (getIdx == musicIdx) {
                                    getIdx = Math.floor(Math.random() * arrMusic.length);
                                }
                            }

                            setMusicIdx(getIdx);
                            audioPlayer.current.audioEl.current.pause();

                            setTimeout(() => {
                                audioPlayer.current.audioEl.current.load();
                                audioPlayer.current.audioEl.current.play();
                            }, 500);
                        }}
                    />
                )
            }

            <ReactAudioPlayer
                ref={countPlayer}
                style={{display: 'none'}}
                src={playSoundFile === 'sound_time' ? 'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/bg/amthreesecs.mp3' : playSoundFile === 'sound_rest' ? 'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/bg/amrest.mp3' : playSoundFile === 'sound_go' ? 'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/bg/amgo.mp3' : 'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/bg/amrest.mp3'}
                autoPlay={false}
                controls
                loop={false}
                volume={countVolumeLevel}
                onEnded={() => {
                }}
            />

            {
                loading ? (<Loader/>) : (
                    <div className='sbvideoplay-contain' style={{overflowY: 'hidden'}}>

                        {
                            isTimerVisible ? (
                                <div className={'sbvideoplay-header'} style={{
                                    display: 'flex',
                                    width: '100%',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: '0 15px'
                                }}>

                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        justifyItems: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <span className='sbvideoplay-header-text'>WORKOUT </span>
                                        {
                                            playStatus === 'e' ? (
                                                <img style={{
                                                    width: '30px',
                                                    height: '30px',
                                                }} src={gifHeartQuick} alt="logo image"/>
                                            ) : (
                                                <img style={{
                                                    width: '30px',
                                                    height: '30px',
                                                }} src={gifHeartSlow} alt="logo image"/>
                                            )
                                        }
                                    </div>

                                    <div>
                                        {
                                            exTimer >= 0 && (
                                                <span className='sbvideoplay-header-extext'
                                                      style={{color: playStatus === 'p' ? '#8a8a8a' : playStatus === 'e' ? '#ff0000' : '#55ff55'}}>{((mParams.current.exStatus === 'p' || mParams.current.exStatus === 'r' || mParams.current.exStatus === 'w') && exTimer === 0) ? 'GO!' : (mParams.current.exStatus === 'e' && exTimer === 0) ? 'STOP' : MyUtil._secondToTimeSmallFormat(exTimer) + ''}</span>

                                            )
                                        }
                                    </div>

                                    <div>
                                            <span
                                                className='sbvideoplay-header-text'>{(playIndex + 1)}/{selectProgram?.pro_array ? (selectProgram?.pro_array?.length) : '0'}</span>
                                    </div>
                                </div>
                            ) : (
                                <div className={'sbvideoplay-header'} style={{justifyContent: 'center'}}>
                                    <span className='sbvideoplay-header-text' onClick={() => {
                                    }}>DON’T GIVE UP,  DO GRAVITY!!</span>
                                </div>
                            )
                        }

                        <div style={{display: 'flex', flexDirection: 'row', width: '100%', height: '100%'}}>
                            <div className='sbvideoplay-left'>

                                <img style={{
                                    width: '40px',
                                    height: '40px',
                                    marginTop: '5px',
                                    marginBottom: '7px',
                                    cursor: 'pointer'
                                }} src={logo} alt="logo image" onClick={() => {
                                    history.goBack();
                                }}/>

                                <div className="sbvideoplay-leftBtnWrap" style={{}} onClick={() => {
                                    setCountVolumeLevel(0);
                                    // countPlayer.current.audioEl.current.play();
                                    if (countPlayer) {
                                        countPlayer.current.audioEl.current.currentTime = 0;
                                        countPlayer.current.audioEl.current.pause();
                                    }

                                    if (mParams.current.exStatus === 'p') {
                                        setPlayIndex(0);
                                        setIsPlayEx(true);
                                        setIsTimerVisible(true);
                                        setIsLogoScreen(false);

                                        mParams.current.exTimer = 4;
                                        mParams.current.exStatus = 'r';
                                        timerHandler();

                                    } else {

                                        if (isPlayEx) {
                                            setIsPlayEx(false);
                                            setIsTimerVisible(false);
                                            // setIsLogoScreen(true);
                                            if (interval.current) {
                                                clearInterval(interval.current);
                                            }
                                        } else {
                                            setIsPlayEx(true);
                                            setIsTimerVisible(true);
                                            // setIsLogoScreen(false);
                                            timerHandler();
                                        }
                                    }
                                }}>
                                    <FontAwesomeIcon style={{
                                        width: '22px',
                                        height: '22px',
                                        marginLeft: mParams.current.exStatus === 'p' ? '4px' : !isPlayEx ? '4px' : '0px'
                                    }} icon={mParams.current.exStatus === 'p' ? faPlay : !isPlayEx ? faPlay : faPause}/>
                                </div>

                                {/*<div className="sbvideoplay-leftBtnWrap" style={{}} onClick={() => {*/}
                                {/*    let getIdx = musicIdx;*/}

                                {/*    // if (musicIdx >= (arrMusic.length - 1)) {*/}
                                {/*    //   getIdx = 0;*/}
                                {/*    // } else {*/}
                                {/*    //   getIdx = getIdx + 1;*/}
                                {/*    // };*/}
                                {/*    if (arrMusic.length > 1) {*/}
                                {/*        while (getIdx == musicIdx) {*/}
                                {/*            getIdx = Math.floor(Math.random() * arrMusic.length);*/}
                                {/*        }*/}
                                {/*    }*/}

                                {/*    setIsBgSound(true);*/}
                                {/*    setMusicIdx(getIdx);*/}
                                {/*    audioPlayer.current.audioEl.current.pause();*/}

                                {/*    setTimeout(() => {*/}
                                {/*        audioPlayer.current.audioEl.current.load();*/}
                                {/*        audioPlayer.current.audioEl.current.play();*/}
                                {/*    }, 500);*/}
                                <div className="sbvideoplay-leftBtnWrap" style={{}} onClick={handleNextVideo}>
                                    <FontAwesomeIcon style={{width: '22px', height: '22px', marginLeft: '6px'}}
                                                     icon={faForward}/>
                                </div>


                                <div className="sbvideoplay-leftBtnWrap" style={{}} onClick={() => {
                                    setIsBgSound(!isBgSound);
                                }}>
                                    <FontAwesomeIcon style={{width: '22px', height: '22px', marginLeft: '6px'}}
                                                     icon={isBgSound ? faVolumeHigh : faVolumeMute}/>
                                </div>

                                <div className='sbvideoplay-left-scroll'>
                                    {
                                        selectProgram?.pro_array?.map((item: any, idx: number) => (
                                            <div key={idx} className="sbvideoplay-leftBtnWrap-play" style={{
                                                backgroundColor: (idx) === playIndex ? '#09348a' : '#000000',
                                                color: (idx) === playIndex ? '#000000' : '#ffffff'
                                            }} onClick={() => {
                                                setCountVolumeLevel(0);
                                                if (countPlayer) {
                                                    countPlayer.current.audioEl.current.currentTime = 0;
                                                    countPlayer.current.audioEl.current.pause();
                                                }

                                                let nextTime = EMPTY_REST_TIME;
                                                mParams.current.playIndex = idx;
                                                // console.log('\n\n\n\n\n\n************* idx : '+idx)
                                                setPlayIndex(idx);
                                                setIsPlayEx(true);
                                                setIsLogoScreen(false);
                                                setExTimer(nextTime);
                                                setIsTimerVisible(true);
                                                mParams.current.exTimer = nextTime;
                                                mParams.current.exStatus = 'r';
                                                setPlayStatus('r');
                                                setExTimer(nextTime);

                                                timerHandler();

                                            }}>{idx + 1}</div>
                                        ))
                                    }
                                </div>
                                <div style={{width: '1px', minHeight: '145px'}}></div>
                            </div>

                            <div className='sbvideoplay-right' style={{}}>
                                {
                                    MyUtil._isNull(selectProgram?.pro_array) ? (
                                        <p style={{color: '#ffffff', fontSize: '1.2rem'}}>Loading...</p>
                                    ) : (
                                        isLogoScreen ? (
                                                <img style={{width: '200px', marginBottom: '150px'}} src={ic_wait_logo}
                                                     alt="logo image"/>
                                            ) :
                                            (

                                                // ************************************************** 8 분할 화면 **************************************************
                                                !MyUtil._isNull(tempVideo) ? (

                                                    <div style={{
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-start',
                                                        display: 'flex',
                                                        position: 'relative',
                                                        flex: 1,
                                                        flexDirection: 'column',
                                                        overflowY: 'auto'
                                                    }}>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            flexWrap: 'wrap',
                                                            alignItems: 'flex-start',
                                                            justifyContent: 'flex-start',
                                                            gap: '15px'

                                                        }}>
                                                            {
                                                                tempVideo?.map((item: any, idx: number) => {
                                                                    let videoSize = '80vw';
                                                                    let videoMargin = {
                                                                        marginLeft: '0.2vw',
                                                                        marginRight: '0.3vw'
                                                                    };

                                                                    if (tempVideo?.length >= 2) {
                                                                        videoSize = '40vw';
                                                                    }

                                                                    return (
                                                                        <div key={idx} style={{
                                                                            marginTop: '8px',
                                                                            position: 'relative'
                                                                        }}>
                                                                            <ReactPlayer
                                                                                key={idx}
                                                                                width={videoSize}
                                                                                height={videoSize}
                                                                                style={videoMargin}
                                                                                url={item.mv_url}
                                                                                playing={true}
                                                                                // playing={(isPlayEx) ? true : false}
                                                                                controls={false}
                                                                                muted={true}
                                                                                volume={0}
                                                                                progressInterval={1000}
                                                                                pip={false}
                                                                                loop={true}
                                                                            />

                                                                            <p style={{
                                                                                color: '#000000',
                                                                                fontSize: '1.8rem',
                                                                                fontWeight: 'bold',
                                                                                position: 'absolute',
                                                                                top: 10,
                                                                                left: 10
                                                                            }}>{
                                                                                item.seq_type
                                                                                // idx === 0 ? 'A' : idx === 1 ? 'B' : idx === 2 ? 'C' : idx === 3 ? 'D' : idx === 4 ? 'E' : idx === 5 ? 'F' : idx === 6 ? 'G' : idx === 7 ? 'H' : ''
                                                                            }</p>
                                                                        </div>
                                                                    )
                                                                })
                                                            }</div>
                                                    </div>

                                                ) : (
                                                    <></>
                                                )


                                                // ************************************************** 옛날 1/2/3 분할 화면 **************************************************

                                                // <>
                                                //   <div style={{
                                                //     flexDirection: 'row', display: 'flex', width: '100%', height: '100%', overflow: 'hidden', justifyContent: 'center',
                                                //     paddingLeft: (MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url2) && MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url3)) ? '600px'
                                                //       : (!MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url2) && !MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url3)) ? '130px'
                                                //         : (!MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url2) || !MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url3)) ? '0px'
                                                //           : '0px'
                                                //   }}>
                                                //     <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center' }}>
                                                //       <ReactPlayer
                                                //         width={'calc(50vw - 32px)'}
                                                //         height={'calc(50vw - 32px)'}
                                                //         url={mvUrl1}
                                                //         playing={true}
                                                //         // playing={(isPlayEx) ? true : false}
                                                //         controls={false}
                                                //         muted={true}
                                                //         volume={0}
                                                //         progressInterval={1000}
                                                //         pip={false}
                                                //         loop={true}
                                                //       // controls={false}
                                                //       // muted={false}
                                                //       // progressInterval={1000}
                                                //       // pip={false}
                                                //       // loop={true}
                                                //       />
                                                //     </div>

                                                //     <div style={{ width: '10px', height: '1px', display: 'flex' }}></div>

                                                //     {
                                                //       (!MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url2) && !MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url3)) ? (
                                                //         <div style={{ display: 'flex', flexDirection: 'column', flex: 1, marginTop: '0px' }}>
                                                //           <ReactPlayer
                                                //             width={'41vh'}
                                                //             height={'41vh'}
                                                //             url={mvUrl2}
                                                //             playing={true}
                                                //             // playing={(isPlayEx) ? true : false}
                                                //             controls={false}
                                                //             muted={true}
                                                //             volume={0}
                                                //             progressInterval={1000}
                                                //             pip={false}
                                                //             loop={true}
                                                //           />

                                                //           <div style={{ width: '1px', height: '11px' }}></div>

                                                //           <ReactPlayer
                                                //             width={'41vh'}
                                                //             height={'41vh'}
                                                //             url={mvUrl3}
                                                //             playing={true}
                                                //             // playing={(isPlayEx) ? true : false}
                                                //             controls={false}
                                                //             muted={true}
                                                //             volume={0}
                                                //             progressInterval={1000}
                                                //             pip={false}
                                                //             loop={true}
                                                //           />
                                                //         </div>

                                                //       ) : MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url2) ? (
                                                //         <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                                //           <MemoPlayer mvUrl={mvUrl3} />
                                                //         </div>

                                                //       ) : (

                                                //         <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                                //           <MemoPlayer mvUrl={mvUrl2} />
                                                //         </div>
                                                //       )
                                                //     }

                                                //   </div>
                                                // </>
                                            )
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
};

export default SBVideoPlay;
