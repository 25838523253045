import React, {useState} from "react";
import styled, {css} from 'styled-components';
import loginBtn from "../img/ic_arrow_rt_y.png";
import LoginButton from "./Compornent/LoginButton";
import logo from '../img/logo_mobile.png';
import {useHistory} from "react-router-dom";

const TitleArea = styled.div`
    margin-top: 30px;
    height: 15vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    background-color: #09348a;
    padding: 15px;

    p {
        color: #eeeeee;
        text-align: left;
        font-size: 1.8rem;
    }

    img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
        width: 70px;
        position: absolute;
        top: 40px;
        right: 10px;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
`;

const TextWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin: 30px auto;

    p {
        color: #09348a;
    }
`;

const ForgotSuccess: React.FC = () => {
    const history = useHistory();
    const [dnStatus, setDnStatus] = useState<'ready' | 'loading' | 'error' | 'success'>('ready');

    const handleSubmitClick = () => {
        setDnStatus('loading');
        setTimeout(() => {
            setDnStatus('success');
            setTimeout(() => {
                history.push({pathname: '/MLogin'})
            }, 500);
        }, 1000);
    };

    return (
        <>
            <TitleArea>
                <p>COMPLETE</p>
                <img src={logo} alt={logo}/>
            </TitleArea>
            <TextContainer>
                <TextWrap>
                    <p>비밀번호 변경이 완료 되었습니다.</p>
                    <p>감사합니다 :)</p>
                </TextWrap>
                <TextWrap>
                    <p>Your password has been changed.</p>
                    <p style={{marginBottom: '30px'}}>Thank you :)</p>
                </TextWrap>
                <LoginButton imgBtn={loginBtn} dnStatus={dnStatus} setDnStatus={setDnStatus}
                             myClick={handleSubmitClick}/>
            </TextContainer>
        </>
    )
}

export default ForgotSuccess
