import React, {useEffect, useRef, useState} from 'react';
import VideoPlayer from './VideoPlayer';
import {useParams} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlay, faPause, faVolumeUp, faVolumeMute, faForward} from '@fortawesome/free-solid-svg-icons';
import styled from "styled-components";
import symbol from '../../img/symbol.png';
import gifHeartQuick from '../../img/gif_heart_red_q.gif';
import gifHeartSlow from '../../img/gif_heart_red_s.gif';

interface VideoNumberProps {
    isSelected: boolean;
}

const PageContainer = styled.div`
    height: 100vh;
    overflow-y: auto;
    background-color: #0a0a0a;
`;

const WorkOutState = styled.div`
    width: 100%;
    height: 10vh;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
    background-color: #0a0a0a;
    z-index: 99;

    .left, .right {
        width: 33%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .left {
        width: 33%;
        margin-left: 10px;
    }

    .center {
        width: 34%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        width: 30px;
        height: auto;
    }

    p, .WorkOutTimer {
        font-size: 16px;
    }
`;

const VideoWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 10vh;
    margin-left: 60px;
    background-color: #0a0a0a;
`;

const VideoContainer = styled.div`
    width: 50%;
`;

const WorkOutTimer = styled.div`
    font-size: 60px;
    font-weight: 700;
    color: #d60a0a;
    margin-top: 10px;
`;

const WorkOutInfo = styled.div`
    margin: 0 10px;
    font-size: 20px;
    color: #ffffff;
`;

const VideoControls = styled.div`
    position: fixed;
    top: 50%;
    left: 0;
    margin-top: 3vh;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #0a0a0a;
`;

const VideoControlButton = styled.div`
    display: flex;
    justify-content: center;
    background: none;
    align-items: center;
    margin: 10px auto;
    text-align: center;

    svg {
        font-size: 20px;
        color: #ffffff;
    }

    img {
        width: 26px;
        height: auto;
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(355deg) brightness(104%) contrast(103%);
    }
`;

const VideoNumbers = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    height: 60vh;
    overflow-x: hidden;
    overflow-y: auto;
`;

const VideoNumber = styled.div<VideoNumberProps>`
    width: 100%;
    cursor: pointer;
    color: ${props => props.isSelected ? '#000' : '#fff'};
    background-color: ${props => props.isSelected ? '#fff' : 'transparent'};
    padding: 10px 20px;
    text-align: center;
    font-size: 20px;
`;

const VideoPlayerPage = () => {
    const {videoUrls}: any = useParams();
    const decodedUrls = JSON.parse(decodeURIComponent(videoUrls));
    const uniqueUrls: string[] = Array.from(new Set(decodedUrls)) as string[];

    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(true);
    const [isMuted, setIsMuted] = useState(false);

    const handleVideoClick = (index: number) => {
        setCurrentVideoIndex(index);
        setIsPlaying(true);
    };

    const handleNextVideo = () => {
        const nextIndex = (currentVideoIndex + 1) % uniqueUrls.length;
        setCurrentVideoIndex(nextIndex);
        setIsPlaying(true);
    };

    const videoRefs = useRef<(HTMLDivElement | null)[]>([]);

    useEffect(() => {
        const currentVideoRef = videoRefs.current[currentVideoIndex];
        if (currentVideoRef) {
            currentVideoRef.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    }, [currentVideoIndex]);

    const [workoutStatus, setWorkoutStatus] = useState('p'); // p: 준비, e: 운동, r: 쉬는시간
    const [exTimer, setExTimer] = useState(30); // 30초로 설정

    useEffect(() => {
        // 인터벌로 워크아웃 상태와 시간 관리
        const interval = setInterval(() => {
            setExTimer(prevTimer => prevTimer - 1);
            if (exTimer <= 0) {
                if (workoutStatus === 'p') {
                    setWorkoutStatus('e'); // 운동 시작
                    setExTimer(60); // 다음 타이머 설정
                } else if (workoutStatus === 'e') {
                    setWorkoutStatus('r'); // 휴식 시작
                    setExTimer(30); // 다음 타이머 설정
                } else {
                    setWorkoutStatus('p'); // 다시 준비 상태
                    setExTimer(30); // 다음 타이머 설정
                }
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [exTimer, workoutStatus]);

    return (
        <PageContainer>
            <WorkOutState>
                <div className="left">
                    <p>WORKOUT</p>
                    <img src={workoutStatus === 'e' ? gifHeartQuick : gifHeartSlow} alt="Heart Animation"/>
                </div>
                <div className="center">
                    <WorkOutTimer>{exTimer}</WorkOutTimer>
                </div>
                <div className="right">
                    <WorkOutInfo>{currentVideoIndex + 1} / {uniqueUrls.length}</WorkOutInfo>
                </div>
            </WorkOutState>
            <VideoControls>
                <VideoControlButton onClick={() => {
                }}>
                    <img src={symbol} alt={'메인로고'}/>
                </VideoControlButton>
                <VideoControlButton onClick={() => setIsMuted(!isMuted)}>
                    <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp}/>
                </VideoControlButton>
                <VideoControlButton onClick={() => setIsPlaying(!isPlaying)}>
                    <FontAwesomeIcon icon={isPlaying ? faPause : faPlay}/>
                </VideoControlButton>
                <VideoControlButton onClick={handleNextVideo}>
                    <FontAwesomeIcon icon={faForward}/>
                </VideoControlButton>
                <VideoNumbers>
                    {uniqueUrls.map((_: string, index: number) => (
                        <VideoNumber key={index}
                                     onClick={() => handleVideoClick(index)}
                                     isSelected={index === currentVideoIndex}>
                            {index + 1}
                        </VideoNumber>
                    ))}
                </VideoNumbers>
            </VideoControls>
            <VideoWrapper>
                {uniqueUrls.map((videoUrl: string, index: number, thumbUrl: string[]) => (
                    <VideoContainer
                        ref={el => videoRefs.current[index] = el}
                        key={index}
                    >
                        <VideoPlayer
                            videoUrls={[videoUrl]}
                            thumbnailUrls={thumbUrl}
                            isPlaying={index === currentVideoIndex && isPlaying}
                            isMuted={isMuted}
                            onVideoClick={() => handleVideoClick(index)}
                        />
                    </VideoContainer>
                ))}
            </VideoWrapper>
        </PageContainer>
    );
};

export default VideoPlayerPage;
