import React, { useEffect, ComponentType } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as MyUtil from "../../constants/MyUtilM";
import * as allActions from "../../component/redux/allActions";
import { RootState } from '../../component/redux/rootReducer';

interface WithSessionCheckProps {
}

// 고차 컴포넌트 정의
function withSessionCheck<T extends WithSessionCheckProps>(WrappedComponent: ComponentType<T>) {
    const WithSessionCheckComponent: React.FC<T> = (props) => {
        const MLoginInfo = useSelector((state: RootState) => state.MLoginInfo);
        const history = useHistory();
        const dispatch = useDispatch();

        useEffect(() => {
            MyUtil._sessionCheck(MLoginInfo.MLoginInfo, history, dispatch, allActions);
        }, [MLoginInfo.MLoginInfo, history, dispatch, allActions]);

        // WrappedComponent에 props 전달
        return <WrappedComponent {...props} />;
    };

    return WithSessionCheckComponent;
}

export default withSessionCheck;
