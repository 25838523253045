import React, { useState } from "react";
import styled from "styled-components";
import backGround from "../img/back_7.jpg";
import NewButtons from "./Compornent/NewButtons";
import { useHistory } from "react-router-dom";

const MobileContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const BackGround = styled.div`
  height: 60%;
  background-image: linear-gradient(
      to bottom,
      rgba(3, 7, 23, 0.1),
      rgba(3, 7, 23, 0.9)
    ),
    linear-gradient(to top, rgb(3, 7, 23), transparent 30%), url(${backGround});
  background-size: cover, cover, cover;
  background-position: center, center, center top;
  background-repeat: no-repeat;
  z-index: 9;
  overflow: hidden;

  @media screen and (max-width: 400px) {
    background-position: center, center, center;
  }
`;

const Gradient = styled.div`
  height: 100%;
  background-color: rgb(3, 7, 23);
  z-index: 9;
  overflow: hidden;
`;

const TitleWrap = styled.div`
  width: 100%;
  text-align: center;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, 50%);
  color: #fff;
  font-family: Poppins-Italic, serif;
  font-size: 2.4rem;
  line-height: 2rem;
  font-weight: 800;

  span {
    font-family: Suit, serif;
    font-size: 0.9rem;
    font-weight: 500;
  }

  @media screen and (max-width: 400px) {
    top: 25%;
  }
`;

const TextWrap = styled.div`
  width: 100%;
  font-family: Suit, serif;
  font-size: 1rem;
  color: #f2f2f2;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, 50%);
  line-height: 1.6rem;

  span {
    margin: 25px 0;
  }

  span::after {
    content: "";
    width: calc(60% - 10px);
    border-bottom: 1px solid #ffffff;
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translate(-50%, 50%);
  }

  @media screen and (max-width: 400px) {
    top: 20%;
  }
`;

const Text = styled.p`
  font-size: 0.9rem;
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translate(-50%, 50%);

  @media screen and (max-width: 400px) {
    bottom: 10%;
  }
`;

export const SignUpCompleteT: React.FC = () => {
  const [dnStatus, setDnStatus] = useState<
    "ready" | "loading" | "error" | "success"
  >("ready");
  const history = useHistory();

  const handleButtonClick = () => {
    setDnStatus("loading");
    setTimeout(() => {
      setDnStatus("success");
      setTimeout(() => {
        history.push({ pathname: "/MLogin" });
      }, 500);
    }, 1000);
  };

  return (
    <MobileContainer>
      <BackGround />
      <Gradient />
      <TitleWrap>Thank you!</TitleWrap>
      <TextWrap>
        <Text>가입이 완료되었습니다.</Text>
        <Text>서비스 관리자의 승인 후 이용 가능합니다.</Text>
        <Text style={{ marginTop: "15px" }}>감사합니다.</Text>
        <span></span>
        <Text>Your registration has been completed.</Text>
        <Text>After approval from the service manager.</Text>
        <Text>It's available</Text>
        <Text style={{ marginTop: "15px" }}>Thank you.</Text>
      </TextWrap>
      <ButtonContainer>
        <NewButtons
          text="Go Back"
          dnStatus={dnStatus}
          myClick={handleButtonClick}
          backGround={"white"}
        />
      </ButtonContainer>
    </MobileContainer>
  );
};

export const SignUpCompleteB: React.FC = () => {
  const [dnStatus, setDnStatus] = useState<
    "ready" | "loading" | "error" | "success"
  >("ready");
  const history = useHistory();

  const handleButtonClick = () => {
    setDnStatus("loading");
    setTimeout(() => {
      setDnStatus("success");
      setTimeout(() => {
        history.push({ pathname: "/MLogin" });
      }, 500);
    }, 1000);
  };

  return (
    <MobileContainer>
      <BackGround />
      <Gradient />
      <TitleWrap>Thank you!</TitleWrap>
      <TextWrap>
        <Text>가입이 완료되었습니다.</Text>
        <Text>결제 후 이용 가능합니다.</Text>
        <Text style={{ marginTop: "15px" }}>감사합니다.</Text>
        <span></span>
        <Text>Your registration has been completed.</Text>
        <Text>Service will be available after payment.</Text>
        <Text style={{ marginTop: "15px" }}>Thank you.</Text>
      </TextWrap>
      <ButtonContainer>
        <NewButtons
          text="Go Back"
          dnStatus={dnStatus}
          myClick={handleButtonClick}
          backGround={"white"}
        />
      </ButtonContainer>
    </MobileContainer>
  );
};
